import React from 'react';
import { createConnector } from 'cartiv';
import {
  Button, Card, Divider, Input, notification,
} from 'antd';
import api from '../../services/api';
import { AuthStore } from '../../stores/AuthStore';
import { MyProfileStore } from '../../stores/ProfileStore';
import { UserStore } from '../../stores/UserStore';
import style from './AccountDetailPage.scss';
import { PASSWORD_MIN_LENGTH } from '../../constants';

const connect = createConnector(React);

@connect(AuthStore)
@connect(MyProfileStore)
@connect(UserStore)
class AccountDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      newPassword: '',
      confirmedNewPassword: '',
    };

    this.style = style;

    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.handleUpdateName = this.handleUpdateName.bind(this);
    this.currentPassword = this.currentPassword.bind(this);
    this.setNewPassword = this.setNewPassword.bind(this);
    this.setConfirmedNewPassword = this.setConfirmedNewPassword.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
  }

  componentDidMount() {
    const activeUserId = AuthStore.onGetStateObject(localStorage.getItem('token')).jwtObject.userId;

    api.User.onLoadAccount(activeUserId, ({ firstName, lastName }) => {
      this.setState({ firstName, lastName });
    });
  }

  setFirstName(event) {
    this.setState(
      { firstName: event.target.value },
    );
  }

  setLastName(event) {
    this.setState(
      { lastName: event.target.value },
    );
  }

  setNewPassword(event) {
    this.setState(
      { newPassword: event.target.value },
    );
  }

  setConfirmedNewPassword(event) {
    this.setState(
      { confirmedNewPassword: event.target.value },
    );
  }

  currentPassword(event) {
    this.setState(
      { password: event.target.value },
    );
  }

  handleUpdateName() {
    const { loadedAccount, firstName, lastName } = this.state;

    api.User.onUpdateUserName(loadedAccount.id, {
      ...loadedAccount,
      firstName,
      lastName,
    });
  }

  handleUpdatePassword() {
    const { password, newPassword } = this.state;

    if (!newPassword || newPassword.length < PASSWORD_MIN_LENGTH) {
      return notification.error({
        message: 'Password length error',
        description: `The minimum password\'s length is ${PASSWORD_MIN_LENGTH}`,
      });
    }

    api.User.onUpdatePassword(password, newPassword);
  }

  render() {
    const { className } = this.props;
    const {
      loadedAccount, loading, firstName, lastName,
    } = this.state;

    return (
      <div className={`account-detail-page ${className}`}>
        {(!loading && !!loadedAccount)
        && (
          <Card title="Account" className="account-detail-page-card editor">
            <div className="update-card">
              <div className="title-section">Name update</div>
              <Divider className="divider-style" />
              <div className="input-field">
                <div className="name-container vertical-align">First Name:</div>
                <Input
                  className="name-input"
                  onChange={this.setFirstName}
                  value={firstName}
                />
              </div>
              <div className="flex input-field">
                <div className="name-container vertical-align">Last Name:</div>
                <Input
                  className="name-input"
                  onChange={this.setLastName}
                  value={lastName}
                />
              </div>
              <div className="update-btn">
                <Button icon="save" size="large" type="primary" onClick={this.handleUpdateName}>
                  Update name
                </Button>
              </div>
            </div>
            <div className="update-card">
              <div className="title-section">Password update</div>
              <Divider className="divider-style" />
              <div className="flex input-field">
                <div className="password-container vertical-align">Current password:</div>
                <Input.Password
                  onChange={this.currentPassword}
                  className="password-input"
                  placeholder="Current password"
                />
              </div>
              <div className="flex input-field">
                <div className="password-container vertical-align">New password:</div>
                <Input.Password
                  onChange={this.setNewPassword}
                  className="password-input"
                  placeholder="New password"
                />
              </div>
              <div className="flex input-field">
                <div className="password-container vertical-align">Confirm new password:</div>
                <Input.Password
                  onChange={this.setConfirmedNewPassword}
                  className="password-input"
                  placeholder="Confirm new password"
                />
              </div>
              <div className="update-btn">
                <Button icon="save" size="large" type="primary" onClick={this.handleUpdatePassword}>
                  Update password
                </Button>
              </div>
            </div>
          </Card>
        )}
      </div>
    );
  }
}

export default AccountDetailPage;
