import React from 'react';
import {
  Button, Card, Collapse, Input,
} from 'antd';
import './AccountProfileEditor.scss';
import { createConnector } from 'cartiv';
import styled from 'styled-components';
import api from '../../services/api';
import { AuthStore } from '../../stores/AuthStore';
import { AccountProfilesStore } from './AccountProfilesStore';
import { BooleanInput, StringInput } from '../genericEditor/FormControls';
import { clone } from '../../lib/clone';
import { DomainSelectorStore } from '../../components/DomainSelector/DomainSelectorStore';
import GenericBackAndSubmit from '../../components/GenericFooter/GenericBackAndSubmit';
import { PATHS } from '../../constants';

const connect = createConnector(React);

function RoleCheckbox({ role, checked, onChange }) {
  function onChangeChecked() {
    onChange(role);
    return false;
  }

  return (
    <div className="role-check">
      <BooleanInput
        entityDefinition={{ label: role }}
        value={checked}
        onChange={onChangeChecked}
        fieldClasses={{
          groupClass: 'ant-row',
          labelClass: 'ant-col-6',
          inputClass: '',
        }}
        name={`${role}-checkbox`}
      />
    </div>
  );
}

@connect(AccountProfilesStore)
@connect(AuthStore)
@connect(DomainSelectorStore)
class AccountProfileEditor extends React.Component {
  constructor(props) {
    super(props);
    this.id = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
    this.state = {};

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeRoleCheckBox = this.onChangeRoleCheckBox.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangePublic = this.onChangePublic.bind(this);
  }

  componentDidMount() {
    api.AccountProfiles.onLoadCustomRoles();
    api.AccountProfiles.onLoadRoles('account');
    api.AccountProfiles.onLoadRoles('emails', 'email');
    api.AccountProfiles.onLoadRoles('collection');
    api.AccountProfiles.onLoadRoles('audit');
    api.AccountProfiles.onLoadFileRoles();
    api.DomainSelector.onGetDomainInformation();
    if (this.id !== 'new') {
      api.AccountProfiles.onLoadProfile(this.id);
    } else {
      api.AccountProfiles.onResetProfile();
    }
  }

  onClickSave(e) {
    const { profile } = this.state;

    if (profile.profileId) {
      api.AccountProfiles.onUpdateProfile(profile);
    } else {
      api.AccountProfiles.onCreateProfile(profile);
    }
  }

  onChangeName(e) {
    const tempProfile = clone(this.state.profile);
    tempProfile.name = e;
    this.setState({ profile: tempProfile });
  }

  onChangeDescription(e) {
    const tempProfile = clone(this.state.profile);
    tempProfile.description = e.target.value;
    this.setState({ profile: tempProfile });
  }

  onChangeRoleCheckBox(role) {
    const tempProfile = clone(this.state.profile);
    const index = tempProfile.roles.indexOf(role);
    if (index === -1) {
      tempProfile.roles.push(role);
    } else {
      tempProfile.roles.splice(index, 1);
    }
    this.setState({ profile: tempProfile });
  }

  onChangePublic() {
    const tempProfile = clone(this.state.profile);
    tempProfile.allowSignup = !tempProfile.allowSignup;
    this.setState({ profile: tempProfile });
  }

  render() {
    const { className } = this.props;
    const { profile, domainInfo, customRoles } = this.state;
    const roles = profile?.roles || [];

    return (
      <form className={`AccountProfileEditor content-wrapper ${className}`}>
        <Card title="Profile editor">
          <div className="editor">
            {profile && profile.profileId && (
              <div className="profileId">
                <div className="profileId-label label">
                  ProfileIds
                </div>
                <div className="value">
                  {profile.profileId}
                </div>
              </div>
            )}
            <div className="name">
              <div className="name-label label">
                Name
              </div>
              <StringInput
                value={profile ? profile.name : ''}
                entityDefinition={{}}
                onChange={this.onChangeName}
              />
            </div>
            <div className="description">
              <div className="description-label label">
                Description
              </div>
              <Input.TextArea
                value={profile ? profile.description : ''}
                className="form-control"
                onChange={this.onChangeDescription}
              />
            </div>
            <BooleanInput
              entityDefinition={{ label: 'Public', details: 'A public profile is one that allows signup' }}
              value={profile ? profile.allowSignup : false}
              onChange={this.onChangePublic}
              fieldClasses={{
                groupClass: 'ant-row',
                labelClass: 'ant-col-6',
                inputClass: '',
                inputWrapperClass: 'ant-col-7',
              }}
              name="public-checkbox"
            />
            <div className="service-roles-wrapper">
              <h3 className="service-label label">
                Roles
              </h3>
              <Collapse bordered={false} defaultActiveKey={[]}>
                {!!domainInfo && domainInfo.usedServices.map((serviceEnum) => {
                  const serviceName = serviceEnum.split('_')[0].toLowerCase();
                  const serviceRoles = this.state[`${serviceName}Roles`];
                  const groupLabel = serviceName.charAt(0).toUpperCase() + serviceName.slice(1);
                  return (
                    <Collapse.Panel
                      header={groupLabel}
                      key={serviceName}
                    >
                      {!!serviceRoles && serviceRoles.map((role) => (
                        <RoleCheckbox
                          key={role}
                          role={role}
                          checked={roles.indexOf(role) !== -1}
                          onChange={this.onChangeRoleCheckBox}
                        />
                      ))}
                    </Collapse.Panel>
                  );
                })}
                <Collapse.Panel header="Custom Roles">
                  {
                    customRoles && customRoles.length
                      ? customRoles.map((role) => (
                        <RoleCheckbox
                          key={role}
                          role={role}
                          checked={roles.indexOf(role) !== -1}
                          onChange={this.onChangeRoleCheckBox}
                        />
                      ))
                      : <Button href={PATHS.ACCOUNTS_SETTINGS} type="link">Specify custom roles</Button>
}
                </Collapse.Panel>
              </Collapse>
            </div>
            <GenericBackAndSubmit onSave={this.onClickSave} />
          </div>
        </Card>
      </form>
    );
  }
}

export default styled(AccountProfileEditor)`
  .editor > div:not(:first-child) {
      margin-top: ${({ theme }) => theme.space.xs};
  }
`;
