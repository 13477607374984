import local from './local';

const ENV_CFG_KEY = '_env';

function getConfig() {
  let cfg;

  if (window.hasOwnProperty(ENV_CFG_KEY) && !!window[ENV_CFG_KEY]) {
    cfg = window[ENV_CFG_KEY];
    console.log('Using "CI" config: %o .', cfg);
  } else {
    cfg = local;
    console.log('Using "Local" config: %o .', cfg);
  }

  return cfg;
}

export const config = getConfig();
