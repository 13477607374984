import React from 'react';
import ColorPicker from 'rc-color-picker';

import './ColorInput.scss';

export class ColorInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};

    this.onChange = this.onChange.bind(this);
  }

  onChange(color) {
    this.props.onChange(color.color);
  }

  componentDidMount() {
    if (typeof this.props.value === 'undefined' || this.props.value === null || this.props.value === '') {
      this.props.onChange(this.props.defaultValue);
    }
  }


  render() {
    const value = typeof this.props.value === 'undefined' || this.props.value === null
      ? this.props.defaultValue
      : this.props.value;

    const groupClass = `form-group ${this.props.fieldClasses.groupClass}`;
    const hasValueClass = value ? 'has-value' : 'empty';
    const focusClass = this.state.focused ? 'focused' : 'blurred';

    return (
      <div className="ColorInput">
        <div
          className={`${this.props.name} ColorInput ${groupClass} ${hasValueClass} ${focusClass}`}>
          <div
            className={`${this.props.fieldClasses.labelClass} label-wrapper`}>
            <label
              className={`form-control-label`}
              htmlFor={`${this.props.name}-form-control`}>
              {this.props.entityDefinition.label}
              {this.props.required ? <span className="required">*</span> : null}
            </label>
          </div>
          <div
            className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
            <ColorPicker
              animation="slide-up"
              color={value}
              defaultColor={value}
              enableAlpha={false} //not allow alpha for now
              onChange={this.onChange}
            />
            {this.props.entityDefinition.details ?
              <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null
            }
          </div>
        </div>
      </div>
    );
  }
}

ColorInput.propTypes = {};

ColorInput.defaultProps = {
  value: '#000000',
  defaultValue: '#000000',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  onChange: (v) => console.log(v),
};
