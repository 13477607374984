import { createStore } from 'cartiv';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';

export const DomainsManagementStore = createStore({
  api,
  name: 'DomainsManagement',
}, {
  getInitialState() {
    return {
      domains: [],
    };
  },
  onLoadDomains() {
    this.setState(this.getInitialState());
    backend.get('/domains').then((domains) => {
      this.setState({
        domains,
      });
    }).catch(genericErrorHandler);
  },
});
