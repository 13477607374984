import React from 'react';
import styled from 'styled-components';

const PasswordSetCardsContainer = ({ className, children }) => (
  <div className={className}>
    {children}
  </div>
);

export default styled(PasswordSetCardsContainer)`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: relative;

  .ant-card {
    min-width: 460px;    
  
    .ant-card-head-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;

    }

    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        display: inline-block;
      }

      .ant-btn {
        width: 100%;

        .button-icon-right {
          position: absolute;
          right: ${({ theme }) => theme.space.xxs};
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .ant-form {
        width: 100%;

        .ant-form-item-children {
          width: 100%;
        }
      }
    }
  }
`;
