import React from 'react';
import { createConnector } from 'cartiv';
import { Button, Card } from 'antd';
import api from '../../services/api';
import { GroupInput } from '../genericEditor/FormControls';
import LanguageStore from '../../stores/LanguageStore';
import { DomainSettingsPageStore } from '../DomainSettingsPage/DomainSettingsPageStore';
import { ComponentPageStore } from './ComponentPageStore';
import './ComponentPage.scss';

const connect = createConnector(React);

@connect(ComponentPageStore)
@connect(LanguageStore)
@connect(DomainSettingsPageStore)
class ComponentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editedData: null,
      component: { _entityData: {} },
      subComponents: {},
      languages: LanguageStore.state.languages,
      domain: DomainSettingsPageStore.state.domain,
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.preview = this.preview.bind(this);
  }

  onChange(editedData) {
    this.setState({ editedData: editedData });
    if (this.previewWindow) {
      this.previewWindow.postMessage({
        type: 'xdpc',
        target: 'ComponentsStore',
        method: 'xDomainUpdate',
        args: ['page', 'home', editedData],
      }, '*');
    }
  }

  preview(e) {
    //TODO live preview changes
    this.previewWindow = window.open('http://web.jrl');
  }

  componentDidMount() {
    api.DomainSettingsPage.onLoadDomainSettingsPage();
    api.GenericEditor.onLoadLanguages();
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      api.ComponentPage.onLoadComponent(this.props.match.params.id);
    }
  }

  submitForm(e) {
    e.preventDefault();
    const id = this.props.match.params.id;
    api.ComponentPage.onUpdateData(id, this.state.editedData || this.state.component._entityData);
  }

  render() {
    const entityDefinition = this.state.component._entityDefinition || {};
    const brandColors = !!this.state.domain.basic && !!this.state.domain.basic.brandColors ? this.state.domain.basic.brandColors.map(brandColor => brandColor.color) : [];
    return (
      <div className="ComponentsPage content-wrapper">
        <Card title={`${this.state.component.name || 'New'} ${this.state.component.type || 'Component'}`}>
          <GroupInput
            name={this.state.component.name}
            languages={this.state.languages}
            brandColors={brandColors}
            entityDefinition={{ spec: entityDefinition }}
            value={this.state.editedData || this.state.component._entityData}
            onChange={this.onChange}
            fieldClasses={{
              groupClass: 'ant-row',
              labelClass: 'ant-col-6',
              inputClass: '',
              inputWrapperClass: 'ant-col-18',
            }}
          />

          <div className="card-actions">
            <Button
              type="primary"
              className="pull-right"
              onClick={this.submitForm}
              icon="save"
            >
              Save
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default ComponentPage;