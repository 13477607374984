import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import { passwordCardStyling } from './PasswordResetRequestCard';
import CardWithLogoInTitle from '../PasswordSet/CardWithLogoInTitle';


const PasswordActionSuccessfulCard = ({ className, title, description }) => (
  <CardWithLogoInTitle title={title} className={className}>
    <Icon type="check-circle" />
    <span>{description}</span>
  </CardWithLogoInTitle>
);

export default styled(PasswordActionSuccessfulCard)`
  ${passwordCardStyling}

  .ant-card-body {
    svg {
      height: 80px;
      width: 80px;
      color: ${({ theme }) => theme.colors.blue};
      margin-bottom: ${({ theme }) => theme.space.md};
    }
  }
`;
