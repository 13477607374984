import React from 'react';
import { createConnector } from 'cartiv';
import {
  Button, Form, Input, Icon,
} from 'antd';
import { parse } from 'query-string';
import { isEmpty } from 'lodash';
import { navigateTo } from '../../services/history';
import api from '../../services/api';
import { RegistrationStore } from '../Registration/RegistrationStore';
import { customPasswordValidator } from '../../utils/strings/Utils';
import { AuthStore } from '../../stores/AuthStore';
import { PATHS } from '../../constants';
import CardWithLogoInTitle from './CardWithLogoInTitle';

const connect = createConnector(React);


const passwordForm = (onSubmit, getFieldDecorator, loading) => (
  <Form
    name="basic"
    onSubmit={onSubmit}
  >
    <Form.Item
      name="password"
    >
      {getFieldDecorator('password', {
        rules: [
          {
            validator: customPasswordValidator,
          },
        ],
        validateTrigger: 'onSubmit',
      })(<Input.Password placeholder="Password" />)}
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Set Password & Log in
        <Icon type="right" className="button-icon-right" />
      </Button>
    </Form.Item>
  </Form>
);


@connect(RegistrationStore)
@connect(AuthStore)
class PasswordSetterCard extends React.Component {
  constructor(props) {
    super(props);

    const uriParams = parse(props.location.search);
    if (!isEmpty(uriParams)) {
      const { email, domainId, activationToken } = uriParams;

      if (email && domainId && activationToken) {
        this.state = {
          email,
          domainId,
          activationToken,
        };
      } else {
        navigateTo(PATHS.PASSWORD_SET_ERROR);
      }
    } else {
      navigateTo(PATHS.PASSWORD_SET_ERROR);
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { activationToken, domainId } = this.state;
    const { form: { validateFields } } = this.props;

    validateFields()
      .then(({ password }) => {
        api.Auth.onActivateAccount(password, activationToken, domainId)
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { email, loading, domainId } = this.state;

    return (
      <CardWithLogoInTitle title={`Set your password for ${email}`} domainId={domainId}>
        {passwordForm(this.onSubmit, getFieldDecorator, loading)}
      </CardWithLogoInTitle>
    );
  }
}

export default Form.create()(PasswordSetterCard);
