import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const GenericBackAndSubmit = ({
  hideBackButton = false,
  typeOfBreaker = 'br',
  children,
  className,
  flexDirection = 'row',
  innerFlexDirection = 'row',
  onSave,
}) => {
  const [leftChildren, setLeftChildren] = useState([]);
  const [rightChildren, setRightChildren] = useState([]);

  useEffect(() => {
    const left = [];
    const right = [];
    let curr = left;

    if (children) {
      if (children.length) {
        children.forEach((e) => {
          if (e.type === typeOfBreaker) {
            curr = right;
          } else {
            curr.push(e);
          }
        });
      } else {
        left.push(children);
      }

      setLeftChildren(left);
      setRightChildren(right);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);


  return (
    <div className={className}>
      <div>
        {!hideBackButton && (
          <Button
            type="link"
            htmlType="button"
            icon="left"
            onClick={() => window.history.back()}
          >
            Back
          </Button>
        )}
        {leftChildren}
      </div>
      <div>
        {onSave ? (
          <Button
            type="primary"
            size="large"
            onClick={onSave}
            icon="save"
          >
            Save
          </Button>
        ) : (
          <Button type="primary" size="large" htmlType="submit" icon="save">
            Save
          </Button>
        )}
        {rightChildren}
      </div>
    </div>
  );
};

export default styled(GenericBackAndSubmit)`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ flexDirection }) => flexDirection};
  margin-top: 15px;

  & > div {
    display: flex;
    flex-direction: ${({ innerFlexDirection }) => innerFlexDirection};
  }
`;
