import { createStore } from 'cartiv';
import { isEmpty } from 'lodash';
import { notification } from 'antd';
import api from '../services/api';
import backend from '../services/api/BackendApi';


export const ApplicationPublicDataStore = createStore({
  api,
  name: 'ApplicationPublicDataStore',
}, {
  getInitialState() {
    return {
      id: '',
      languages: [],
      applicationName: '',
      applicationUrl: '',
      applicationLogoUrl: '',
      applicationLogoSmallUrl: '',
      logoUrl: '',
      usedServices: [],
      requestFinished: false,
      loading: false,
    };
  },
  onLoadData(domainId) {
    if (domainId) {
      this.setState({ loading: true });
      backend.get('/domains/current', { headers: { 'jrc-domain': domainId } })
        .then((resp) => {
          if (resp && !isEmpty(resp)) {
            const { applicationLogoUrl, applicationLogoSmallUrl } = resp;
            const logoUrl = applicationLogoSmallUrl || applicationLogoUrl;

            this.setState({ logoUrl, ...resp });
          }
        })
        .catch(() => {
          console.error(`Could not load application public data for ${domainId}`);
        })
        .finally(() => this.setState({ loading: false, requestFinished: true }));
    } else {
      this.setState({ requestFinished: true });
    }
  },
  getCallbackUrlFor(action, status, payload) {
    return `${this.getApplicationUrl()}?source=jap&applicationId=${this.state.id}&action=${action}&status=${status}&payload=${encodeURIComponent(JSON.stringify(payload || {}))}`;
  },
  getApplicationUrl() {
    if (typeof this.state.applicationUrl !== 'undefined') {
      return this.state.applicationUrl;
    }
    throw new Error('Application data not loaded yet; call onLoadData');
  },
  getApplicationId() {
    if (typeof this.state.id !== 'undefined') {
      return this.state.id;
    }
    throw new Error('Application data not loaded yet; call onLoadData');
  },
});
