import { createStore } from 'cartiv';
import api from '../services/api';


// TODO Bogdan remove ?
export const MyProfileStore = createStore({
  api,
  name: 'MyProfile',
}, {
  getInitialState() {
    return {
      profileEmail: null,
      loaded: false,
    };
  },
});
