import React from 'react';
import { createConnector } from 'cartiv';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Button, Card } from 'antd';
import style from './DomainSelector.scss';
import api from '../../services/api';
import { DomainSelectorStore } from './DomainSelectorStore';
import { AuthStore } from '../../stores/AuthStore';
import { quotes } from '../Login/quotes';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(DomainSelectorStore)
@connect(AuthStore)
class DomainSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      jwtObject: {},
      quote: quotes[~~(quotes.length * Math.random())],
    };
    this.style = style;

    api.DomainSelector.onLoadUserDomains();
  }

  renderSelector() {
    return (
      <Card
        title="Select a domain"
        extra={(
          <Button size="large" type="link" icon="poweroff" onClick={api.Auth.onLogout} className="logout pull-right">
          Log out
          </Button>
      )}
      >
        <ul>
          {
            this.state.domains.map((domain) => (
              <li className={`domain domain-${domain}`} key={domain}>
                <Link to={`#${domain}`} onClick={() => api.DomainSelector.onSelectDomain(domain)}>
                  {domain}
                </Link>
              </li>
            ))
          }
        </ul>
      </Card>
    );
  }

  render() {
    if (this.state.domain) {
      return <Redirect to={PATHS.LANDING} />;
    } if (this.state.domains.length === 1) {
      api.DomainSelector.onSelectDomain(this.state.domains[0]);
      return <Redirect to={PATHS.LANDING} />;
    } if (!this.state.jwtObject) {
      return <Redirect to={PATHS.LOGIN} />;
    }
    return (
      <div className="DomainSelector">
        <div className="ant-row team-row">
          <div className="ant-col-7 ant-col-offset-2">
            {this.renderSelector()}
          </div>
          <div className="ant-col-offset-2 ant-col-7 right-side">
            <div className="quote">
              <div className="text">
                {`"${this.state.quote.text}"`}
              </div>
              <div className="author">
                {this.state.quote.author
                  .split(/[^a-zA-ZöäüÄÜÖéáűőúöüóíÉÁŰŐÚÖÜÍÓțșăâîȚȘĂÂÎ]/)
                  .map((n, i) => (i > 0 ? `${n[0]}.` : n))
                  .join(' ')}
              </div>
              <div
                className="image"
                style={{ backgroundImage: `url(images/team/${this.state.quote.id}.png-800.png)` }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { DomainSelector };
