import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { DATE_FORMAT } from '../../../constants';

class DateInputCls extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   *
   * @param value - moment
   * @param valueString - string representation of moment object
   */
  onChange(value, valueString) {
    const { validator = undefined, format = DATE_FORMAT, onChange } = this.props;
    const valid = this.validate(value);

    if (!validator || validator && valid) {
      onChange(value.format(format));
    }
  }

  validate(value) {
    const { validator } = this.props;
    let valid = true;

    if (validator) {
      valid = !!value && validator(value);
    }
    return valid;
  }

  render() {
    const {
      format = DATE_FORMAT, className, value, fieldClasses, name, entityDefinition, required,
    } = this.props;

    return (
      <div className={`${name} DateInput form-group ${fieldClasses.groupClass} ${className}`}>
        <label
          className={`${fieldClasses.labelClass} form-control-label`}
          htmlFor={`${name}-form-control`}
        >
          {entityDefinition.label}
          {required ? <span className="required">*</span> : null}
        </label>
        <div className={`input-wrapper ${fieldClasses.inputWrapperClass}`}>
          { // DO NOT MERGE the two datepickers TODO otherwise defaultValue won't work
            value
              ? (
                <DatePicker
                  defaultValue={moment(value)}
                  format={format}
                  onChange={this.onChange}
                />
              ) : null
          }
          {
            !value
              ? (
                <DatePicker
                  format={format}
                  onChange={this.onChange}
                />
              ) : null
          }


          {this.validate() ? null
            : (
              <small
                className="validationMessage"
              >
                {this.props.validationMessage || `Please supply a valid ${entityDefinition.label}`}
              </small>
            )}
          {entityDefinition.details
            ? <small className="details form-text text-muted">{entityDefinition.details}</small> : null}
        </div>
      </div>
    );
  }
}

DateInputCls.defaultProps = {
  value: null,
  defaultValue: '',
  format: undefined,
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  placeholder: '',
  onChange: (v) => console.log(v),
};

export const DateInput = styled(DateInputCls)`
  .input-wrapper > *{
    width: 100%;
  }
`;
