import React from 'react';
import './AccessControlList.scss';
import { Button, Checkbox } from 'antd';
import { StringInput } from '../genericEditor/FormControls';

const PERM_VALUES = ['OWNED', 'GROUP', 'ALL'];
const PERMS = {
  OWNED_READ: 0b100000,
  OWNED_WRITE: 0b010000,
  GROUP_READ: 0b001000,
  GROUP_WRITE: 0b000100,
  ALL_READ: 0b000010,
  ALL_WRITE: 0b000001,
};


function Role({
                index, role, collectionName, nameValue, description, permission, onChangePermission, onChangeName, onDelete, onChangeDescription,
              }) {
  function setBitsToNull(perm, permType) {
    if (permType === 'WRITE') {
      return perm & 0b101010;
    }
    return perm & 0b010101;
  }

  function changePermission(permType, permValue) {
    const perm = setBitsToNull(permission, permType);
    const newPermission = perm | PERMS[`${permValue}_${permType}`];
    if (newPermission !== permission) {
      onChangePermission(index, newPermission);
    } else {
      onChangePermission(index, perm);
    }
  }

  function changeName(e) {
    onChangeName(e, index);
  }

  function changeDescription(e) {
    onChangeDescription(e, index);
  }

  function onDeleteRole() {
    onDelete(index);
  }

  function contains(perm1, perm2, permType) {
    perm1 = setBitsToNull(perm1, permType === 'READ' ? 'WRITE' : 'READ');
    const lowestBit = perm1 & -perm1;
    return ((lowestBit & perm2) === perm2);
  }

  return (
    <tr>
      <td className="role-name-wrapper">
        <div className="role-prefix">
          {' '}
          ROLE_COLLECTION_
          {collectionName.toUpperCase()}
          _
        </div>
        <StringInput
          value={nameValue}
          entityDefinition={{}}
          onChange={changeName}
        />
      </td>
      <td className="role-description">
        <StringInput
          value={description}
          entityDefinition={{}}
          onChange={changeDescription}
        />
      </td>
      {['READ', 'WRITE']
        .map((permType, i) => PERM_VALUES
          .map((permValue, j) => {
            const checked = contains(permission, PERMS[`${permValue}_${permType}`], permType);
            return (
              <td key={`${i}${j}`} className="radio-button">
                <Checkbox
                  name={`${permType}-${index}`}
                  title={`${permType} ${permValue}`}
                  checked={checked}
                  onClick={() => changePermission(permType, permValue)}
                  onChange={() => {
                  }}
                />
              </td>
            );
          }))}

      <td>
        <Button
          type="danger"
          htmlType="button"
          title="Delete element"
          className="remove-role"
          onClick={onDeleteRole}
          icon="delete"
        />
      </td>
    </tr>
  );
}

export const AccessControlEditor = function AccessControlEditor(
  {
    index,
    roles,
    collection,
    fields,
    onChangeName,
    onDeleteRole,
    onChangeDescription,
    onChangePermission,
    onAddNewRole,
  },
) {
  function addNewRole() {
    onAddNewRole();
  }

  return (
    <div className="AccessControlEditor">
      <div className="desc">
        Specify the roles with permissions for the collection. These roles will be available
        later to assign to user profiles.
      </div>
      <table className="table table-hover">
        <thead>
        <tr className="header-row-1">
          <th className="border-none"/>
          <th className="border-none"/>

          <th colSpan={3}>Read</th>
          <th colSpan={3}>Write</th>
        </tr>
        <tr className="header-row-2">
          <th className="border-none">Role name</th>
          <th className="border-none">Description</th>

          <th>Owned</th>
          <th>Group</th>
          <th>All</th>
          <th>Owned</th>
          <th>Group</th>
          <th>All</th>

        </tr>
        </thead>
        <tbody>
        {roles.map((role, index) => (
          <Role
            key={collection + index}
            index={index}
            role={role.name}
            collectionName={collection}
            description={role.description}
            permission={role.permission}
            onChangePermission={onChangePermission}
            nameValue={role.name.split('_')[3]}
            onChangeName={onChangeName}
            onDelete={onDeleteRole}
            onChangeDescription={onChangeDescription}
          />
        ))}
        </tbody>
      </table>
      <div className="add-element-wrapper">
        <Button
          type="default"
          htmlType="button"
          onClick={addNewRole}
          icon="plus"
        >
          Add custom role
        </Button>
      </div>
    </div>
  );
};
