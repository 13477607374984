import React from 'react';
import { Input } from 'antd';
import * as style from './TextInput.scss';

export class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { valid: true };
    this.dummy = style;
  }

  render() {
    const value = typeof this.props.value !== 'undefined' && this.props.value !== null
      ? this.props.value : this.props.defaultValue;
    const groupClass = `form-group ${this.props.fieldClasses.groupClass}`;
    const hasValueClass = value ? 'has-value' : 'empty';
    const focusClass = this.state.focused ? 'focused' : 'blurred';
    return (
      <div
        className={`${this.props.name} TextInput ${groupClass} ${hasValueClass} ${focusClass}`}
      >
        <label
          className={`${this.props.fieldClasses.labelClass} form-control-label`}
          htmlFor={`${this.props.name}-form-control`}
        >
          {this.props.entityDefinition.label}
          {this.props.required ? <span className="required">*</span> : null}
        </label>

        <div className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
          <Input.TextArea
            name={this.props.name}
            id={`${this.props.name}-form-control`}
            className={`${this.props.name} form-control ${this.props.fieldClasses.inputClass}`}
            rows={this.props.entityDefinition.rows || 12}
            placeholder={this.props.entityDefinition.placeholder}
            value={value}
            onChange={(e) => this.props.onChange(e.target.value)}
          >
          </Input.TextArea>
          {this.state.valid ? null
            : (
              <small
                className="validationMessage"
              >
                {this.props.validationMessage || `Please supply a valid ${this.props.entityDefinition.label}`}
              </small>
            )}
          {this.props.entityDefinition.details
            ? <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null}
        </div>
      </div>
    );
  }
}

TextInput.defaultProps = {
  value: '',
  defaultValue: '',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: { rows: 6 },
  onChange: (v) => console.log(v),
};
