import React from 'react';
import { Select } from 'antd';
import './SelectInput.scss';
import { generateAntOptions } from '../../../lib/utils';


export class SelectInput extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(singleSelection) {
    this.props.onChange(singleSelection);
  }

  render() {
    const {
      entityDefinition, value, name, fieldClasses,
    } = this.props;
    const options = (entityDefinition.options || [])
      .map((o) => (Object.getPrototypeOf(o) === String.prototype ? { value: o, label: o } : o));

    return (
      <div className={`SelectInput ${name} form-group ${fieldClasses.groupClass}`}>
        <label
          className={`${fieldClasses.labelClass} form-control-label`}
          htmlFor={`${name}-form-control`}
        >
          {entityDefinition.label}
        </label>
        <div className={`input-wrapper ${fieldClasses.inputWrapperClass}`}>
          <Select
            style={{ width: '100%' }}
            placeholder={`Please select ${entityDefinition.label}`}
            onChange={this.onChange}
            value={value}
          >
            {generateAntOptions(options)}
          </Select>
          {
            entityDefinition.details
              ? <small className="details form-text text-muted">{entityDefinition.details}</small>
              : null
          }
        </div>
      </div>
    );
  }
}

SelectInput.defaultProps = {
  // value: undefined,
  defaultValue: '',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: { options: [] },
  onChange: (v) => console.log(v),
};
