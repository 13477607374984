import { extend } from 'umi-request';
import { handleError } from '../../utils/api/Utils';
import { AuthStore } from '../../stores/AuthStore';

class AbstractApi {
  request = null;

  /**
   * Get cookies stored active jwt
   * @returns {string|null}
   */
  static getAuthenticationToken() {
    const token = AuthStore.getToken();
    if (token) {
      return `Bearer ${token}`;
    }
    return null;
  }

  constructor(requestCfg = {}) {
    const incomingHeaders = requestCfg.headers || [];

    this.request = extend({
      ...requestCfg,
      headers: {
        'Accept': 'application/json',
        credentials: 'include', // include, same-origin, *omit
        mode: 'cors', // no-cors, *same-origin
        redirect: 'follow', // *manual, error
        referrer: 'no-referrer', // *client
        ...incomingHeaders,
      },
      errorHandler: handleError,
    });

    this.setupInterceptors();
  }

  /**
   * Get http request
   * @param path
   * @param options
   * @returns {*}
   */
  get(path = '', options = {}) {
    return this.request.get(path, options);
  }

  /**
   * Delete http request
   * @param path
   * @param options
   * @returns {*}
   */
  delete(path = '', options) {
    return this.request.delete(`${path}`, options);
  }

  /**
   * Post http request
   * @param path
   * @param options
   * @returns {*}
   */
  post(path = '', options) {
    return this.request.post(`${path}`, options);
  }

  /**
   * Put http request
   * @param path
   * @param options
   * @returns {*}
   */
  put(path = '', options) {
    return this.request.put(`${path}`, options);
  }

  /**
   * Patch http request
   * @param path
   * @param options
   * @returns {*}
   */
  patch(path = '', options) {
    return this.request.patch(`${path}`, options);
  }

  setupInterceptors() {
    const setAuthHeader = async (ctx, next) => {
      const token = AbstractApi.getAuthenticationToken();

      if (token && !ctx.req.options.headers.Authorization) {
        ctx.req.options.headers.Authorization = token;
      }

      await next();
    };

    const interceptors = { // https://github.com/umijs/umi-request#interceptor
      request: [setAuthHeader],
    };

    interceptors.request.forEach((interceptor) => {
      this.request.use(interceptor, { global: false });
    });
  }
}

export default AbstractApi;
