import { createStore } from 'cartiv';
import api from '../services/api';


export const EmbeddableStore = createStore({
  api,
  name: 'Embeddable',
}, {
  getInitialState() {
    return {
      loading: false,
      success: false,
      targetId: '',
      options: {},
    };
  },
  onEnable() {
    window.parent.postMessage({loaded: true}, '*'); // TODO
    window.addEventListener('message', this.onReceiveMessage, false);
  },
  onDisable() {
    window.removeEventListener('message', this.onReceiveMessage)
  },
  onReceiveMessage(event) {
    if (event.data.method === 'init') {
      this.setState({options: event.data.options});
      if (event.data.options.customCss) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = event.data.options.customCss;
        document.head.appendChild(link);
      }
    }
  },

});
