import React from 'react';
import { createConnector } from 'cartiv';
import styled from 'styled-components';
import api from '../../services/api';
import { LoginStore } from '../Login/LoginStore';
import CardWithLogoInTitle from '../PasswordSet/CardWithLogoInTitle';

const connect = createConnector(React);


const cardBody = (domainAndGroup, sendRequest) => {
  if (domainAndGroup && domainAndGroup.length > 0) {
    return (
      <ul className="domain-list">
        {domainAndGroup.map((value, i) => (
          <li className="domain" key={value.domainId}>
            <button
              type="button"
              className="domain-button"
              onClick={() => sendRequest(value)}
            >
              {`${value.domainId}`}
            </button>
          </li>
        ))}
      </ul>
    );
  }

  return (<div>This user is part of no domain.</div>);
};


@connect(LoginStore)
class PasswordResetDomainSelectorCard extends React.Component {
  constructor(props) {
    super(props);

    this.sendRequest = this.sendRequest.bind(this);
  }

  sendRequest = (value) => {
    const { domainId } = value;
    const { email } = this.props;

    api.ApplicationPublicDataStore.onLoadData(domainId);

    api.Login.onSendPasswordResetRequest(
      email,
      domainId,
    );
  };

  render() {
    const { domainAndGroup, className } = this.props;
    const { loading } = this.state;

    return (
      <CardWithLogoInTitle title="Select a domain" loading={loading} className={className}>
        {cardBody(domainAndGroup, this.sendRequest)}
      </CardWithLogoInTitle>
    );
  }
}


export default styled(PasswordResetDomainSelectorCard)`
.domain-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .domain {
    .domain-button {
      background-color: unset;
      text-align: left;
      width: 100%;
      color: ${({ theme }) => theme.colors.primary};
      display: flex;
      flex-direction: column;
      padding: ${({ theme }) => theme.space.xs};
      box-shadow: 1px 1px 3px rgba(0, 0, 0, .25);
      text-transform: uppercase;
      margin-bottom: ${({ theme }) => theme.space.xxs};
      transition: all .35s ease;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};

        small {
          color: ${({ theme }) => theme.colors.white};
        }
      }

      small {
        text-transform: none;
        color: gray;
      }
    }
  }
}
`;
