import React from 'react';
import {
  Avatar, Button, Divider, Dropdown, Menu,
} from 'antd';
import { createConnector } from 'cartiv';
import api from '../../services/api';
import { AuthStore } from '../../stores/AuthStore';
import { DomainSelectorStore } from '../DomainSelector/DomainSelectorStore';
import { UserStore } from '../../stores/UserStore';
import { navigateTo } from '../../services/history';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(AuthStore)
@connect(DomainSelectorStore)
@connect(UserStore)
class AccountManagementDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onDomainLogout = this.onDomainLogout.bind(this);
    this.onDomainChange = this.onDomainChange.bind(this);
  }

  componentDidMount() {
    api.DomainSelector.onGetDomainInformation();
    api.DomainSelector.onLoadUserDomains();
    api.User.onLoadAccount(AuthStore.onGetStateObject(localStorage.getItem('token')).jwtObject.userId);
  }

  onDomainLogout() {
    const { domains } = this.state;
    if (domains.length === 1) {
      api.Auth.onLogout();
    }
    api.DomainSelector.onClearDomain();
  }

  onDomainChange(applicationName) {
    api.DomainSelector.onSelectDomain(applicationName, api.broadcastDomainChange);
  }

  render() {
    const {
      domains = [], domainInfo, jwtObject, avatarUrl, className,
    } = this.state;

    const menu = (
      <Menu className="Header">
        {
          domains.map((applicationName, index) => {
            const domainClassName = (domainInfo && domainInfo.id === applicationName)
              ? 'Header active-domain' : 'Header';

            return (
              <Menu.Item key={`key-${index}`} className={domainClassName}>
                <Button
                  htmlType="button"
                  type="link"
                  size="small"
                  fontWeight="normal"
                  className="Header domain-menu-item header-dropdown-menu-item"
                  onClick={(event) => {
                    event.preventDefault();
                    this.onDomainChange(applicationName);
                  }}
                >
                  {applicationName}
                </Button>
              </Menu.Item>
            );
          })
        }
        <Divider style={{ margin: 0 }} />
        <Menu.Item>
          <Button
            htmlType="button"
            type="link"
            size="small"
            className="logout header-dropdown-menu-item"
            onClick={() => {
              navigateTo(PATHS.ACCOUNT);
            }}
          >
            Account
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button
            htmlType="button"
            type="link"
            size="small"
            className="logout header-dropdown-menu-item"
            onClick={api.Auth.onLogout}
          >
            Log out
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} className="Header navbar-nav user-menu">
        <div className="Header horizontal-container">
          <Avatar src={avatarUrl} />
          <span className="user-email">
            {jwtObject && jwtObject.sub}
          </span>
        </div>
      </Dropdown>
    );
  }
}

export default AccountManagementDropdown;
