import React from 'react';
import PropTypes from 'prop-types';
import './BooleanInput.scss';
import { Checkbox } from 'antd';

export class BooleanInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const value = typeof this.props.value !== 'undefined' && this.props.value !== null ?
      this.props.value : !!this.props.defaultValue;
    return (
      <div
        className={`${this.props.name} BooleanInput form-group ${this.props.fieldClasses.groupClass} `}>
        <div className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
          <Checkbox onChange={({target: {checked}}) => this.props.onChange(checked)}
                    name={this.props.name}
                    checked={value}
                    id={`${this.props.name}-form-control`}
                    className={`${this.props.name} ${this.props.fieldClasses.inputClass}`}
          >{this.props.entityDefinition.label}</Checkbox>
          {this.props.entityDefinition.details ?
            <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null
          }
        </div>
      </div>
    );
  }
}

BooleanInput.defaultProps = {
  value: false,
  defaultValue: false,
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: {
    label: 'checkbox',
  },
  onChange: (v) => console.log(v),
};
BooleanInput.protoTypes = {
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  fieldClasses: PropTypes.shape({
    groupClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    inputWrapperClass: PropTypes.string,
  }),
  entityDefinition: PropTypes.shape({
    label: PropTypes.string,
    details: PropTypes.string,
  }),
  onChange: PropTypes.func,
};
