import React from 'react';
import { Tag } from 'antd';

const TagsCell = ({ tags = [], className }) => (
  <span className={className}>
    {
      tags.map((tag) => (
        <Tag key={tag}>
          {(`${tag}`).toUpperCase()}
        </Tag>
      ))
    }
  </span>
);

export default TagsCell;
