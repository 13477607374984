import React from 'react';
import PasswordSetErrorCard from '../../components/PasswordSet/PasswordSetErrorCard';
import PasswordSetCardsContainer from './PasswordSetCardsContainer';


export default (props) => (
  <PasswordSetCardsContainer>
    <PasswordSetErrorCard {...props} />
  </PasswordSetCardsContainer>
);
