import { config } from '../../config';
// eslint-disable-next-line import/no-cycle
import AbstractApi from './AbstractApi';
import { verifyUnauthorized } from '../../utils/interceptors/GenericInterceptors';


class BackendApi extends AbstractApi {
  static getUrl = () => config.backendUrl;

  constructor() {
    super({
      prefix: BackendApi.getUrl(),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setupInterceptors();
  }

  setupInterceptors() {
    super.setupInterceptors();

    const { request, response } = this.request.interceptors;
    const interceptors = {
      response: [verifyUnauthorized],
    };

    interceptors.response.forEach((interceptor) => {
      response.use(interceptor, { global: false });
    });
  }

  downloadFile(path, params) {
    return this.get(path, {
      params,
      responseType: 'blob',
    });
  }
}

export default new BackendApi();
