import React, { Component } from 'react';
import { createConnector } from 'cartiv';
import { Redirect } from 'react-router';
import BackendApi from '../../services/api/BackendApi';
import { buildRequestOptions } from '../../utils/api/Utils';
import { AuthStore } from '../../stores/AuthStore';
import api from '../../services/api';
import { PATHS } from '../../constants';


const connect = createConnector(React);

@connect(AuthStore)
class Authentication extends Component {
  async componentDidMount() {
    const urlHash = this.props.location.hash;
    const urlSearch = this.props.location.search;

    const searchParams = urlHash
      ? new URLSearchParams(urlHash.replace('#', '?'))
      : new URLSearchParams(urlSearch);

    const accessToken = searchParams.get('access_token');
    const code = searchParams.get('code');
    const stateParamString = localStorage.getItem('authDetails');
    const stateParamJson = JSON.parse(stateParamString);
    const {
      profileIds, groupIds, providerId, domainId,
    } = stateParamJson;

    const { location } = window;
    const { protocol, host } = location;
    const redirectUri = `${protocol}//${host}${PATHS.ACCOUNT_AUTHENTICATE}`;
    const fetchOptions = buildRequestOptions()
      .withDomainId(domainId)
      .noToken()
      .body({
        accessToken: accessToken || code,
        groupIds,
        profileIds,
        redirectUri,
      })
      .build();

    const tokenResponse = await BackendApi.post(
      `${PATHS.ACCOUNT_AUTHENTICATE}/oauth2/${providerId}`,
      fetchOptions,
    );

    if (tokenResponse) {
      const { token } = tokenResponse;
      api.Login.onLoginSuccessful(token);
    }
  }

  render() {
    return <div />;
  }
}

export { Authentication };
