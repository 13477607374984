import React from 'react';
import { Button } from 'antd';
import fileBackend from '../../../services/api/FileApi';
import { genericErrorHandler } from '../../../utils/Common';
import './ImageInput.scss';

/**
 * !!! Empty string is intentional
 * @type {string}
 */
const DEFAULT_UPLOAD_URL = '';

export class ImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false };
  }

  onFileUpload(changeEv) {
    if (changeEv.target.files && changeEv.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.addEventListener('error', (e) => {
        genericErrorHandler(e, () => this.setState({ uploading: false }));
      });
      fileReader.addEventListener('load', (e) => {
        fetch(e.target.result)
          .then((resp) => {
            resp.blob()
              .then((blob) => {
                fileBackend.postFile([blob])
                  .then((resp) => {
                    this.setState({ uploading: false });
                    const uploadURL = resp.filePaths[0];
                    this.setState({ value: uploadURL });
                    this.props.onChange(uploadURL);
                  })
                  .catch((error) => {
                    genericErrorHandler(error.message, () => { this.setState({ uploading: false }); });
                  });
              })
              .catch((error) => {
                this.setState({ uploading: false });
                genericErrorHandler(error.message);
              });
          })
          .catch((error) => {
            this.setState({ uploading: false });
            genericErrorHandler(error.message);
          });
      });
      this.setState({ uploading: true });
      fileReader.readAsDataURL(changeEv.target.files[0]);
    }
  }

  removeImage(e) {
    this.props.onChange(DEFAULT_UPLOAD_URL);
  }

  render() {
    const {
      name, value, defaultValue, fieldClasses, entityDefinition, uploading,
    } = this.props;

    const val = typeof value !== 'undefined' && value !== null
      ? value : defaultValue;

    return (
      <div className={`${name} ImageInput form-group ${fieldClasses.groupClass}`}>
        <label
          className={`${fieldClasses.labelClass} form-control-label`}
          htmlFor={`${name}-form-control`}
        >
          {entityDefinition.label}
        </label>
        <div className={`input-wrapper ${fieldClasses.inputWrapperClass}`}>
          <input
            type="file"
            name={name}
            id={`${name}-form-control`}
            className={`${name} form-control ${fieldClasses.inputClass}`}
            onChange={this.onFileUpload.bind(this)}
          />
          {entityDefinition.details
            ? <small className="details form-text text-muted">{entityDefinition.details}</small> : null}
          {val ? [
            <div
              key="image"
              className={`${name} image`}
              style={{ backgroundImage: `url('${val}?w=400')` }}
            />,
            <Button
              icon="delete"
              type="danger"
              htmlType="button"
              key="button"
              onClick={this.removeImage.bind(this)}
              className="remove"
            />,

          ] : (
            uploading
              ? <i className="fa fa-spinner fa-spin fa-3x" />
              : null
          )}
        </div>
      </div>
    );
  }
}

ImageInput.defaultProps = {
  value: '',
  defaultValue: '',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: {},
  onChange: (v) => console.log(v),
};
