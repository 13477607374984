import React, { Component } from 'react';
import { createConnector } from 'cartiv';
import { Card, Skeleton } from 'antd';
import styled from 'styled-components';
import api from '../../services/api';
import { ApplicationPublicDataStore } from '../../stores/ApplicationPublicDataStore';
import { ReactComponent as Logo } from './set-password-logo.svg';


const connect = createConnector(React);


@connect(ApplicationPublicDataStore)
class CardWithLogoInTitle extends Component {
  constructor(props) {
    super(props);

    api.ApplicationPublicDataStore.onLoadData(props.domainId);
  }

  renderTitle = (title, logoUrl, requestFinished) => {
    let logo;

    if (!requestFinished) {
      logo = <Skeleton active avatar paragraph={false} title={false} />;
    } else {
      logo = logoUrl ? <img src={logoUrl} alt="logo" /> : <Logo />;
    }

    return (
      <>
        <div className="logo-container">{logo}</div>
        <span>{title}</span>
      </>
    );
  };

  render() {
    const {
      title, children, loading, className,
    } = this.props;
    const { logoUrl, requestFinished } = this.state;

    return (
      <Card
        className={className}
        title={this.renderTitle(title, logoUrl, requestFinished)}
        loading={loading}
      >
        {children}
      </Card>
    );
  }
}

export default styled(CardWithLogoInTitle)`
.logo-container {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: ${({ theme }) => theme.space.xs};

  img {
    width: 100%;
  }

  .ant-skeleton-header {
    padding: 0;

    .ant-skeleton-avatar {
      width: 38px !important;
      height: 38px !important;
    }
  }
}
`;
