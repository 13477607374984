import React from 'react';
import {
  Button, Form, Icon,
} from 'antd';
import styled from 'styled-components';
import { get } from 'lodash';
import { createConnector } from 'cartiv';
import api from '../../services/api';
import { passwordCardStyling } from './PasswordResetRequestCard';
import { parse } from '../../lib/query-string';
import { LoginStore } from '../Login/LoginStore';
import PasswordInputForm from './PasswordInputForm';
import CardWithLogoInTitle from '../PasswordSet/CardWithLogoInTitle';


const connect = createConnector(React);


@connect(LoginStore)
class PasswordResetInputCard extends React.Component {
  constructor(props) {
    super(props);

    const { domainId } = parse(window.location.search);
    api.ApplicationPublicDataStore.onLoadData(domainId);

    if (domainId) {
      api.Registration.onLoadAccountSettingsByDomainId(domainId);
      this.state = { domainId };
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onBackToLogin = this.onBackToLogin.bind(this);
    this.renderPasswordForm = this.renderPasswordForm.bind(this);
  }


  onSubmit = (event) => {
    event.preventDefault();

    const { form } = this.props;
    const { validateFields } = form;
    validateFields(['password'])
      .then(({ password }) => {
        validateFields(['repeat-password'])
          .then(() => {
            const resetToken = get(this.props, 'match.params.resetToken');

            api.Login.onResetPasswordWithToken(password, resetToken);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  onBackToLogin = () => {
    const { domainId } = this.state;

    api.Login.onBackToLogin(domainId);
  }

  renderPasswordForm = () => {
    const { form } = this.props;
    const { loading } = this.state;

    return (
      <PasswordInputForm onSubmit={this.onSubmit} form={form} loading={loading} />
    );
  };

  render() {
    const { className } = this.props;

    return (
      <CardWithLogoInTitle title="Reset Password" className={className}>
        {this.renderPasswordForm()}
        <Button type="link" onClick={this.onBackToLogin}>
          <Icon type="left" />
          Back to login
        </Button>
      </CardWithLogoInTitle>
    );
  }
}

const PasswordResetInputForm = Form.create()(PasswordResetInputCard);
export default styled(PasswordResetInputForm)`
  ${passwordCardStyling}
`;
