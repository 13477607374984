import { config } from '../../config';
import AbstractApi from './AbstractApi';


class FileApi extends AbstractApi {
  static getUrl = () => config.backendFileUrl;

  constructor() {
    super({
      prefix: FileApi.getUrl(),
    });
  }

  postFile(fileOrFiles) {
    const data = new FormData();

    [].concat(fileOrFiles)
      .forEach((file) => data.append('files', file));

    return this.post('', { data });
  }
}

export default new FileApi();
