import React from 'react';
import './MultiSelectInput.scss';
import { Select } from 'antd';
import { generateAntOptions } from '../../../lib/utils';

export class MultiSelectInput extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(valueArray) {
    this.props.onChange(valueArray);
  }

  render() {
    const {
      name, fieldClasses, entityDefinition, value,
    } = this.props;
    const { options } = entityDefinition;

    return (
      <div className={`${name} form-group ${fieldClasses.groupClass}`}>
        {
          entityDefinition && (
            <>
              <div className={`form-control-label ${fieldClasses.labelClass}`}>
                {entityDefinition.label}
              </div>
              <div className={`input-wrapper ${fieldClasses.inputWrapperClass}`}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={`Please select ${entityDefinition.label}`}
                  mode="multiple"
                  onChange={this.onChange}
                  value={value}
                >
                  {generateAntOptions(options)}
                </Select>
                {
                  entityDefinition.details
                    ? <small className="details form-text text-muted">{entityDefinition.details}</small>
                    : null
                }
              </div>
            </>
          )
        }
      </div>
    );
  }
}

MultiSelectInput.defaultProps = {
  value: [],
  defaultValue: [],
  entityDefinition: {
    options: [{
      value: 'sample',
      label: 'Sample Label',
    }],
  },
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  onChange: (v) => console.log(v),
};
