import React from 'react';
import PasswordChangeInputCard from '../../components/PasswordReset/PasswordChangeInputCard';
import PasswordSetCardsContainer from './PasswordSetCardsContainer';


export default (props) => (
  <PasswordSetCardsContainer>
    <PasswordChangeInputCard {...props} />
  </PasswordSetCardsContainer>
);
