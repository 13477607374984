import { History, createBrowserHistory } from 'history';
import { AnalyticsStore } from '../lib/AnalyticsStore';

function createClientHistory(): History { // TODO fix this to work with react router
  if (typeof document === 'undefined') {
    throw "There shall always be a document object (no SSR compatibility)";
  }
  const history = createBrowserHistory();

  setTimeout(() => {
    AnalyticsStore.init();
  }, 0);

  history.listen((location) => {
    AnalyticsStore.onPageView(location.pathname);
  });
  return history;
}

export const history: History = createClientHistory();

export const navigateTo = (path: string, ...state: any[]) => {
  history.push(path, ...state); // TODO improve
};

export function navigateBack() {
  history.goBack(); // TODO improve
}
