import { createStore } from 'cartiv';
import { merge } from 'lodash';
import api from './i18n_api';
import EN from './en';
import DE from './de';

const translations = {
  en: EN,
  de: merge({}, EN, DE),
};

const defaultLocale = 'en';
const DEFAULT = translations[defaultLocale];

export const I18nStore = createStore({
  api,
  name: 'language',
}, {
  getInitialState() {
    return {
      language: defaultLocale,
      strings: DEFAULT,
    };
  },

  translate(i18nText) {
    if (typeof (i18nText) !== 'object') {
      return i18nText;
    }

    if (this.state.language === 'de') {
      return i18nText['de'];
    }
    return i18nText['en']
  },

  onChange(language) {
    this.setState({
      language: translations[language] ? language : defaultLocale,
      strings: translations[language] || DEFAULT,
    });
  },
});
