import React from 'react';
import { createConnector } from 'cartiv';
import { Card, Table } from 'antd';
import api from '../../services/api';
import { ComponentsPageStore } from './ComponentsPageStore';
import './ComponentsPage.scss';
import { navigateTo } from '../../services/history';

const connect = createConnector(React);

@connect(ComponentsPageStore)
class ComponentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { components: [] };
  }

  componentDidMount() {
    api.ComponentsPage.onLoadComponents();
  }

  render() {
    const { components } = this.state;
    return (
      <div className="ComponentsPage content-wrapper">
        <Card title="WebApp Components">
          <Table
            dataSource={components
              .filter(comp => comp._type === 'page')}
            rowKey="_id"
            columns={[
              {
                title: 'Page',
                key: 'name',
                dataIndex: 'name',
              },
            ]}
            onRow={(comp) => ({
              onClick: () => navigateTo(`components/${comp._id}`),
            })}
          />
          <Table
            dataSource={components
              .filter(comp => comp._type === 'element')}
            rowKey="_id"
            columns={[
              {
                title: 'Element',
                key: 'name',
                dataIndex: 'name',
              },
            ]}
            onRow={(comp) => ({ onClick: () => navigateTo(`components/${comp._id}`) })}
          />
        </Card>
      </div>
    );
  }
}

export default ComponentsPage;
