import React from 'react';
import { createConnector } from 'cartiv';
import { Button, Card } from 'antd';
import style from './DomainSettingsPage.scss';
import api from '../../services/api';
import { DomainSettingsPageStore, SERVICES } from './DomainSettingsPageStore';
import { GroupInput } from '../genericEditor/FormControls';
import { clone } from '../../lib/clone';
import { AuthStore } from '../../stores/AuthStore';
import { LayoutStore } from '../../components/Layout/LayoutStore';
import { navigateTo } from '../../services/history';
import GenericBackAndSubmit from '../../components/GenericFooter/GenericBackAndSubmit';
import { DEFAULT_AVAILABLE_LANGUAGES, PATHS } from '../../constants';

const connect = createConnector(React);

const NewDomainForm = ({
  onChangeId, onChangeProjectName, newId, projectName,
}) => (
  <Card title="New domain ">
    <h2 />
    <div className="id-input">
      Domain id:
      <input type="text" value={newId} onChange={onChangeId} />
    </div>
    <div className="project-name-input">
      Project name:
      <input type="text" value={projectName} onChange={onChangeProjectName} />
    </div>
    <Button
      type="primary"
      size="larget"
      icon="save"
      onClick={this.createDomain}
    >
      Create
    </Button>
  </Card>
);

const DomainEditor = ({
  domain,
  onChange,
  submitForm,
  showDomainUsers,
}) => (
  <Card title={`${domain.id} settings`}>
    <GroupInput
      name="domain"
      entityDefinition={{ spec: DOMAIN_DEF }}
      value={clone(domain)}
      onChange={onChange}
      fieldClasses={{
        groupClass: 'ant-row',
        labelClass: 'ant-col-6',
        inputClass: '',
        inputWrapperClass: 'ant-col-18',
      }}
    />
    <GenericBackAndSubmit hideBackButton onSave={submitForm}>
      <Button
        type="link"
        onClick={showDomainUsers}
        icon="team"
      >
        Manage domain users
      </Button>
    </GenericBackAndSubmit>
  </Card>
);

@connect(DomainSettingsPageStore)
@connect(AuthStore)
@connect(LayoutStore)
class DomainSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: DomainSettingsPageStore.state.domain,
      newId: '',
      projectName: '',
    };

    this.servicesToDelete = [];

    this.style = style;
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.createDomain = this.createDomain.bind(this);
    this.onChangeId = this.onChangeId.bind(this);
    this.onChangeProjectName = this.onChangeProjectName.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.showDomainUsers = this.showDomainUsers.bind(this);

    const pathname = this.props.location.pathname.split('/');
    this.new = pathname[pathname.length - 1] === 'new';
    if (this.new) {
      api.DomainSettingsPage.onResetDomain();
    } else {
      if (this.props.location.search) {
        const path = this.props.location.search.split('=');
        api.DomainSettingsPage.onSetManagedDomainId(path[1]);
      }
      api.DomainSettingsPage.onLoadDomainSettingsPage();
    }
  }

  buildDomainData(value) {
    const { services } = value;
    value.usedServices = [];

    const domainData = value;

    if (services) {
      if (!!services.Email && services.Email === true) {
        domainData.usedServices.push(SERVICES.EMAIL_SERVICE);
      }
      if (!!services.Collection && services.Collection === true) {
        domainData.usedServices.push(SERVICES.COLLECTION_SERVICE);
      }
    }
    domainData.usedServices.push(SERVICES.FILE_SERVICE);
    domainData.usedServices.push(SERVICES.ACCOUNT_SERVICE);
    domainData.usedServices.push(SERVICES.AUDIT_SERVICE);

    return domainData;
  }

  submitForm() {
    const data = this.state.domain;
    const domainData = this.buildDomainData(data);
    api.DomainSettingsPage.onUpdateDomainSettings(domainData, this.servicesToDelete);
  }

  createDomain() {
    let tempValue = {};
    if (this.state.domain) {
      tempValue = clone(this.state.domain);
    }
    tempValue.id = this.state.newId;
    this.setState({ domain: tempValue });
    api.DomainSettingsPage.onCreateNewDomain(tempValue);
  }

  getServiceToDelete(currentServices, updateDomain) {
    const keyObj = Object.keys(currentServices);
    const valueCurrentServices = Object.values(currentServices);
    let serviceToDelete = null;

    let valueNewServices = [];

    if (!!updateDomain && !!updateDomain.services) {
      valueNewServices = Object.values(updateDomain.services);
    }

    for (let i = 0; i < keyObj.length; i++) {
      if (valueCurrentServices[i] === true && valueNewServices[i] === false && !this.servicesToDelete.includes(keyObj[i])) {
        serviceToDelete = keyObj[i];
        this.showDeleteModal(serviceToDelete);
      }
    }
  }

  showDeleteModal(service) {
    api.Modals.onShowModal({
      id: `deactivate-or-delete-service-${service}`,
      title: `Deactivate or delete ${service}`,
      description: `Do you want to deactivate or delete ${service} service?`,
      closeButton: false,
      actions: [
        {
          label: 'Deactivate',
          action: () => api.Modals.onCloseModal(`deactivate-or-delete-service-${service}`),
        },
        {
          label: 'Delete',
          action: () => {
            this.servicesToDelete.push(service);
          },
          type: 'primary',
        },
        {
          label: 'Cancel',
          action: (e) => {
            const newState = this.state;
            newState.domain.services[service] = true;
            this.setState(newState);
            api.Modals.onCloseModal(`deactivate-or-delete-service-${service}`);
          },
        },
      ],
    });
  }

  onChange(e) {
    this.getServiceToDelete(this.state.previousUsedServices, e);

    this.servicesToDelete = this.servicesToDelete.filter((service) => !e.services[service]);

    this.setState({ domain: e });
  }

  onChangeId(e) {
    this.setState({ newId: e.target.value });
  }

  onChangeProjectName(e) {
    this.setState({ projectName: e.target.value });
  }

  showDomainUsers() {
    navigateTo(`${PATHS.ACCOUNTS_USERS}/${this.state.domain.id}`);
  }

  render() {
    const { domain } = this.state;
    return (
      <div className="DomainSettingsPage content-wrapper">
        {this.new
          ? (
            <NewDomainForm
              newId={this.state.newId}
              projectName={this.state.projectName}
              onChangeId={this.onChangeId}
              onChangeProjectName={this.onChangeProjectName}
            />
          )
          : (
            <DomainEditor
              domain={domain}
              onChange={this.onChange}
              submitForm={this.submitForm}
              showDomainUsers={this.showDomainUsers}
            />
          )}
      </div>
    );
  }
}

const DOMAIN_DEF = {
  applicationName: 'String',
  applicationUrl: 'String',
  applicationLogoUrl: 'Image',
  applicationLogoSmallUrl: 'Image',
  'services': {
    '_type': 'Group',
    'spec': {
      'Email': 'Boolean',
      'Collection': 'Boolean',
      'File': { _type: 'Boolean', disabled: true },
      'Account': { _type: 'Boolean', disabled: true },
      'Audit': { _type: 'Boolean', disabled: true },
    },
  },
  'languages': { '_type': 'Tags', options: DEFAULT_AVAILABLE_LANGUAGES },
};

export { DomainSettingsPage };
