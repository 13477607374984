import React from 'react';
import { createConnector } from 'cartiv';
import { Button, Card } from 'antd';
import api from '../../services/api';
import { UserStore } from '../../stores/UserStore';
import { I18nStore } from '../../components/i18n/i18nStore';
import GroupInput from '../genericEditor/FormControls/GroupInput';
import { clone } from '../../lib/clone';
import { AuthStore } from '../../stores/AuthStore';
import { DomainsManagementStore } from '../DomainsManagement/DomainsManagementStore';
import { AccountProfilesStore } from '../account/AccountProfilesStore';
import { GroupStore } from '../account/GroupStore';
import { AccountSettingsStore } from '../account/AccountSettingsStore';
import { parse } from '../../lib/query-string';

const connect = createConnector(React);


@connect(I18nStore)
@connect(UserStore)
@connect(AuthStore)
@connect(DomainsManagementStore)
@connect(AccountProfilesStore)
@connect(GroupStore)
@connect(AccountSettingsStore)
class UserEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: {} };
    this.specificDomain = parse(this.props.location.search).domainId;
    this.activationToken = parse(this.props.location.search).activationToken;
    this.onChangeDomainId = this.onChangeDomainId.bind(this);
    this.onClickApprove = this.onClickApprove.bind(this);
  }

  componentDidMount() {
    if (this.specificDomain === 'root') {
      api.DomainsManagement.onLoadDomains();
    }
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      api.User.onLoadUser(this.props.match.params.id);
    } else {
      api.User.onUnloadUser();
    }
    // TG-710 load with domain specific token when on root. maybe split root off
    api.AccountProfiles.onLoadPaginated({pageSize: 999});
    api.Group.onLoadAllGroups();
    api.AccountSettings.onLoadAccountSettings();
  }

  componentWillUpdate(nextProps, nextState) {
    const loadedUserChanged = JSON.stringify(this.state.loadedUser) !== JSON.stringify(nextState.loadedUser);
    if (loadedUserChanged || this.state.formData._initial) {
      this.setState({
        formData: nextState.loadedUser || {},
      });
    }
  }

  onChange(formData) {
    this.setState({ formData });
  }

  save(e) {
    e.preventDefault();
    if (this.state.formData.id) {
      api.User.onUpdateUser(this.state.formData.id, this.state.formData);
    } else if (this.specificDomain !== 'root') {
      let tempData = {};
      if (this.state.formData) {
        tempData = clone(this.state.formData);
      }
      tempData.domainId = this.specificDomain;
      api.User.onCreateUser(tempData);
    } else {
      api.User.onCreateUser(this.state.formData);
    }
  }

  onChangeDomainId(event) {
    let tempData = {};
    if (this.state.formData) {
      tempData = clone(this.state.formData);
    }
    tempData.domainId = event.target.value;
    this.setState({ formData: tempData });
  }

  onClickApprove() {
    api.User.onApproveUserSignup(this.activationToken);
  }

  render() {
    const {
      groups = [], accountProfiles = [], accountSettings,
    } = this.state;

    const profileOptions = accountProfiles.map((profile) => ({
      value: profile.profileId,
      label: profile.name,
    }));

    const groupOptions = groups.map((group) => ({
      value: group.groupId,
      label: `${group.name}(${group.groupId})`,
      key: group.id,
    }));

    const accountProfilesTransformed = accountProfiles
      .map((profile) => ({
        value: profile.profileId,
        label: `${profile.name}(${profile.profileId})`,
        key: profile.id,
      }));

    const domainSpecificData = !!accountSettings && !!accountSettings.userData ? accountSettings.userData.spec : {};
    const domainUserFormSpec = {
      spec: {
        email: {
          _type: 'String',
          validate: (v) => /[^\s]*@[^\s]*\.[^\s]*/.test(v),
        },
        firstName: 'String',
        lastName: 'String',
        imageUrl: 'Image',
        profileIds: {
          _type: 'MultiSelect',
          details: 'If not set, defaults to the profileId of the current user ',
          options: accountProfilesTransformed,
        },
        groupIds: {
          _type: 'MultiSelect',
          options: groupOptions,
          value: undefined,
        },
        ...domainSpecificData,
      },
    };

    const rootUserFormSpec = {
      spec: {
        email: {
          _type: 'String',
          validate: (v) => /[^\s]*@[^\s]*\.[^\s]*/.test(v),
        },
        firstName: 'String',
        lastName: 'String',
        profileIds: {
          label: 'Profile',
          _type: 'MultiSelect',
          options: profileOptions,
          value: undefined,
        },
        groupIds: {
          label: 'Group',
          _type: 'MultiSelect',
          options: groupOptions,
          value: undefined,
        },
      },
    };

    const userFormSpec = this.props.match.path.includes('domains') ? rootUserFormSpec : domainUserFormSpec;

    return (
      <div className="UserPage content-wrapper">
        <Card title="Edit User">
          <form onSubmit={this.save.bind(this)}>
            <GroupInput
              name="user"
              entityDefinition={userFormSpec}
              fieldClasses={{
                groupClass: 'ant-row',
                labelClass: 'ant-col-6',
                inputClass: '',
                inputWrapperClass: 'ant-col-18',
              }}
              value={this.state.formData}
              onChange={this.onChange.bind(this)}
            />
            {
              this.specificDomain === 'root'
              && (
                <div className="domain-id-input">
                  <div className="ant-col-3">DomainId:</div>
                  <select
                    className="domain-select ant-col-9"
                    onChange={this.onChangeDomainId}
                    value={this.state.formData.domainId ? this.state.formData.domainId : ''}
                  >
                    {!!this.state.domains && this.state.domains.map((domain) => (
                      <option
                        key={domain.id}
                        value={domain.id}
                      >
                        {domain.id}
                      </option>
                    ))}
                  </select>
                </div>
              )
            }
            {
              !!this.activationToken
              && (
                <button type="button" className="approve btn btn-lg btn-primary" onClick={this.onClickApprove}>
                  <i
                    className="fa fa-check"
                  />
                  Approve
                </button>
              )
            }
            <div className="bottom-actions">
              <Button
                type="link"
                htmlType="button"
                size="large"
                icon="left"
                onClick={() => window.history.back()}
              >
                Back
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="save pull-right"
                icon="save"
              >
Save
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

export default UserEditor;
