import React from 'react';
import { notification, Input } from 'antd';
import * as style from './RawInput.scss';

export class RawInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { valid: true };
    this.dummy = style;
  }

  onChange(value) {
    const valid = this.validate(value);
    if (!valid) {
      notification.warn({ message: 'Cannot enter invalid JSON' });
      return;
    }
    this.setState({ value });
    this.props.onChange(JSON.parse(value), valid);
  }

  validate(value) {
    let valid = true;
    try {
      JSON.parse(value);
    } catch (e) {
      valid = false;
    }

    if (this.props.validator) {
      valid = !!value && this.props.validator(value);
    }
    return valid;
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.forceValidation && newProps.forceValidation) {
      this.validate(this.props.value);
    }
  }

  beautify(value) {
    return JSON.stringify(value, null, 4);
  }

  render() {
    const value = typeof this.props.value !== 'undefined' && this.props.value !== null
      ? this.props.value : this.props.defaultValue;
    const groupClass = `form-group ${this.props.fieldClasses.groupClass}`;
    const hasValueClass = value ? 'has-value' : 'empty';
    const focusClass = this.state.focused ? 'focused' : 'blurred';
    const formattedValue = this.beautify(value);

    return (
      <div
        className={`${this.props.name} RawInput ${groupClass} ${hasValueClass} ${focusClass}`}
      >
        <label
          className={`${this.props.fieldClasses.labelClass} form-control-label`}
          htmlFor={`${this.props.name}-form-control`}
        >
          {this.props.entityDefinition.label}
          {this.props.required ? <span className="required">*</span> : null}
        </label>
        <div className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
          <Input.TextArea
            type={this.props.typeOverride || 'text'}
            name={this.props.name}
            id={`${this.props.name}-form-control`}
            className={`${this.props.name} form-control ${this.props.fieldClasses.inputClass}`}
            placeholder={this.props.entityDefinition.placeholder}
            onChange={(e) => this.onChange(e.target.value)}
            value={formattedValue}
          />
          {this.state.valid ? null
            : (
              <small
                className="validationMessage"
              >
                {this.props.validationMessage || `Please supply a valid ${this.props.entityDefinition.label}`}
              </small>
            )}
          {this.props.entityDefinition.details
            ? <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null}
        </div>
      </div>
    );
  }
}

RawInput.defaultProps = {
  value: '',
  defaultValue: '',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  placeholder: '',
  onChange: (v) => console.log(v),
};
