import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import GroupInput from './GroupInput';
import { clone } from '../../../lib/clone';

import './ArrayInput.scss';

export class ArrayInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.addElement = this.addElement.bind(this);
    this.renderElement = this.renderElement.bind(this);
  }

  addElement() {
    const { value = [] } = this.props;
    const obj = {};
    Object.keys(this.props.entityDefinition.spec).map((key) => {
      obj[key] = '';
    });
    value.push(obj);
    this.props.onChange(value);
  }

  removeElement(index) {
    const value = clone(this.props.value);
    value.splice(index, 1);
    this.props.onChange(value);
  }

  moveElement(index, delta) {
    if (index + delta >= 0 && index + delta < this.props.value.length) {
      const entity = clone(this.props.value);
      const tmp = entity[index];
      entity[index] = entity[index + delta];
      entity[index + delta] = tmp;
      this.props.onChange(entity);
    }
  }

  onElementChange(index, value) {
    const entity = this.props.value || [];
    console.log('index: ', index);
    entity[index] = value;
    this.props.onChange(entity);
  }

  renderElement(aElement, index) {
    aElement._id = aElement._id || `${+new Date()}-${~~(Math.random() * 1000)}`;
    return (
      <div
        className={`array-element array-element-${index} container`}
        key={aElement._id}
      >
        <div className="array-element-content row">
          <div className="array-element-action">
            <Button
              htmlType="button"
              title="Move element up"
              className={`move-up-${this.props.name} move-up`}
              onClick={() => {
                this.moveElement(index, -1);
              }}
              icon="up"
              type="link"
              size="small"
            />
            <Button
              type="link"
              htmlType="button"
              title="Delete element"
              className={`remove-${this.props.name} remove`}
              onClick={() => {
                this.removeElement(index);
              }}
              icon="delete"
              size="small"
            />
            <Button
              htmlType="button"
              title="Move element down"
              className={`move-down-${this.props.name} move-down`}
              onClick={() => {
                this.moveElement(index, 1);
              }}
              icon="down"
              type="link"
              size="small"
            />
          </div>
          <GroupInput
            name={`${this.props.name}-${index}`}
            level={this.props.level + 1}
            entityDefinition={this.props.entityDefinition}
            fieldClasses={this.props.fieldClasses}
            value={aElement}
            languages={this.props.languages}
            onChange={(value) => this.onElementChange(index, value)}
          />
        </div>
      </div>
    );
  }

  render() {
    const arrayElements = (this.props.value || []).map(this.renderElement);
    const Hx = `h${this.props.level + 1}`;

    return (
      <div className={`ArrayInput ${this.props.name} ${this.props.className}`}>
        <Hx className="array-input-header">
          <span className="name">{this.props.entityDefinition.label}</span>
          {this.props.entityDefinition.details
            ? <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null}
        </Hx>
        <div className="array-input-body">
          {arrayElements}
          <div className="array-element add-element-wrapper">
            <Button
              type="secondary"
              htmlType="button"
              size="default"
              onClick={this.addElement}
              icon="plus"
            >
              Add another item
            </Button>
          </div>
        </div>
        <div className="array-input-footer" />
      </div>
    );
  }
}

ArrayInput.defaultProps = {
  name: 'array',
  level: 0,
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: {
    spec: { example: 'String' },
  },
  onChange: (...args) => console.warn('Changing', args),
};


export default styled(ArrayInput)`
  .array-element-content {
    display: flex;
    flex-direction: row;
  }
  
  .array-element-action {
    display: flex;
    flex-direction: column;
  }
`;
