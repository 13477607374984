export const quotes = [
  {id: 'aab', text: 'Nice UIs are key to product success', author: 'Ádám Balázs'},
  {id: 'aak', text: 'Security & Access Control are relevant. We provide it!', author: 'Árpád Kovács-Ábrahám'},
  {id: 'atu', text: 'Efficiency in execution is key. Let\'s build!', author: 'Alin Turbuț'},
  {id: 'asz', text: 'Infrastructure is solid. Life is good.', author: 'Áron Szabó'},
  {id: 'ata', text: 'Stabilize before rollout & do proper QA!', author: 'Attila Zsiga Szabó'},
  {id: 'bma', text: 'Javascript, javascript everywhere! ', author: 'Bogdan Maier'},
  {id: 'gyj', text: 'PPT - Precision, Proficiency and Teamwork (not PowerPoint) ', author: 'György Jaskó'},
  {id: 'kfe', text: 'UX the α and Ω of product development', author: 'Krisztina Fekete'},
  {id: 'lma', text: 'Be rude to your users - said noone ever.', author: 'Lilla Másody'},
  {id: 'man', text: 'Let\'s build something great again!', author: 'Mátyás Albert-Nagy'},
  {id: 'mwe', text: 'It\'s all about the execution. Get in touch!', author: 'Manuel Wesch'},
  {id: 'dba', text: 'Let\'s launch your business!', author: 'David Baus'},
  {id: 'nsa', text: 'Tested code is the best kind of code.', author: 'Nándor Salak'},
  {id: 'oru', text: 'Proper backend processes for a rocksolid product!', author: 'Ovidiu Roșu'},
  {id: 'tac', text: 'If you want to build connected products you need connected people.', author: 'Thomas Acuner'},
];
