import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../services/api/BackendApi';
import api from '../services/api';
import { genericErrorHandler } from '../utils/Common';
import { navigateTo } from '../services/history';
import { DEFAULT_ROW_NR } from '../components/pagination/Pagination';
import { PATHS } from '../constants';


export const DEFAULT_TEMPLATES = ['contact', 'activate', 'reset_password'];

export const EmailTemplatesStore = createStore(
  {
    api,
    name: 'Template',
  }, {
    getInitialState() {
      return {
        loading: false,
        success: false,
        templates: [],
        settings: null,
        loadedTemplate: null,
        totalElements: -1,
        lastPagination: {
          page: 0,
        },
      };
    },
    onLoadPaginatedTemplates({
      fieldName, direction, page, size = DEFAULT_ROW_NR,
    }) {
      this.setState({
        success: false,
        loading: true,
      });
      const reqParams = {
        page, size, sort: fieldName, order: direction,
      };
      backend.get('/emails/templates', {
        params: { ...reqParams },
      })
        .then((response) => {
          this.setState({
            success: true,
            loading: false,
            templates: response.content,
            totalElements: response.totalElements,
            lastPagination: reqParams,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          genericErrorHandler(error);
        });
    },
    onUpdateTemplate(templateId, data) {
      backend.patch(`/emails/templates/${templateId}`, { data })
        .then(
          (response) => {
            notification.success({ message: 'Template updated successfully' });
            this.setState({ loading: true });
            navigateTo(PATHS.EMAILS_TEMPLATES);
          },
        )
        .catch(genericErrorHandler);
    },
    onAddTemplate(data) {
      backend.post('/emails/templates', { data })
        .then(
          (response) => {
            notification.success({ message: 'Template added successfully' });
            this.setState({ loading: true });
            navigateTo(PATHS.EMAILS_TEMPLATES);
          },
        )
        .catch(genericErrorHandler);
    },
    onDeleteTemplate(templateId) {
      backend.delete(`/emails/templates/${templateId}`)
        .then(
          (response) => {
            notification.success({ message: 'Template deleted successfully' });
            const templates = this.state.templates.slice(0);

            const temp = templates.find((t) => t.id === templateId);
            const index = templates.indexOf(temp);
            templates.splice(index, 1);
            this.setState({ templates });
          },
        )
        .catch(
          (error) => {
            this.setState({ loading: false });
            genericErrorHandler(error);
          },
        );
    },
    onUnloadTemplate() {
      this.setState({ loadedTemplate: null });
    },

    onLoadSettings() {
      this.setState({ loading: true });
      backend.get('/emails/settings')
        .then((response) => {
          this.setState({
            settings: response,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          genericErrorHandler(error);
        });
    },
    onUpdateSettings(data) {
      backend.patch('/emails/settings', { data })
        .then(
          (response) => {
            notification.success({ message: 'Settings updated successfully' });
            navigateTo(PATHS.EMAILS_SETTINGS);
          },
        )
        .catch(genericErrorHandler);
    },
  },
);
