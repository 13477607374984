import { createStore } from 'cartiv';
import { notification } from 'antd';
import gravatar from 'gravatar';
import { merge } from 'lodash';
import backend from '../services/api/BackendApi';
import api from '../services/api';
import { genericErrorHandler } from '../utils/Common';
import { navigateBack } from '../services/history';
import { DEFAULT_ROW_NR } from '../components/pagination/Pagination';
import { getPageAfterRemoval } from '../utils/pagination/Utils';


export const UserStore = createStore({
  api,
  name: 'User',
}, {
  getInitialState() {
    return {
      loading: false,
      success: false,
      loadedUser: null,
      loadedAccount: null,
      users: [],
      roles: [],
      domainSpecificRoles: [],
      totalPages: 0,
      lastPagination: {
        page: 0,
      },
      totalElements: -1,
      domainAndGroup: [],
      avatarUrl: undefined,
      avatarRequestFailed: false,
    };
  },

  onUnloadUser() {
    this.setState({ loadedUser: null });
  },
  onLoadUser(userId) {
    this.setState({ loading: true });
    backend.get(`/users/${userId}`)
      .then((loadedUser) => {
        this.setState({ loadedUser, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        genericErrorHandler(error);
      });
  },

  onLoadAccount(userId, cb) {
    const callback = cb;
    if (!userId) {
      console.warn("No userId defined to load");
      return;
    }
    this.setState({ loading: true });
    
    backend.get(`/users/${userId}`)
      .then((loadedAccount) => {
        let avatarUrl;
        if (!loadedAccount.imageUrl) {
          avatarUrl = gravatar.url(loadedAccount.email);
        } else {
          avatarUrl = loadedAccount.imageUrl;
        }
        this.setState({ loadedAccount, avatarUrl });

        callback && callback(loadedAccount);
      })
      .catch(genericErrorHandler)
      .finally(() => this.setState({ loading: false }));
  },

  onLoadPaginated({
    fieldName, direction, page, size = DEFAULT_ROW_NR,
  }, query = {}) {
    const reqParams = merge(this.state.lastPagination, {
      fieldName, page, size, order: direction,
    });
    const querySettings = { page, q: query, f: {} };

    this.setState({ success: false, loading: true });

    backend.get('/users', {
      params: {
        ...reqParams,
        ...querySettings,
      },
    })
      .then((response) => {
        this.setState({
          success: true,
          users: response.content,
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          lastPagination: reqParams,
        });
      })
      .catch(genericErrorHandler)
      .finally(() => this.setState({ loading: false }));
  },

  onCreateUser(data) {
    this.setState({ loading: true });

    backend.post('/users', { data })
      .then((response) => {
        notification.success({ message: 'User has been created & notified via email' });
        navigateBack();
      })
      .catch(genericErrorHandler)
      .finally(() => this.setState({ loading: false }));
  },
  onUpdateUser(id, data) {
    this.setState({ loading: true });

    backend.put(`/users/${id}`, { data })
      .then((response) => {
        notification.success({ message: 'User updated successfully' });
        navigateBack();
      })
      .catch((error) => {
        genericErrorHandler(error);
      })
      .finally(() => this.setState({ loading: false }));
  },

  onUpdateUserName(id, data) {
    this.setState({ loading: true });

    backend.put(`/users/${id}`, { data })
      .then((response) => {
        notification.success({ message: 'User updated successfully' });
      })
      .catch(genericErrorHandler)
      .finally(() => this.setState({ loading: false }));
  },

  onDeleteUser(id) {
    const { users, lastPagination } = this.state;

    backend.delete(`/users/${id}`)
      .then((response) => {
        this.onLoadPaginated({
          ...lastPagination,
          page: getPageAfterRemoval(lastPagination.page, users.length),
        });

        notification.success({ message: 'User deleted successfully' });
      })
      .catch(genericErrorHandler);
  },

  onLoadDomainAndGroupByEmail(email, cb) {
    this.setState({ loading: true });

    backend.get(`/account/domains/${email}`)
      .then((resp) => {
        this.setState({ domainAndGroup: resp });
        cb && cb(resp, email);
      })
      .catch(genericErrorHandler)
      .finally(() => this.setState({ loading: false }));
  },

  onApproveUserSignup(activationToken) {
    backend.get(`/account/approve?activationToken=${activationToken}`)
      .then((response) => {
        notification.success({ message: 'User\'s account activated successfully' });
      })
      .catch(genericErrorHandler);
  },

  onUpdatePassword(oldPassword, newPassword) {
    const data = {
      oldPassword,
      newPassword,
    };

    if (newPassword !== oldPassword) {
      return notification.error({
        message: 'Password match error',
        description: 'The password and it\'s confirmation do not match',
      });
    }

    backend.post('/account/change-password', { data })
      .then((response) => {
        notification.success({ message: 'User\'s password was updated successfully' });
      })
      .catch(genericErrorHandler);
  },
});
