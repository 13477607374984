import React from 'react';
import { Button, Icon } from 'antd';
import styled from 'styled-components';
import { parse } from 'query-string';
import api from '../../services/api';
import { passwordCardStyling } from './PasswordResetRequestCard';
import CardWithLogoInTitle from '../PasswordSet/CardWithLogoInTitle';


const PasswordResetRequestSentCard = ({ className, location }) => {
  const onResendEmail = () => {
    const { state } = location;

    api.Login.onSendPasswordResetRequest(...state, true);
  };

  const onBackToLogin = () => {
    const { domainId } = parse(location.search);

    api.Login.onBackToLogin(domainId);
  };

  return (
    <CardWithLogoInTitle title="Password reset email sent" className={className}>
      <span>Check your email for the reset link.</span>
      <br />
      <span>
          If you haven&apos;t received one within 2 minutes
        <Button type="link" onClick={onResendEmail}>click here to resend the email.</Button>
      </span>
      <Button type="link" onClick={onBackToLogin} className="back-to-login-button">
        <Icon type="left" />
        Back to login
      </Button>
    </CardWithLogoInTitle>
  );
};


export default styled(PasswordResetRequestSentCard)`
  ${passwordCardStyling}

  .ant-card-body {
    align-items: flex-start !important;
    margin-left: ${({ theme }) => theme.space.xs};

    span {
      .ant-btn-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: auto !important;
        height: auto;
        padding: 0;
        font-weight: bold;
        
        svg {
          font-size: 10px;
        }
        
      }
    }

    .back-to-login-button {
      margin-top: ${({ theme }) => theme.space.xs};
    }
  }
`;
