import React from 'react';
import { Select } from 'antd';
import './ReferenceInput.scss';
import { generateAntOptions } from '../../../lib/utils';

export class ReferenceInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let proptions = [];
    const options = [];
    let referenceType = {};
    if (this.props.referenceEntities) {
      referenceType = this.props.referenceEntities.find((entity) => entity.referenceType === this.props.entityDefinition.referenceType);
      if (referenceType) {
        proptions = referenceType.entities;
        proptions.forEach((opt) => {
          options.push({
            value: opt._id,
            label:
              typeof opt[referenceType.displayField] === 'object' && !!opt[referenceType.displayField]._i18n
                ? opt[referenceType.displayField]._i18n.en
                : opt[referenceType.displayField],
          });
        });
      }
    }


    const value = typeof this.props.value !== 'undefined' && this.props.value !== null
      ? this.props.value : null;

    return (
      <div className={`ReferenceInput ${this.props.name} form-group ${this.props.fieldClasses.groupClass}`}>
        <label
          className={`${this.props.fieldClasses.labelClass} form-control-label`}
          htmlFor={`${this.props.name}-form-control`}
        >
          {this.props.entityDefinition.referenceType}
        </label>
        <div className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
          <Select
            name={this.props.name}
            id={`${this.props.name}-form-control`}
            className={`${this.props.name} form-control ${this.props.fieldClasses.inputClass}`}
            defaultValue={value}

            onChange={(refId) => {
              this.props.onChange(refId);
            }}
          >
            {generateAntOptions(options)}
          </Select>
          {this.props.entityDefinition.details
            ? <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null}
        </div>
      </div>
    );
  }
}

ReferenceInput.defaultProps = {
  defaultValue: '',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: { options: [] },
  referenceEntities: [],
  onChange: (v) => console.log(v),
};
