import React from 'react';
import './DomainTokensPage.scss';
import { createConnector } from 'cartiv';
import { Button, Card } from 'antd';
import api from '../../services/api';
import { GroupInput } from '../genericEditor/FormControls';
import { LayoutStore } from '../../components/Layout/LayoutStore';
import { DomainTokensPageStore } from './DomainTokensPageStore';
import { AccountProfilesStore } from '../account/AccountProfilesStore';
import { AuthStore } from '../../stores/AuthStore';
import { GroupStore } from '../account/GroupStore';

const connect = createConnector(React);

@connect(DomainTokensPageStore)
@connect(LayoutStore)
@connect(AccountProfilesStore, ['accountProfiles'])
@connect(GroupStore, ['groups'])
class DomainTokensPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
    this.onGenerateToken = this.onGenerateToken.bind(this);
  }

  componentDidMount() {
    const domainId = AuthStore.getDomainId();
    api.AccountProfiles.onLoadPaginated({pageSize: 999});
    api.Group.onLoadAllGroups();
  }

  onGenerateToken() {
    const { data } = this.state;
    if (data.profileIds && data.profileIds.length === 0) {
      data.profileIds = null;
    }
    if (data.groupIds && data.groupIds.length === 0) {
      data.groupIds = null;
    }
    api.DomainTokensPage.onGenerateToken(data);
  }

  render() {
    const {
      jwtObject, data: value, accountProfiles = [], groups = [], token,
    } = this.state;

    if (jwtObject) {
      value.profileIds = value.profileIds || jwtObject.profileIds;
      value.groupIds = value.groupIds || jwtObject.groupIds;
      value.userId = value.userId || jwtObject.userId;
      value.subject = value.subject || jwtObject.sub;
    }

    const accountProfilesTransformed = accountProfiles
      .map((profile, i) => ({
        label: `${profile.name}(${profile.profileId})`,
        value: profile.profileId,
        key: profile.id,
      }));

    const groupsTransformed = groups
      .map((group, i) => ({
        label: `${group.name}`,
        value: group.groupId,
        key: group.id,
      }));

    return (
      <div className="DomainTokensPage content-wrapper">
        <Card title="Token Generator">
          <p>
            <small>You will need the ROLE_ACCOUNT_DOMAIN_TOKEN_GENERATE in your logged in profile</small>
          </p>
          <GroupInput
            name="domain"
            entityDefinition={{
              spec: {
                profileIds: {
                  _type: 'MultiSelect',
                  details: 'If not set, defaults to the profileId of the current user ',
                  options: accountProfilesTransformed,
                },
                groupIds: {
                  _type: 'MultiSelect',
                  options: groupsTransformed,
                  details: 'If not set, defaults to the groupId of the current user ',
                },
                userId: { _type: 'String', details: 'If not set, defaults to the userId of the current user ' },
                subject: { _type: 'String', details: 'If not set, defaults to the email address of the current user ' },
                validity: { _type: 'Date', details: 'If not set, defaults now + 10min ' },
              },
            }}
            value={value}
            onChange={(data) => this.setState({ data })}
            fieldClasses={{
              groupClass: 'ant-row',
              labelClass: 'ant-col-6',
              inputClass: '',
              inputWrapperClass: 'ant-col-18',
            }}
          />
          <Button
            type="primary"
            size="large"
            onClick={this.onGenerateToken}
            icon="setting"
          >
            Generate Token
          </Button>
          <hr />
          <h2>Generated Token</h2>
          <pre>{token || 'N/A'}</pre>
          {token && (
            <div>
              <h3>Token contents</h3>
              <pre>
                {token.split('.')
                  .slice(0, 2)
                  .map(atob)
                  .join('---\n')
                  .replace(/(,|\{|\})/g, '$1\n')}
              </pre>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export { DomainTokensPage };
