/**
 * Gets previous page / only page after deleting
 * @param pageNr
 * @returns {number}
 */
export const getPageAfterRemoval = (pageNr, entriesCount) => {
  // eslint-disable-next-line no-nested-ternary
  return entriesCount > 1 // has more than 1 element on the page
    ? pageNr
    : pageNr > 1 // there is a previous page
      ? pageNr - 1
      : 0;
};
