import React from 'react';
import { createConnector } from 'cartiv';
import { Button, Card, Form } from 'antd';
import { EmailTemplatesStore } from '../../stores/EmailTemplateStore';
import { GroupInput } from '../genericEditor/FormControls';
import GenericBackAndSubmit from '../../components/GenericFooter/GenericBackAndSubmit';

const connect = createConnector(React);

@connect(EmailTemplatesStore)
class EmailSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _initial: true,
      settings: null,
      loading: false,
    };

    this.save = this.save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    EmailTemplatesStore.onLoadSettings();
  }

  onChange(value) {
    this.setState({
      formData: value,
    });
  }

  save(e) {
    e.preventDefault();
    EmailTemplatesStore.onUpdateSettings(this.state.formData);
  }

  render() {
    const FormSpec = {
      spec: {
        noReplyAddress: 'String',
        noReplyName: 'String',
        host: 'String',
        port: 'String',
        protocol: 'String',
        username: 'String',
        password: 'String',
        auth: 'Boolean',
        starttls: 'Boolean',
      },
    };

    return (
      <div className="EmailSettingsPage content-wrapper">
        <Card title="Edit Email Settings">
          <Form onSubmit={this.save}>

            {this.state.loading
              ? (
                <div>
                Loading...
                </div>
              )
              : (
                <GroupInput
                  name="template"
                  entityDefinition={FormSpec}
                  fieldClasses={{
                    groupClass: 'ant-row',
                    labelClass: 'ant-col-6',
                    inputClass: '',
                    inputWrapperClass: 'ant-col-18',
                  }}
                  value={this.state.settings}
                  onChange={this.onChange}
                />
              )}

            <GenericBackAndSubmit hideBackButton />
          </Form>
        </Card>
      </div>
    );
  }
}


export { EmailSettingsPage };
