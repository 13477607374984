import React from 'react';
import { merge } from 'lodash';
import { createConnector } from 'cartiv';
import { Button, Card, Form } from 'antd';
import styled from 'styled-components';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import { AuditStore } from './AuditStore';
import api from '../../services/api';
import { GroupInput } from '../genericEditor/FormControls';
import Table from '../../components/table/Table';


const connect = createConnector(React);

@connect(AuditStore)
class AuditList extends React.Component {
  auditColumns = [{
    title: 'User',
    dataIndex: 'principal',
    key: 'principal',
    sorter: true,
  }, {
    title: 'Event Type',
    dataIndex: 'type',
    key: 'type',
    sorter: true,
  }, {
    title: 'Data',
    dataIndex: 'data',
    key: 'data',
    sorter: true,
    render(text, record) {
      const data = !!record.data && (record.type.includes('AUTHENTICATION')
        ? record.data.details
        : `id: ${record.data.id}`);
      return (
        <span>{data}</span>
      );
    },
  }, {
    title: 'Event Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sorter: true,
  }];


  constructor(props) {
    super(props);

    this.state = {
      filter: {
        user: '',
        eventType: '',
        id: '',
        fromDate: '',
        toDate: '',
      },
    };

    this.onChange = this.onChange.bind(this);
    this.query = this.query.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
    this.getQuery = this.getQuery.bind(this);
  }

  componentDidMount() {
    this.query({});
  }

  onChange(value) {
    this.setState({ filter: value });
  }

  getQuery() {
    const { filter } = this.state;
    const query = {
      principal: { $regex: filter.user, $options: 'i' },
      eventType: { $regex: filter.eventType, $options: 'i' },
      fromDate: filter.fromDate,
      toDate: filter.toDate,
    };

    if (filter.id) {
      query['data.id'] = { $regex: filter.id, $options: 'i' };
    }

    return query;
  }

  /**
   * Params update when the Table component changes
   * @param page
   * @param pageSize
   * @param sortField
   * @param sortOrder
   */
  query({
    page = 0, pageSize = DEFAULT_ROW_NR, sortField = 'lastModifiedDate', sortOrder = 'DESC',
  }) {
    this.setState({ success: false, loading: true });

    api.Audit.onLoadPaginated({
      fieldName: sortField,
      direction: sortOrder,
      pageSize,
      page,
    }, this.getQuery());
  }

  clearQuery() {
    this.setState({
      filter: {
        user: '',
        eventType: '',
        id: '',
        fromDate: '',
        toDate: '',
      },
      lastPagination: {
        page: 0,
      },
    }, () => this.query({}));
  }

  renderFilters() {
    return (
      <div className="filters">
        <h4>Filters</h4>
        <Form onSubmit={(e) => {
          e.preventDefault();
          this.query({});
        }}
        >
          <GroupInput
            name="query"
            entityDefinition={{
              spec: {
                user: 'String',
                eventType: 'String',
                id: 'String',
                fromDate: 'Date',
                toDate: 'Date',
              },
            }}
            value={this.state.filter}
            onChange={this.onChange}
          />
          <div className="button-wrapper">
            <Button type="link" icon="close" onClick={this.clearQuery}>Clear</Button>
            <Button type="primary" htmlType="submit" icon="filter">Filter</Button>
          </div>
        </Form>
        <hr />
      </div>
    );
  }


  render() {
    const { className } = this.props;
    const { auditData, totalElements, lastPagination, loading } = this.state;

    const pagination = merge({
      total: totalElements,
      onChange: this.query,
    }, {
      pageSize: lastPagination ? lastPagination.pageSize : undefined,
      current: lastPagination ? lastPagination.page : undefined,
    });

    return (
      <div className={`AuditList content-wrapper ${className}`}>
        <Card title="Audit">
          {this.renderFilters()}
          <Table
            data={auditData}
            columns={this.auditColumns}
            onChange={this.query}
            paginator={pagination}
            loading={loading}
          />
        </Card>
      </div>
    );
  }
}

export default styled(AuditList)`
  .filters {
    form {
      display: flex;
      align-items: flex-end;
    }
    .GroupInput {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
     
      > * {
        margin: 8px 16px;
        width: 13vw;
      }
    }
    
    .button-wrapper {
      padding-top: ${({ theme }) => theme.space.sm};
      padding-right: ${({ theme }) => theme.space.sm};
      display: flex;
      align-self: flex-start;      
      
      button {
         margin-left: 16px;
      }
    }
    
    hr {
      border-top: 1px solid ${({ theme }) => theme.colors.hr};
    }
  }
  
  .ant-card-body > * {
     margin-bottom: 30px;
  }
`;
