import { createStore } from 'cartiv';
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';
import { debounce } from 'lodash';
import api from '../services/api';
import { cookies } from '../lib/cookies';
// eslint-disable-next-line import/no-cycle
import backend from '../services/api/BackendApi';
import { navigateTo } from '../services/history';
import { PATHS, ERRORS } from '../constants';
import { ApplicationPublicDataStore } from './ApplicationPublicDataStore';


const loadToken = function () { // TODO bogdan: see what must be stored, how - me have cycles in the app.
  let token;
  if (typeof localStorage !== 'undefined') {
    token = localStorage.getItem('token');
  }
  if (!token) {
    token = cookies.getItem('token');
  }
  return token;
};
const buildStateObject = function (token) {
  let validToken = null;
  let validJwtObject = {};
  let stateObject = {
    token: validToken,
    jwtObject: validJwtObject,
  };

  try {
    if (token) {
      const jwtObject = jwt_decode(token);
      const expirationDate = new Date(jwtObject.exp * 1000);
      if (+expirationDate > +new Date()) {
        validToken = token;
        validJwtObject = jwtObject;
      } else {
        setTimeout(() => {
          const isTopWindow = window === window.top;

          if (isTopWindow) {
            notification.warn({ message: 'Token expired' });
          }
        });
      }
    }
  } catch (e) {
    console.info('Could not restore token', e);
  } finally {
    stateObject = {
      token: validToken,
      jwtObject: validJwtObject,
      loading: false,
    };
  }
  return stateObject;
};

export const AuthStore = createStore({
  api,
  name: 'Auth',
}, {
  getInitialState() {
    const token = loadToken();
    const initialState = buildStateObject(token);
    return initialState;
  },
  onLogout() {
    this.onReset();
  },
  onReset() {
    this.setState({
      token: null,
      jwtObject: null,
    });
    this.persistToken(null);
  },
  onGetStateObject(token) {
    return buildStateObject(token);
  },
  initFromToken(token) {
    const stateObject = buildStateObject(token);
    this.persistToken(stateObject.token);
    this.setState(stateObject);
  },
  persistToken(token) {
    if (global.isServer) {
      return;
    }
    if (!token) {
      localStorage.removeItem('token');
      cookies.removeItem('token');
    } else {
      localStorage.setItem('token', token);
      cookies.setItem('token', token);
    }
  },
  getToken() {
    return this.state.token;
  },
  getEmail() {
    return this.state.jwtObject.sub;
  },
  getDomainId() {
    return this.state.jwtObject.domainId;
  },
  getRoles() {
    return (this.state.jwtObject.auth || '').split(',');
  },
  onRefreshToken() {
    backend.post('/account/refresh-token').then((resp) => {
      const { token } = resp;
      this.initFromToken(token);
    }).catch((errorResponse) => {
      notification.warn({ message: 'Could not refresh token' });
    });
  },
  onActivateAccount(password, activationToken, domainId) {
    this.setState({ loading: true });
    const data = { password, activationToken };
    const headers = {
      'jrc-domain': domainId,
      /* FIXME needs to be set, so active JAP logins on the same domain don't interfere  */
      Authorization: null,
    };

    return backend.post('/account/activate', { data, headers }).then(({ token }) => {
      navigateTo(PATHS.PASSWORD_SET_SUCCESS);
      debounce(() => {
        const callbackUrl = ApplicationPublicDataStore.getCallbackUrlFor('activate-account', 'success', { token });
        window.location = callbackUrl;
      }, 2000)();
    }).catch((errorResponse) => {
      const status = errorResponse?.data?.status;

      if (status === 404) {
        notification.error({ message: ERRORS.ACCOUNT_ALREADY_ACTIVATED });
      } else {
        navigateTo(PATHS.PASSWORD_SET_ERROR);
      }
    })
      .finally(() => this.setState({ loading: false }));
  },

});
