import React from 'react';
import styled from 'styled-components';
import { createConnector } from 'cartiv';
import { Menu } from 'antd';
import api from '../../services/api';
import { I18nStore } from '../i18n/i18nStore';
import { DomainSelectorStore } from '../DomainSelector/DomainSelectorStore';
import { AuthStore } from '../../stores/AuthStore';
import { DomainSettingsPageStore } from '../../pages/DomainSettingsPage/DomainSettingsPageStore';
import { EmailMenuItem } from '../../pages/email/EmailMenuItem';
import MenuItem from './MenuItem';
import { AccountMenuItem } from '../../pages/account/AccountMenuItem';
import { AuditMenuItem } from '../../pages/audit/AuditMenuItem';
import { CollectionMenuItem } from '../../pages/collection/CollectionMenuItem';
import { BuilderMenuItem } from '../../pages/builder/BuilderMenuItem';
import { FileMenuItem } from '../../pages/file/FileMenuItem';
import { PATHS } from '../../constants';

const connect = createConnector(React);

const MENU_ITEMS = {
  email: EmailMenuItem,
  account: AccountMenuItem,
  audit: AuditMenuItem,
  collection: CollectionMenuItem,
  builder: BuilderMenuItem,
};
// file: FileMenuItem,

@connect(I18nStore)
@connect(DomainSelectorStore)
@connect(AuthStore)
@connect(DomainSettingsPageStore)
class SideBar extends React.Component {
  componentDidMount() {
    api.DomainSelector.onGetDomainInformation();
  }

  renderDomains() {
    const { jwtObject } = this.state;
    if (!!jwtObject && jwtObject.domainId !== 'root') {
      return (
        <MenuItem
          className="domain"
          title="Application settings"
          icon="cloud"
          subMenu={[
            {
              'title': 'General',
              'icon': 'setting',
              'link': '/domain',
            },
            {
              'title': 'Tokens',
              'icon': 'tag',
              'link': '/domain/tokens',
            }]}
        />
      );
    }

    return (
      <MenuItem
        className="domains-management"
        link={PATHS.DOMAINS_MANAGE}
        icon="fa-list"
        title="Manage domains"
      />
    );
  }


  renderAvailableServices(showSideBar) {
    const { jwtObject, domainInfo } = this.state;
    if (domainInfo) {
      const menuItems = domainInfo.usedServices.map((serviceEnum) => {
        const serviceName = serviceEnum.split('_')[0].toLowerCase();
        const MenuItemType = MENU_ITEMS[serviceName];
        if (MenuItemType) {
          return (<MenuItemType key={serviceName} />);
        }
      })
        .filter((e) => !!e);
      if (!!jwtObject && ['watersports.one', 'base-coworking.com'].indexOf(jwtObject.domainId) !== -1) {
        // TODO remove the webcomponents element when watersports / base dynamic elements have been reworked
        menuItems.unshift(
          <MenuItem
            key="webcomponents"
            className="components"
            link="/components"
            icon="file-word"
            title="WebComponents"
          />,
        );
      }
      return menuItems;
    }
  }

  render() {
    const { className, showSideBar } = this.props;

    api.DomainSettingsPage.onSetManagedDomainId('current');

    return (
      <div className={className}>
        <Menu mode="inline">

          {this.renderAvailableServices(showSideBar)}

          {this.renderDomains()}
        </Menu>
      </div>
    );
  }
}

export default styled(SideBar)`
  
`;
