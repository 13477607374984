import { PASSWORD_MIN_LENGTH } from '../../constants';

export const hasLowerCase = (str) => (/[a-z]/.test(str));

export const hasUpperCase = (str) => (/[A-Z]/.test(str));

export const hasNumber = (str) => (/[0-9]/.test(str));

export const customPasswordValidator = (rule, value, callback) => {
  const errorMessages = [];

  if (!value) {
    callback('Please enter your new password');
  }
  if (value.length < PASSWORD_MIN_LENGTH) {
    errorMessages.push(`${PASSWORD_MIN_LENGTH} characters`);
  }
  if (!hasLowerCase(value)) {
    errorMessages.push('1 lowercase character');
  }
  if (!hasUpperCase(value)) {
    errorMessages.push('1 uppercase character');
  }
  if (!hasNumber(value)) {
    errorMessages.push('1 number');
  }

  if (errorMessages.length > 0) {
    callback(`The password must have at least ${errorMessages.join(', ')}`);
  } else {
    callback();
  }
};
