import React from 'react';
import './RichTextEditor.scss';
import backend from '../../services/api/BackendApi';

let ReactQuill = 'textarea';

export class RichTextEditor extends React.Component {
  constructor(props) {
    ReactQuill = require('react-quill');

    super(props);
    this.state = {
      value: this.props.value ? this.props.value : '',
      uploading: false,
    };


    this.modules = {
      toolbar: [
        [{'header': [1, 2, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['video'],
        ['clean'],
        [{'color': ['#000000'].concat(this.props.brandColors)}],
      ],
    };

    this.formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image',
      'video',
      'color',
    ];
  }

  componentWillUpdate(nextProps, nextState) {
    if (JSON.stringify(this.props.brandColors) !== JSON.stringify(nextProps.brandColors)) {
      this.modules = {
        toolbar: [
          [{'header': [1, 2, false]}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['video'],
          ['clean'],
          [{'color': ['#000000'].concat(nextProps.brandColors)}],
        ],
      };
    }
    if (JSON.stringify(this.props.value) !== JSON.stringify(nextProps.value)) {
      // if the initial props of the editor change, reload state.

      if (this.state.value === nextProps.value) {
        // don't set state if values are the same because it might produce flickering
        return;
      }
      this.setState({
        value: nextProps.value,
      });
    }
  }

  handleChange(val) {
    let base64 = this.containsBase64(val);
    if (base64 === null) {
      this.setState({value: val});
      this.props.onChange(val);
    } else {
      this.setState({uploading: true});
      this.replaceBase64(val, base64[0]).then(res => {
        this.setState({
          uploading: false,
          value: res,
        });
        this.props.onChange(res);
      }).catch(() => this.setState({
        uploading: false,
        value: val,
      }));
    }
  }

  containsBase64(val) {
    let regexp = new RegExp('"data:image\/([a-zA-Z]*);base64,([^"]*)"');
    return val.match(regexp);
  };


  replaceBase64(val, base64) {
    base64 = base64.substring(1, base64.length - 1);
    return new Promise((resolve, reject) => {
      fetch(base64)
        .then(res => {
          return res.blob();
        })
        .then(blob => {
          backend.postFile([blob])
            .then((resp) => {
              let result = val.replace(base64, resp.filePaths[0]);
              resolve(result);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  render() {
    if (global.isServer) {
      return null;
    }
    return (
      <div className="RichTextEditor">
        {!this.state.uploading ? <ReactQuill
          theme="snow"
          formats={this.formats}
          modules={this.modules}
          value={this.state.value}
          className={this.props.className}
          onChange={this.handleChange.bind(this)}
        /> : <i className="fa fa-spinner fa-spin fa-3x"/>}
      </div>
    );
  }
}

RichTextEditor.defaultProps = {
  value: '',
  onChange: (value) => console.log(`RTE changed to ${value}. Nobody cares.`),
};
