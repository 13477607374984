import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';
import { navigateTo } from '../../app/AppRoutes';

export const ComponentPageStore = createStore({
  api,
  name: 'ComponentPage',
}, {
  getInitialState() {
    return {
      loading: false,
      component: [],
      subComponents: [],
    };
  },
  onLoadComponent(id) {
    this.setState({
      loading: true,
    });
    backend.get(`/collection/Component/${id}`).then((result) => {
      this.setState({
        loading: false,
        component: result,
      });
    }).catch(genericErrorHandler);

    backend.get('/collection/Component', {
      data: {
        q: {
          parentId: id,
        },
      },
    },
      (result) => {
        this.setState({
          loading: false,
          component: result.content,
        });
      })
      .catch(genericErrorHandler);
  },
  onUpdateData(id, d) {
    const data = { _entityData: d };
    // TODO Remove the whole component management part because:
    // once upon a time, when we've written this part, God and us knew what is going on.
    // Now only God knows.
    backend.put(`/collection/Component/${id}`, { data })
      .then(() => {
        navigateTo('/Components');
        notification.success({ message: 'Component saved' });
      })
      .catch(genericErrorHandler);
  },
});
