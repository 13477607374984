import React from 'react';
import { createConnector } from 'cartiv';
import {
  Button, Form, Input, Icon,
} from 'antd';
import styled, { css } from 'styled-components';
import { parse } from 'query-string';
import api from '../../services/api';
import { UserStore } from '../../stores/UserStore';
import { LoginStore } from '../Login/LoginStore';
import CardWithLogoInTitle from '../PasswordSet/CardWithLogoInTitle';


export const passwordCardStyling = css`
  .ant-card-head-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      display: inline-block;
    }

    .ant-btn {
      width: 100%;

      .button-icon-right {
        position: absolute;
        right: ${({ theme }) => theme.space.xxs};
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .ant-form {
      width: 100%;

      .ant-form-item-children {
        width: 100%;
      }
    }
  }
`;


const connect = createConnector(React);


const passwordResetRequestForm = (onSubmit, getFieldDecorator, loading) => (
  <Form
    name="basic"
    onSubmit={onSubmit}
  >

    <Form.Item
      name="email"
    >
      {getFieldDecorator('email', {
        rules: [
          {
            type: 'email',
            message: 'Please input a valid email!',
          },
          {
            required: true,
            message: 'Please input your email!',
          },
        ],
        validateTrigger: 'onSubmit',
      })(<Input placeholder="Email" />)}
    </Form.Item>


    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Send password reset link
        <Icon type="mail" className="button-icon-right" />
      </Button>
    </Form.Item>
  </Form>
);


@connect(UserStore)
@connect(LoginStore)
class PasswordResetRequestCard extends React.Component {
  constructor(props) {
    super(props);

    const { domainId } = parse(this.props.location.search);
    api.ApplicationPublicDataStore.onLoadData(domainId);
    this.state = { domainId };

    this.onSubmit = this.onSubmit.bind(this);
    this.onBackToLogin = this.onBackToLogin.bind(this);
    this.handleDomainAndGroup = this.handleDomainAndGroup.bind(this);
  }

  onBackToLogin = () => {
    const { domainId } = this.state;

    api.Login.onBackToLogin(domainId);
  }

  handleDomainAndGroup = (domainAndGroup, email) => {
    if (domainAndGroup.length === 1) {
      const { domainId } = domainAndGroup[0];
      api.ApplicationPublicDataStore.onLoadData(domainId);
      api.Login.onSendPasswordResetRequest(email, domainId);
    } else {
      this.props.toggleShowDomainSelector(domainAndGroup, email);
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { validateFields } = this.props.form;
    validateFields()
      .then(({ email }) => {
        const { domainId } = this.state;
        if (domainId) {
          api.Login.onLoadGroupByEmailAndDomainThenSendRequest(email, domainId);
        } else {
          UserStore.onLoadDomainAndGroupByEmail(email, this.handleDomainAndGroup);
        }
      })
      .catch((err) => {});
  }

  render() {
    const { className, form: { getFieldDecorator } } = this.props;
    const { loading } = this.state;

    return (
      <CardWithLogoInTitle title="Forgot password" className={className}>
        {passwordResetRequestForm(this.onSubmit, getFieldDecorator, loading)}
        <Button type="link" onClick={this.onBackToLogin}>
          <Icon type="left" />
          Back to login
        </Button>
      </CardWithLogoInTitle>
    );
  }
}

const PasswordResetRequestForm = Form.create()(PasswordResetRequestCard);
export default styled(PasswordResetRequestForm)`
  ${passwordCardStyling}
`;
