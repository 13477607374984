import React from 'react';
import { Layout } from 'antd';
import { createConnector } from 'cartiv';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import AppHeader from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import AppFooter from '../Footer/Footer';
import { LayoutStore } from './LayoutStore';
import { AuthStore } from '../../stores/AuthStore';
import { DomainSelectorStore } from '../DomainSelector/DomainSelectorStore';


const connect = createConnector(React);

@connect(LayoutStore)
@connect(AuthStore)
@connect(DomainSelectorStore)
class AppLayout extends React.Component { // TODO BOGDAN: REFAC FC
  constructor(props) {
    super(props);
  }

  render() {
    const {
      Sider, Header, Content, Footer,
    } = Layout;
    const { children, className, theme } = this.props;
    const { showSideBar } = this.state;

    return (
      <Layout className={className}>
        <Header>
          <AppHeader />
        </Header>

        <Layout>
          <Sider width={theme.layout.sidebar.width}>
            <SideBar showSideBar />
          </Sider>

          <Content>
            <main className={showSideBar ? 'main-panel' : 'full-panel'}>
              {children}
            </main>
          </Content>
        </Layout>

        <Footer>
          <AppFooter />
        </Footer>
      </Layout>
    );
  }
}

export default styled(withTheme(AppLayout))`
  aside, main {
    height: calc(100vh - ${({ theme }) => `${theme.layout.footer.height + theme.layout.header.height}px`});
    min-height: calc(100vh - ${({ theme }) => `${theme.layout.footer.height + theme.layout.header.height}px`});
  }
  .main-panel {
    padding: 1rem 1rem 0 1rem;
  }
  
  .ant-layout-header{
      padding: 0 ${({ theme }) => theme.space.xs};
  }
  .ant-layout-footer{
      padding: 16px ${({ theme }) => theme.space.xs};
  }
  
  .ant-layout-sider-children {
    overflow-y: auto;
  }
`;
