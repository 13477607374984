// HTTP codes
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;

// Validation
export const PASSWORD_MIN_LENGTH = 8;


// Formatting
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm';


// roles
export const JAP_ADMIN_UI = 'JAP_ADMIN_UI';


// lanugages
export const DEFAULT_AVAILABLE_LANGUAGES = [{ label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' }, { label: 'Español', value: 'es' }];


// paths
export const PATHS = {
  ACCOUNT: '/account',
  ACCOUNT_AUTHENTICATE: '/account/authenticate',
  ACCOUNTS_USERS: '/accounts/users',
  ACCOUNTS_SETTINGS: '/accounts/settings',
  ACCOUNTS_PROFILES: '/accounts/profiles',
  ACCOUNTS_PROFILES_EDIT: '/accounts/profiles/edit',
  ACCOUNTS_PROFILES_NEW: '/accounts/profiles/new',
  ACCOUNTS_GROUPS: '/accounts/groups',
  ACCOUNTS_GROUPS_EDIT: '/accounts/groups/edit',
  ACCOUNTS_GROUPS_NEW: '/accounts/groups/new',
  AUDIT: '/audit',
  COLLECTION_TYPES: '/collection/types',
  COLLECTION_EDIT: '/collection/edit',
  DOMAINS_USERS_NEW: '/domains/users/new',
  DOMAINS_MANAGE: '/domains/manage',
  DOMAINS_NEW: '/domains/new',
  EMAILS_TEMPLATES: '/emails/templates',
  EMAILS_TEMPLATE: '/emails/template',
  EMAILS_SETTINGS: '/emails/settings',
  FILE_SETTINGS: '/file/settings',
  LANDING: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  DOMAIN: '/domain',
  DOMAIN_TOKENS: '/domain/tokens',
  COMPONENTS: '/components',
  PASSWORD_SET: '/password-set',
  PASSWORD_SET_SUCCESS: '/password-set/success',
  PASSWORD_SET_ERROR: '/password-set/error',
  PASSWORD_CHANGE: '/password-change',
  PASSWORD_CHANGE_SUCCESS: '/password-change/success',
  PASSWORD_CHANGE_ERROR: '/password-change/error',
  PASSWORD_RESET_SUCCESS: '/password-reset/success',
  PASSWORD_RESET_ERROR: '/password-reset/error',
  PASSWORD_RESET_REQUEST: '/password-reset/request',
  PASSWORD_RESET_REQUEST_SENT: '/password-reset/request/sent',
  PASSWORD_RESET_PASSWORD_CHANGED: '/password-reset/password-changed',
  PASSWORD_RESET_CHANGE_PASSWORD: '/password-reset/change-password',
  USERS: '/users',
  EMBED_LOGIN: '/embed/login',
  EMBED_REGISTER: '/embed/register',
  EMBED_REQUEST_PASSWORD_RESET: '/embed/request-password-reset',
  EMBED_CHANGE_PASSWORD: '/embed/change-password',
  EMBED_REQUEST_SENT: '/embed/request/sent',
};

// error messages
export const ERRORS = {
  ACCOUNT_ALREADY_ACTIVATED: 'Your account was already activated',
  ACCOUNT_ACTIVATION_FAILED: 'Could not activate your account',
};
