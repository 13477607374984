import { createStore } from 'cartiv';
import { extend } from '../../lib/extend';
import api from '../../services/api';
import backend from '../../services/api/BackendApi';
import { buildRequestOptions } from '../../utils/api/Utils';

export const RegistrationStore = createStore({
  api,
  name: 'Registration',
}, {
  getInitialState() {
    return {
      registrationError: null,
      lastFetched: -1,
      facebookAppId: '',
      googleAppId: '',
      facebookSignUpEnabled: false,
      googleSignUpEnabled: false,
      successfulResetUrl: '',
      signupAllowed: false,
    };
  },

  onRegister(user) {
    const fetchOptions = buildRequestOptions()
      .withDomainId(user.domainId)
      .body(user)
      .build();

    backend.post('/account/signup', fetchOptions)
      .then((response) => {
        window.parent.postMessage({
          message: 'Registered new user',
          response,
        }, '*');
      })
      .catch((error) => {
        window.parent.postMessage({
          message: 'Registered new user',
        }, '*');
      });
  },

  onLoadAccountSettingsByDomainId(domainId) { // TODO move to appropriate store
    const fetchOptions = buildRequestOptions()
      .withDomainId(domainId)
      .build();

    backend.get(`/account/settings/${domainId}`, fetchOptions)
      .then((response) => {
        this.setState({
          facebookAppId: response.facebookAppId,
          googleAppId: response.googleAppId,
          facebookSignUpEnabled: response.facebookSignUpEnabled,
          googleSignUpEnabled: response.googleSignUpEnabled,
          successfulResetUrl: response.successfulResetUrl,
          signupAllowed: response.signupAllowed,
        });
      })
      .catch((e) => {
        console.log('Could not load domain settings', e);
        window.parent.postMessage({
          status: 500,
          message: 'Could not load domain\'s account settings',
        }, '*');
      });
  },
});
