import React from 'react';
import {
  Button, Card, Divider, Modal,
} from 'antd';
import { createConnector } from 'cartiv';
import { merge } from 'lodash';
import api from '../../services/api';
import { AuthStore } from '../../stores/AuthStore';
import { AccountProfilesStore } from './AccountProfilesStore';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import Table from '../../components/table/Table';
import { navigateTo } from '../../services/history';
import RowButton from '../../components/table/action/RowButton';
import TwoRowsCell from '../../components/table/cell/TwoRowsCell';
import { PATHS } from '../../constants';

const connect = createConnector(React);


@connect(AccountProfilesStore)
@connect(AuthStore)
class AccountProfilesList extends React.Component {
  profileColumns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (text, { name, profileId }, idx) => <TwoRowsCell upperText={name} lowerText={profileId} />,
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    sorter: true,
  }, {
    title: 'Public',
    dataIndex: 'allowsSignup',
    key: 'allowsSignup',
    render: (text, { allowsSignup }, idx) => <span>{allowsSignup ? 'Yes' : 'No' }</span>,
  }, {
    title: 'Actions',
    key: 'actions',
    render: function (text, record) {
      return (
        <span>
          <RowButton
            icon="edit"
            onClick={() => this.goToProfile(record)}
          />
          <Divider type="vertical" />
          <RowButton
            icon="delete"
            onClick={() => {
              this.confirmDelete(record);
            }}
          />
        </span>
      );
    }.bind(this),
  }];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.query({});
  }

  confirmDelete = ({ profileId, name }) => {
    Modal.confirm({
      title: 'Confirmation required',
      content: `Are you sure you want to delete '${name}'`,
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => api.AccountProfiles.onDeleteProfile(profileId),
    });
  };

  query({
    page = 0, pageSize = DEFAULT_ROW_NR, sortField = 'lastModifiedDate', sortOrder = 'DESC',
  }) {
    api.AccountProfiles.onLoadPaginated({
      fieldName: sortField,
      direction: sortOrder,
      pageSize,
      page,
    });
  }

  goToProfile = ({ profileId }) => {
    navigateTo(`${PATHS.ACCOUNTS_PROFILES_EDIT}/${profileId}`);
  };

  render() {
    const {
      accountProfiles: profilesData, totalElements, lastPagination, accountProfilesLoading,
    } = this.state;

    const pagination = merge({
      total: totalElements,
      onChange: this.query,
    }, {
      pageSize: lastPagination ? lastPagination.pageSize : undefined,
      current: lastPagination ? lastPagination.page : undefined,
    });

    return (
      <Card
        title="Profiles"
        extra={(
          <Button onClick={() => navigateTo(PATHS.ACCOUNTS_PROFILES_NEW)} type="primary" size="large" icon="plus">
            New profile
          </Button>
        )}
      >
        <Table
          data={profilesData}
          columns={this.profileColumns}
          onChange={this.query}
          paginator={pagination}
          loading={accountProfilesLoading}
        />
      </Card>
    );
  }
}

export default AccountProfilesList;
