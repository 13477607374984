import React from 'react';
import menu from './menu.json';
import MenuItem from '../../components/SideBar/MenuItem';

export class AccountMenuItem extends React.Component {
  render() {
    return (
      <MenuItem
        className={menu.className}
        icon={menu.icon}
        link={menu.link}
        title={menu.title}
        subMenu={menu.children}
        key={menu.title}
        {...this.props}
      />
    );
  }
}
