import { createStore } from 'cartiv';
import api from '../services/api';
import { genericErrorHandler } from '../utils/Common';
import backend from '../services/api/BackendApi';

const LanguageStore = createStore({
  api,
  name: 'GenericEditor',
}, {
  getInitialState() {
    return {
      languages: [],
    };
  },
  onLoadLanguages() {
    backend.get('/domains/current').then(result => {
      this.setState({
        languages: result.languages.languages,
      });
    }).catch(genericErrorHandler);
  },
});

export default LanguageStore;
