import React from 'react';
import { createConnector } from 'cartiv';
import { Button, Card, Icon, Modal } from 'antd';
import api from '../../services/api';
import { I18nStore } from '../../components/i18n/i18nStore';
import { style } from './GenericEditor.scss';
import { GenericEntityStore } from '../../stores/GenericEntityStore';
import GroupInput from './FormControls/GroupInput';
import { clone } from '../../lib/clone';
import LanguageStore from '../../stores/LanguageStore';
import { DomainSettingsPageStore } from '../DomainSettingsPage/DomainSettingsPageStore';
import { CollectionTypesStore } from '../collection/CollectionTypesStore';
import { GroupStore } from '../account/GroupStore';
import { SelectInput, MultiSelectInput } from './FormControls';
import GenericBackAndSubmit from '../../components/GenericFooter/GenericBackAndSubmit';
import { navigateBack } from '../../services/history';

const connect = createConnector(React);

@connect(I18nStore)
@connect(CollectionTypesStore)
@connect(GenericEntityStore)
@connect(LanguageStore)
@connect(DomainSettingsPageStore)
@connect(GroupStore)
class GenericEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      groupIds: [],
      languages: LanguageStore.state.languages,
      domain: DomainSettingsPageStore.state.domain,
    };
    this.dummy = style;
  }

  componentDidMount() {
    api.GenericEditor.onLoadLanguages();
    api.DomainSettingsPage.onLoadDomainSettingsPage();
    api.CollectionTypes.onLoadCollectionType(this.props.match.params._type);
    api.Group.onLoadAllGroups();
    if (this.props.match.params.id !== 'new' && this.props.match.params.id !== 'undefined') {
      api.GenericEntity.onLoadEntityById(this.props.match.params._type, this.props.match.params.id);
    } else {
      api.GenericEntity.onUnloadEntity();
    }
    api.GenericEntity.onLoadAllReferenceEntities(this.props.match.params._type);
  }

  componentWillUpdate(nextProps, nextState) {
    const loadedEntityChanged = (nextState.loadedEntity && this.state.loadedEntity
      && nextState.loadedEntity._loadedDate !== this.state.loadedEntity._loadedDate)
      || (!!nextState.loadedEntity ^ !!this.state.loadedEntity);

    if ((loadedEntityChanged)) {
      const formData = clone(nextState.loadedEntity) || {};
      const groupIds = formData.owner?.groupIds;
      this.setState({
        formData,
        groupIds,
      });
    }
  }

  onChange(newValue) {
    this.setState({ formData: newValue, edited: true });
  }

  onSubmit() {
    const collectionType = this.state.collectionType || { spec: [] };

    const dataToSave = Object.keys(collectionType.spec).reduce((data, key) => {
      data[key] = this.state.formData[key];
      return data;
    }, {});

    dataToSave.owner = { groupIds: this.state.groupIds };

    const existingEntity = this.state.loadedEntity;
    if (!existingEntity) {
      api.GenericEntity.onCreateEntity(this.props.match.params._type, dataToSave);
    } else {
      api.GenericEntity.onUpdateEntity(
        this.props.match.params._type,
        existingEntity._id,
        dataToSave,
      );
      if (this.state.groupIds
        && (
          this.state.groupIds.length !== existingEntity.owner.groupIds.length
          || this.state.groupIds.any(groupId => existingEntity.owner.groupIds && existingEntity.owner.groupIds.indexOf(groupId) !== -1)
        )) {
        api.GenericEntity.onChown(this.props.match.params._type, existingEntity._id, {
          groupIds: this.state.groupIds,
        });
      }
    }
  }

  render() {
    const collectionType = this.state.collectionType || { spec: [] };

    const groupOptions = (this.state.groups || []).map((group) => ({
      value: group.groupId,
      label: group.name,
    }));
    const groupDef = {
      label: 'Group',
      options: groupOptions,
    };

    const brandColors = !!this.state.domain.basic && !!this.state.domain.basic.brandColors
      ? this.state.domain.basic.brandColors.map((brandColor) => brandColor.color)
      : [];
    const collectionTypeId = this.props.match.params._type;
    return (
      <div className="content-wrapper">
        <Card className="GenericEditor"
          title={`Edit ${collectionTypeId}`}
          extra={
            this.state.loadedEntity ? <Button type="default" icon="delete" onClick={(e) => {
              Modal.confirm({
                title: 'Confirmation required',
                content: 'Are you sure you want to delete entry?',
                okText: 'Delete',
                cancelText: 'No',
                onOk: () => {
                  api.GenericEntity.onDelete(collectionTypeId, this.state?.loadedEntity?._id);
                  navigateBack();
                },
              });
            }
            } /> : null
          }
        >
          <form
            className={`${collectionTypeId} edit-form `}
            onSubmit={(e) => {
              e.preventDefault();
              this.onSubmit();
            }}
          >

            <div className="editor-body">
              {
                this.state.loading ? 'Loading...'
                  : (
                    <>
                      <MultiSelectInput
                        entityDefinition={groupDef}
                        name="generic-editor-select"
                        value={this.state.groupIds}
                        onChange={(value) => this.setState({ groupIds: value })}
                        fieldClasses={{
                          groupClass: 'ant-row',
                          labelClass: 'ant-col-6',
                          inputClass: '',
                          inputWrapperClass: 'ant-col-18',
                        }}
                      />
                      <GroupInput
                        name={collectionType.type}
                        entityDefinition={collectionType}
                        value={this.state.formData}
                        onChange={this.onChange.bind(this)}
                        languages={this.state.languages}
                        brandColors={brandColors}
                        referenceEntities={this.state.referenceEntities}
                        fieldClasses={{
                          groupClass: 'ant-row',
                          labelClass: 'ant-col-6',
                          inputClass: '',
                          inputWrapperClass: 'ant-col-18',
                        }}
                      />
                    </>
                  )
              }
            </div>
            <GenericBackAndSubmit />
          </form>
        </Card>
      </div>
    );
  }
}

export { GenericEditor };
