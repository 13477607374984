// local backend
const configObject = {
  // local
  'backendUrl': 'http://localhost:8080/api',
  'backendFileUrl': 'https://blob.develop.justrocket.cloud',
  'adminUrl': 'http://localhost:8082',

  // develop backend
  //  'backendUrl': 'https://jap-api.develop.justrocket.cloud/api',
  //  'backendFileUrl': 'https://blob.develop.justrocket.cloud',
  //  'adminUrl': 'http://localhost:8082',

  // staging backend
//   'backendUrl': 'https://jap-api.staging.justrocket.cloud/api',
//    'backendFileUrl': 'https://blob.staging.justrocket.cloud',
//    'adminUrl': 'http://localhost:8082',
};

export default configObject;
