import { createStore } from 'cartiv';
import api from '../services/api';
import { config } from '../config';

// const initMobileAnalytics = function (analyticsId) { // TODO delete?
//   (function (i, s, o, g, r, a, m) {
//     i['GoogleAnalyticsObject'] = r;
//     i[r] = i[r] || function () {
//       (i[r].q = i[r].q || []).push(arguments)
//     };
//     i[r].l = 1 * new Date();
//     a = s.createElement(o);
//     m = s.getElementsByTagName(o)[0];
//     a.async = 1;
//     a.src = g;
//     m.parentNode.insertBefore(a, m)
//   })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
//
//   window.ga('create', analyticsId, {'storage': 'none', 'clientId': window.device.uuid});
//   window.ga('set', 'checkProtocolTask', null);
//   window.ga('set', 'anonymizeIp', true);
//   window.ga('set', 'forceSSL', true);
// };

const initWebAnalytics = function (analyticsId) {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    };
    i[r].l = 1 * new Date();
    a = s.createElement(o);
    m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

  window.ga('create', analyticsId, 'auto');
};


export const AnalyticsStore = createStore({
  api: api,
  name: 'Analytics',
}, {
  getInitialState() {
    return {};
  },
  init() {
    if (!global.isServer && config.googleAnalyticsID) {
      initWebAnalytics(config.googleAnalyticsID);
    }
  },
  onPageView(path) {
    this.onEvent('pageview', path);
  },
  onEvent(type, data) {
    const appType = typeof cordova === 'undefined' ? 'webapp' : 'mobileapp';
    if (typeof window.ga !== 'undefined') {
      if (type === 'pageview') {
        // report the pageview as standard pageview
        window.ga('send', 'pageview', {'title': appType + ': Page ' + data, 'page': data});
        data = {action: 'open ' + data};
      }
      // report event
      window.ga('send', 'event', {
        'eventCategory': type,
        'eventAction': data.action || 'action',
        'eventLabel': data.label || 'label',
        'eventValue': data.value || 0,
      });
    }
  },
});
