import { set, unset } from 'lodash';


/**
 * Method used for track error in console
 * @param error
 */
export const handleError = (error) => {
  if (error.response) {
    const response = error.response.clone();

    response.json()
      .then((responseData) => {
        const { headers, status } = response;
        console.log('Failed with status: %o | Headers: %o  - Data: %o', status, headers, responseData);
      })
      .catch((err) => console.error(err));
  } else {
    console.log(error.message);
  }

  throw error;
};

export const buildRequestOptions = function () {
  const fetchOptions = {
    headers: undefined,
    data: undefined,
  };

  return {
    withHeader(name, value) {
      set(fetchOptions, `headers.${name}`, value);
      return this;
    },
    withDomainId(domainId) {
      if (domainId && domainId.length) {
        set(fetchOptions, 'headers.jrc-domain', domainId);
      }
      return this;
    },
    noToken() {
      unset(fetchOptions, 'headers.Authorization');
      return this;
    },
    body(body) {
      fetchOptions.data = body;
      return this;
    },
    withQuery(query) {
      fetchOptions.query = query;
      return this;
    },
    build() {
      return fetchOptions;
    },
  };
};
