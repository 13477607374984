import React from 'react';
import PasswordActionSuccessfulCard from './PasswordActionSuccessfulCard';


export default () => (
  <PasswordActionSuccessfulCard
    title="Password changed successfully"
    description="Taking you back..."
  />
);
