import React from 'react';
import { createConnector } from 'cartiv';
import {
  Button, Card, Divider, Modal,
} from 'antd';
import { merge } from 'lodash';
import api from '../../services/api';
import { EmailTemplatesStore } from '../../stores/EmailTemplateStore';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import { navigateTo } from '../../services/history';
import TwoRowsCell from '../../components/table/cell/TwoRowsCell';
import RowButton from '../../components/table/action/RowButton';
import Table from '../../components/table/Table';
import DateAndModifierCell from '../../components/table/cell/DateAndModifierCell';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(EmailTemplatesStore)
class EmailTemplatesPage extends React.Component {
  templateColumns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (text, record, idx) => (
      <TwoRowsCell upperText={record.name} lowerText={record.groupId} />
    ),
  }, {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    sorter: true,
  }, {
    title: 'Last modified',
    dataIndex: 'lastModifiedDate',
    key: 'lastModifiedDate',
    render: (text, { lastModifiedDate, lastModifiedBy }, idx) => (
      <DateAndModifierCell
        modifiedBy={lastModifiedBy}
        date={lastModifiedDate}
      />
    ),
  }, {
    title: 'Actions',
    key: 'actions',
    render: function (text, record) {
      return (
        <span>
          <RowButton
            icon="edit"
            onClick={() => this.goToTemplate(record)}
          />
          <Divider type="vertical" />
          <RowButton
            icon="delete"
            onClick={() => {
              this.confirmDelete(record);
            }}
          />
        </span>
      );
    }.bind(this),
  }];

  constructor(props) {
    super(props);
    this.state = {
      templates: [],
    };
  }

  componentDidMount() {
    this.query({});
  }

  goToTemplate = ({ id }) => {
    navigateTo(`${PATHS.EMAILS_TEMPLATE}/${id}`);
  };

  confirmDelete = ({ id, name }) => {
    Modal.confirm({
      title: 'Confirmation required',
      content: `Are you sure you want to delete '${name}'`,
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => api.Template.onDeleteTemplate(id),
    });
  };

  // eslint-disable-next-line class-methods-use-this
  query({
    page = 0, pageSize = DEFAULT_ROW_NR, sortField = 'lastModifiedDate', sortOrder = 'DESC',
  }) {
    api.Template.onLoadPaginatedTemplates({
      fieldName: sortField,
      direction: sortOrder,
      size: pageSize,
      page,
    });
  }

  render() {
    const {
      templates: templatesData, totalElements, lastPagination, loading,
    } = this.state;

    const pagination = merge({
      total: totalElements,
      onChange: this.query,
    }, {
      pageSize: lastPagination ? lastPagination.pageSize : undefined,
      current: lastPagination ? lastPagination.page : undefined,
    });

    return (
      <Card
        title="Email Templates"
        extra={(
          <Button
            onClick={() => navigateTo(`${PATHS.EMAILS_TEMPLATE}/new`)}
            type="primary"
            size="large"
            icon="plus"
            className="pull-right"
          >
            New Template
          </Button>
        )}
      >
        <Table
          data={templatesData}
          columns={this.templateColumns}
          onChange={this.query}
          paginator={pagination}
          loading={loading}
        />
      </Card>
    );
  }
}

export { EmailTemplatesPage };
