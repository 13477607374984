import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { clone } from '../../lib/clone';
import { genericErrorHandler } from '../../utils/Common';
import {
  addTitleFields,
  getConvertedSpecsFromArrayIntoObject,
  getConvertedSpecsFromObjectIntoArray,
  getReferenceTypes,
  getTitleFields,
} from '../../lib/converters';
import { navigateTo } from '../../services/history';
import { ReferenceTypesStore } from '../entityDefinitionEditor/ReferenceTypesStore';
import { PATHS } from '../../constants';

export const CollectionTypesStore = createStore({
  api,
  name: 'CollectionTypes',
}, {
  getInitialState() {
    return {
      collectionTypes: [],
      collectionType: {
        type: '',
        spec: {},
        description: '',
      },
      collectionTypeListSpec: {
        type: '',
        spec: [],
        description: '',
        acl: [],
      },
      collectionTypesLoading: false,
    };
  },
  onLoadCollectionTypes() {
    this.setState({ collectionTypesLoading: true, collectionTypes: [] });
    backend
      .get('/collection/type').then((collectionTypes) => {
        collectionTypes.sort((a, b) => (a.type > b.type ? 1 : (a.type < b.type ? -1 : 0)));

        this.setState({
          collectionTypes,
        });
      })
      .catch(genericErrorHandler)
      .finally(this.setState({ collectionTypesLoading: false }));
  },

  onLoadCollectionType(type, callback) {
    backend.get(`/collection/type/${type}`).then((result) => {
      this.setState({
        collectionType: result,
      }, () => {
        if (callback) {
          callback(result);
        }
      });
    }).catch(genericErrorHandler);
  },

  onLoadCollectionTypeListSpec(type) {
    backend.get(`/collection/type/${type}`).then((result) => {
      const collectionTypeListSpec = clone(result);
      collectionTypeListSpec.spec = getConvertedSpecsFromObjectIntoArray(collectionTypeListSpec.spec);
      const referenceTypes = getReferenceTypes(collectionTypeListSpec.spec);
      for (let i = 0; i < referenceTypes.length; i++) {
        ReferenceTypesStore.onLoadReferenceTypeListSpec(referenceTypes[i].referenceType);
      }
      collectionTypeListSpec.spec = addTitleFields(collectionTypeListSpec.spec, result.titleFields);

      this.setState({
        collectionTypeListSpec,
      });
    }).catch(genericErrorHandler);
  },
  onCreateCollectionType(newCollectionType) {
    const tempCollectionType = clone(newCollectionType);
    tempCollectionType.spec = getConvertedSpecsFromArrayIntoObject(newCollectionType.spec);

    tempCollectionType.titleFields = getTitleFields(newCollectionType.spec);

    backend.post('/collection/type', { data: tempCollectionType }).then((result) => {
      notification.success({ message: 'Collection type successfully created' });

      const tempNewCollectionType = clone(newCollectionType);
      tempNewCollectionType.id = result.id;
      const tempCollection = this.state.collectionTypes.slice();
      tempCollection.push(tempNewCollectionType);
      this.setState({ collectionTypes: tempCollection });
      this.onResetCollectionType();
      navigateTo(PATHS.COLLECTION_TYPES);
    }).catch(genericErrorHandler);
  },
  onUpdateCollectionType(id, collectionType) {
    const tempCollectionType = clone(collectionType);
    tempCollectionType.spec = getConvertedSpecsFromArrayIntoObject(collectionType.spec);

    tempCollectionType.titleFields = getTitleFields(collectionType.spec);

    backend.put(`/collection/type/${id}`, { data: tempCollectionType }).then((result) => {
      notification.success({ message: 'Collection type updated' });
      this.onResetCollectionType();
      navigateTo(PATHS.COLLECTION_TYPES);
    }).catch(genericErrorHandler);
  },
  onResetCollectionType() {
    const resetColType = {
      type: '',
      spec: {},
      description: '',
    };

    this.setState({ collectionType: resetColType });
  },
  onResetCollectionTypeListSpec() {
    const resetColTypeListSpec = {
      type: '',
      spec: [],
      description: '',
    };
    this.setState({ collectionTypeListSpec: resetColTypeListSpec });
  },
  onDeleteCollectionType(id) {
    backend.delete(`/collection/type/${id}`).then((result) => {
      notification.success({ message: 'Collection type deleted' });
      const tempCollection = this.state.collectionTypes.slice();
      const index = tempCollection.findIndex((collectionType) => collectionType.id === id);
      tempCollection.splice(index, 1);
      this.setState({ collectionTypes: tempCollection });
    }).catch(genericErrorHandler);
  },

  setLoading(collectionTypesLoading) {
    this.setState({ collectionTypesLoading });
  },

  /**
   * Called by api.jsx
   */
  onApplicationDomainChange() {
    this.onLoadCollectionTypes();
  },
});
