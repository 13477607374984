import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../../services/api/BackendApi';
import fileBackend from '../../services/api/FileApi';

import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';
import { navigateTo } from '../../services/history';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import { getPageAfterRemoval } from '../../utils/pagination/Utils';
import { PATHS } from '../../constants';

export const AccountProfilesStore = createStore({
  api,
  name: 'AccountProfiles',
}, {
  getInitialState() {
    return {
      accountProfiles: [],
      totalElements: -1,
      lastPagination: {
        page: 0,
      },
      profile: {
        name: '',
        description: '',
        roles: [],
      },
      customRoles: [],
      accountRoles: [],
      collectionRoles: [],
      emailRoles: [],
      auditRoles: [],
      fileRoles: [],
      accountProfilesLoading: false,
    };
  },
  onLoadPaginated({
    fieldName, direction, page, pageSize = DEFAULT_ROW_NR,
  }) {
    this.setState({ accountProfilesLoading: true });

    backend.get('/account/profiles', {
      params: {
        page, sort: fieldName, order: direction,
      },
    }).then((result) => {
      this.setState({
        accountProfiles: result.content,
        totalElements: result.totalElements,
        lastPagination: {
          fieldName, direction, page, pageSize,
        },
      });
    })
      .catch(genericErrorHandler)
      .finally(() => this.setState({ accountProfilesLoading: false }));
  },

  onLoadCustomRoles() {
    backend.get('/account/settings').then((result) => {
      this.setState({
        customRoles: (result.customAuthorities || []).map((customRole) => customRole.name),
      });
    }).catch(genericErrorHandler);
  },

  onLoadProfile(profileId) {
    backend.get(`/account/profiles/${profileId}`).then((result) => {
      this.setState({
        profile: result,
      });
    }).catch(genericErrorHandler);
  },

  onResetProfile() {
    this.setState({
      profile: {
        name: '',
        description: '',
        roles: [],
      },
    });
  },

  onCreateProfile(profileData) {
    backend.post('/account/profiles', { data: profileData })
      .then((result) => {
        notification.success({ message: 'Profile created' });

        const tempAccountProfiles = this.state.accountProfiles.slice();

        tempAccountProfiles.push(result);

        this.setState({ accountProfiles: tempAccountProfiles });

        navigateTo(PATHS.ACCOUNTS_PROFILES);
      }).catch(genericErrorHandler);
  },

  onUpdateProfile(profileData) {
    const tempAccountProfiles = this.state.accountProfiles.slice();
    const index = tempAccountProfiles
      .findIndex((profile) => profile.profileId === profileData.profileId);
    tempAccountProfiles[index] = profileData;

    backend.patch(`/account/profiles/${profileData.profileId}`, { data: profileData }).then((result) => {
      notification.success({ message: 'Profile saved' });
      this.setState({ accountProfiles: tempAccountProfiles });
      navigateTo(PATHS.ACCOUNTS_PROFILES);
    }).catch(genericErrorHandler);
  },

  onDeleteProfile(profileId) {
    const { accountProfiles, lastPagination } = this.state;

    backend.delete(`/account/profiles/${profileId}`).then((result) => {
      this.onLoadPaginated({
        ...lastPagination,
        page: getPageAfterRemoval(lastPagination.page, accountProfiles.length),
      });

      notification.success({ message: 'Profile deleted' });
    }).catch(genericErrorHandler);
  },

  onLoadRoles(serviceEndpoint, serviceName = serviceEndpoint) {
    backend.get(`/${serviceEndpoint}/roles`).then((result) => {
      this.setState({
        [`${serviceName}Roles`]: result,
      });
    }).catch(genericErrorHandler);
  },

  onLoadFileRoles() {
    fileBackend.get('/api/files/roles').then((result) => {
      this.setState({ fileRoles: result });
    }).catch(genericErrorHandler);
  },
});
