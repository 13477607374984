import React from 'react';
import { createConnector } from 'cartiv';
import { Spin } from 'antd';
import menu from './menu.json';
import MenuItem from '../../components/SideBar/MenuItem';
import { CollectionTypesStore } from './CollectionTypesStore';
import { DomainSelectorStore } from '../../components/DomainSelector/DomainSelectorStore';
import api from '../../services/api';
import { clone } from '../../lib/clone';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(CollectionTypesStore)
@connect(DomainSelectorStore)
class CollectionMenuItem extends React.Component {
  componentDidMount() {
    api.CollectionTypes.onLoadCollectionTypes();
  }

  render() {
    const { collectionTypesLoading, collectionTypes } = this.state;
    const subMenus = clone(menu.children);

    if (collectionTypes) {
      collectionTypes.map((collection) => {
        const name = collection.type;
        subMenus.push({
          title: `${name.charAt(0).toUpperCase() + name.slice(1)}s`,
          icon: 'database',
          link: `${PATHS.COLLECTION_EDIT}/${name}`,
        });
      });
    }

    return (
      <>
        <MenuItem
          className={menu.className}
          icon={menu.icon}
          link={menu.link}
          title={menu.title}
          subMenu={subMenus}
          key={menu.title}
          {...this.props}
        />
        {collectionTypesLoading && <Spin size="small" /> }
      </>
    );
  }
}

export { CollectionMenuItem };
