import { createStore } from 'cartiv';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { clone } from '../../lib/clone';
import { genericErrorHandler } from '../../utils/Common';
import { addTitleFields, getArrayOfRootLeaves, getConvertedSpecsFromObjectIntoArray } from '../../lib/converters';

export const ReferenceTypesStore = createStore({
  api,
  name: 'ReferenceTypes',
}, {
  getInitialState() {
    return {
      referenceTypes: [],
      referenceType: {
        type: '',
        spec: {},
        description: '',
      },
      referenceTypeListSpec: {},
    };
  },
  onLoadReferenceTypes() {
    backend.get('/collection/type').then((result) => {
      this.setState({
        referenceTypes: result,
      });
      this.onLoadReferenceTypeListSpec(result[0].type);
    }).catch(genericErrorHandler);
  },
  onLoadReferenceTypeListSpec(type, callback) {
    backend.get(`/collection/type/${type}`).then((result) => {
      const tempReferenceTypeListSpec = clone(this.state.referenceTypeListSpec);
      const tempReferenceTypeListSpecItem = clone(result);
      tempReferenceTypeListSpecItem.spec = getConvertedSpecsFromObjectIntoArray(tempReferenceTypeListSpecItem.spec);

      tempReferenceTypeListSpecItem.spec = addTitleFields(tempReferenceTypeListSpecItem.spec, result.titleFields);
      if (callback) {
        const param = getArrayOfRootLeaves(tempReferenceTypeListSpecItem.spec)[0];
        callback(param);
      }
      tempReferenceTypeListSpec[type] = tempReferenceTypeListSpecItem;
      this.setState({
        referenceTypeListSpec: tempReferenceTypeListSpec,
      });
    }).catch(genericErrorHandler);
  },
});
