import React from 'react';
import PasswordActionSuccessfulCard from '../PasswordReset/PasswordActionSuccessfulCard';


export default (props) => (
  <PasswordActionSuccessfulCard
    {...props}
    title="Account successfully activated!"
    description="Logging you in..."
  />
);
