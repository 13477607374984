import React from 'react';
import { createConnector } from 'cartiv';
import { GroupInput } from '../genericEditor/FormControls';
import { FileSettingsStore } from './FileSettingsStore';
import mimeTypes from './mimeTypes';
import './FileSettingsPage.scss';
import { clone } from '../../lib/clone';

const connect = createConnector(React);

@connect(FileSettingsStore)
class FileSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        allowedTypes: [],
      },
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.addType = this.addType.bind(this);
    this.removeType = this.removeType.bind(this);
    this.renderType = this.renderType.bind(this);
    this.save = this.save.bind(this);
  }

  componentWillMount() {
    FileSettingsStore.onLoadFileSettings();
  }

  onChange(settings) {
    this.setState({settings});
  }

  onTypeChange(type, i) {
    let settings = this.state.settings;
    if (settings.allowedTypes[i].type !== type.type) {
      type.subtype = '*';
    }
    settings.allowedTypes[i] = type;
    this.setState({settings});
  }

  addType() {
    let settings = this.state.settings;
    settings.allowedTypes.push({
      type: '*',
      subtype: '*',
    });
    this.setState({settings});
  }

  removeType(i) {
    let settings = this.state.settings;
    settings.allowedTypes.splice(i, 1);
    this.setState({settings});
  }

  save(e) {
    e.preventDefault();
    FileSettingsStore.onUpdateFileSettings(this.state.settings);
  }

  renderType(type, i) {
    const typeOptions = mimeTypes.map(type => {
      return {label: type.type, value: type.type}
    });
    typeOptions.unshift({label: '*', value: '*'});

    let typeSpec = {
      spec: {
        type: {
          _type: 'Select',
          options: typeOptions,
        },
      },
    };

    if (type.type !== '*') {
      const subtypeOptions = mimeTypes.find(mimeType => mimeType.type === type.type).subtypes.map(subtype => {
        return {label: subtype, value: subtype}
      });
      subtypeOptions.unshift({label: '*', value: '*'});

      typeSpec.spec['subtype'] = {
        _type: 'Select',
        options: subtypeOptions,
      };
    }

    return (
      <div className={'type row'} key={i}>
        <GroupInput
          className={'row type-group ant-col-11'}
          fieldClasses={{
            groupClass: 'ant-col-5',
            labelClass: '',
            inputClass: '',
            inputWrapperClass: '',
          }}
          entityDefinition={typeSpec}
          value={type}
          onChange={(value) => this.onTypeChange(value, i)}
        />
        <div className={'button-wrapper ant-col-1'}>
          <button
            type="button"
            title={'Delete element'}
            className={`remove btn btn-sm btn-danger`}
            onClick={() => this.removeType(i)}
          ><span className="fa fa-trash-o"/>
          </button>
        </div>
      </div>
    )
  }

  render() {
    const FormSpec = {
      spec: {
        maxSize: {
          label: 'Max file size (MB)',
          _type: 'Number',
        },
        cacheRetention: {
          label: 'How long to keep the cache (days)',
          _type: 'Number',
        },
      },
    };

    return (
      <div className={'FileSettingsPage content-wrapper'}>
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Edit File Settings</h2>
            {this.state.loading ?
              <div>
                Loading...
              </div>
              : (
                this.settings && this.settings.allowedTypes ?
                  this.renderForm(FormSpec) : <div>Couldn't load file settings</div>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  renderForm(FormSpec) {
    return <form onSubmit={this.save}>
      <GroupInput entityDefinition={FormSpec} fieldClasses={{
        groupClass: 'ant-row',
        labelClass: 'ant-col-6',
        inputClass: '',
        inputWrapperClass: 'ant-col-18',
      }} value={this.state.settings} onChange={this.onChange}/>
      <h3>Allowed types:</h3>
      {this.state.settings.allowedTypes.map((allowedType, i) => this.renderType(clone(allowedType), i))}
      <button type={'button'} className={'add-type btn btn-sm btn-success'} onClick={this.addType}>
        <span className="mdi mdi-plus"/> Add Type
      </button>
      a
      <button type="submit" className="save btn btn-lg btn-primary pull-right">
        <i className="fa fa-save"/>
        Save
      </button>
    </form>;
  }
}

export { FileSettingsPage };
