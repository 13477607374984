import React from 'react';
import { createConnector } from 'cartiv';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';
import { AuthProviderEpo } from '../../lib/jap-client/api';
import api from '../../services/api';
import { LoginStore } from './LoginStore';
import { I18nStore } from '../i18n/i18nStore';
import GroupInput from '../../pages/genericEditor/FormControls/GroupInput';
import { RegistrationStore } from '../Registration/RegistrationStore';
import { parse } from '../../lib/query-string';
import { EmbeddableStore } from '../../stores/EmbeddableStore';
import { navigateTo } from '../../services/history';
import { ThirdPartyAuth } from './ThirdPartyAuth';
import BackendApi from '../../services/api/BackendApi';
import { buildRequestOptions } from '../../utils/api/Utils';
import { PATHS } from '../../constants';
import './LoginForm.scss';

const connect = createConnector(React);

interface LoginFormProps extends RouteComponentProps {

}

interface LoginStoreState {
  loginError?: { title: string }
  lastFetched?: number,
  loading?: boolean,
}

interface I18nLoginSate {
  strings: { Login: any } | undefined,
}

interface RegistrationStoreState {
  registrationError?: any,
  lastFetched?: number,
  facebookAppId?: string,
  googleAppId?: string,
  facebookSignUpEnabled?: boolean,
  googleSignUpEnabled?: boolean,
  successfulResetUrl?: string,
  signupAllowed?: boolean,
}

interface EmbeddableStoreState {

}

interface LoginFormState extends LoginStoreState, I18nLoginSate, RegistrationStoreState, EmbeddableStoreState {
  email: string,
  password: string
  profileIds: string[],
  domainId: string | undefined,
  enabledAuthProviders: AuthProviderEpo[],
}

@connect(LoginStore)
@connect(I18nStore)
@connect(RegistrationStore)
@connect(EmbeddableStore)
class LoginForm extends React.Component<LoginFormProps, LoginFormState>  {
  constructor(props: LoginFormProps) {
    super(props);
    this.state = {
      strings: { Login: {} },
      email: '',
      password: '',
      profileIds: [],
      domainId: undefined,
      enabledAuthProviders: [],
    };
    this.onClickForgotPassword = this.onClickForgotPassword.bind(this);
  }

  componentWillMount() {
    api.Embeddable.onEnable();

    const domaninId = parse(this.props.location.search).domainId;

    if (domaninId) {
      api.Registration.onLoadAccountSettingsByDomainId(domaninId);
    }
  }

  componentDidMount() {
    localStorage.clear();
    const { domainId } = parse(this.props.location.search);

    if (domainId) {
      const fetchOptions = buildRequestOptions()
        .withDomainId(domainId)
        .noToken()
        .build();

      BackendApi.get(`/account/settings/authProviders/${domainId}`, fetchOptions)
        .then((data: AuthProviderEpo[]) => {
          window.parent.postMessage({ name: 'nrAuthProviders', nrAuthProviders: data ? data.length : 0 }, '*');
          this.setState({ domainId, enabledAuthProviders: data });
        });
    } else {
      console.warn('Missing domainId as URI parameter');
    }
  }

  componentWillUnmount() {
    api.Embeddable.onDisable();
  }

  login() {
    api.Login.onLogin(
      this.state.email,
      this.state.password,
      parse(this.props.location.search).domainId,
    );
  }

  renderErrorElement(loginError: { title: string }) {
    return (
      <div className="ant-row error-wrapper">
        <p className="error">{loginError.title}</p>
      </div>
    );
  }

  onClickForgotPassword() {
    navigateTo(
      `${PATHS.EMBED_REQUEST_PASSWORD_RESET}?domainId=${
      parse(this.props.location.search).domainId
      }`,
    );
    window.parent.postMessage('Forgot password', '*');
  }

  render() {
    const errorElement = this.state.loginError
      ? this.renderErrorElement(this.state.loginError)
      : null;
    const { enabledAuthProviders } = this.state;
    return (
      <div className="LoginForm">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.login();
          }}
        >
          <GroupInput
            value={{
              email: this.state.email,
              password: this.state.password,
            }}
            entityDefinition={{
              spec: {
                email: 'Email',
                password: 'Password',
              },
            }}
            name="user"
            onChange={this.setState.bind(this)}
          />
          {errorElement}
          <div className="ant-row btn-container">
            <Button type="link" onClick={this.onClickForgotPassword} className="forgot-password">
              {this.state.strings?.Login?.forgotPassword}
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="primary-login"
              icon="unlock"
            >
              {this.state.strings?.Login?.action}
            </Button>
          </div>
          {
            enabledAuthProviders && enabledAuthProviders.length > 0
              ? (
                <div>
                  <div className="horizontal-line-with-text-in-middle"><h6><span>or</span></h6></div>
                  {enabledAuthProviders
                    .map((authProviderInfo, index) => (
                      <ThirdPartyAuth
                        key={`provider-${index}`}
                        authProviderInfo={authProviderInfo}
                        domainId={this.state.domainId || 'N/A'}
                      />
                    ))}
                </div>
              ) : null
          }
          {
            this.state.signupAllowed
            && (
              <div className="register-link">
                Don't have an account?
                <Link
                  to={`${PATHS.EMBED_REGISTER}?domainId=${
                    parse(this.props.location.search).domainId
                    }`}
                  className="link"
                >
                  Register here
                </Link>
              </div>
            )
          }
        </form>
      </div>
    );
  }
}

export { LoginForm };
