import React, { useState } from 'react';
import PasswordResetRequestCard from '../../components/PasswordReset/PasswordResetRequestCard';
import PasswordResetDomainSelectorCard from '../../components/PasswordReset/PasswordResetDomainSelectorCard';
import PasswordSetCardsContainer from './PasswordSetCardsContainer';


export default (props) => {
  const [showDomainSelector, setShowDomainSelector] = useState(false);
  const [emailDomainGroup, setEmailDomainGroup] = useState({});

  const toggleShowDomainSelector = (domainAndGroup, email) => {
    setShowDomainSelector(!showDomainSelector);
    setEmailDomainGroup({ email, domainAndGroup });
  };

  return (
    <PasswordSetCardsContainer>
      {showDomainSelector ? (
        <PasswordResetDomainSelectorCard {...emailDomainGroup} />
      ) : (
        <PasswordResetRequestCard
          {...props}
          toggleShowDomainSelector={toggleShowDomainSelector}
        />
      )}
    </PasswordSetCardsContainer>
  );
};
