const { darken, lighten } = require('polished');

//
// const toThreeTones = (label, color) => ({
//   [label]: color,
//   [`${[label]}Light`]: lighten(.2, color),
//   [`${[label]}Dark`]: darken(.2, color),
// });

const colorVariables = {
  jrGrey: '#282835',
  jrRed: '#E56D65',
  white: '#fff',
  greyLighter: '#F7F9FB',
  greyLight: '#f8f9fa',
  grey: '#ced4da',
  greyDark: '#6c757d',
  blue: '#1890ff',
};


const Theme = {
  colors: Object.assign(colorVariables, {
    primary: colorVariables.jrGrey,
    secondary: colorVariables.red,
    content: colorVariables.greyLight,
    hover: colorVariables.greyLight,
    hoverBorder: colorVariables.grey,
    focus: colorVariables.greyLight,
    contrastBg: colorVariables.white,
    hr: colorVariables.greyLight,
    blue: colorVariables.blue,
  }),

  layout: {
    header: {
      height: 64,
      logo: 'images/logo_header.png',
    },
    sidebar: {
      width: 255,
    },
    footer: {
      height: 53,
    },
  },

  font: {
    size: {
      sm: '11px',
      md: '14px',
      lg: '18px',
    },
    family: '"Proxima Nova", Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },

  space: {
    xxxs: '4px',
    xxs: '10px',
    xs: '16px',
    sm: '24px',
    md: '36px',
    lg: '48px',
    xl: '64px',
    xxl: '80px',
    xxxl: '128px',
  },
};

module.exports = {
  Theme,
  colors: Theme.colors,
  sizes: Theme.sizes,
  layout: Theme.layout,
};
