import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../services/api/BackendApi';
import api from '../services/api';
import { genericErrorHandler } from '../utils/Common';
import { sortBy } from '../lib/sortBy';
import { CollectionTypesStore } from '../pages/collection/CollectionTypesStore';
import { navigateTo } from '../services/history';
import { getReferenceTypes } from '../lib/converters';
import { DEFAULT_ROW_NR } from '../components/pagination/Pagination';
import { getPageAfterRemoval } from '../utils/pagination/Utils';
import { PATHS } from '../constants';

export const GenericEntityStore = createStore(
  {
    api,
    name: 'GenericEntity',
  }, {
    getInitialState() {
      return {
        loading: false,
        error: false,
        entities: [],
        perPage: 1,
        loadedEntity: null,
        loadededEntityDate: -1,
        referenceEntities: [],
        totalElements: -1,
        lastPagination: {
          page: 0,
        },
      };
    },

    onLoadPaginatedGenericEntities({
      fieldName, query, direction, page, pageSize = DEFAULT_ROW_NR, entityName,
    }) {
      this.setState({ loading: true });

      backend.get(`/collection/${entityName}`, {
        params: {
          q: query,
          page,
          sort: fieldName,
          order: direction,
          size: pageSize,
        },
      })
        .then((response) => {
          sortBy(response.content, 'weight');
          this.setState({
            entities:
              response.content.map((e) => {
                // eslint-disable-next-line no-underscore-dangle
                e._type = entityName;
                return e;
              }),
            totalElements: response.totalElements,
            lastPagination: {
              fieldName, direction, page, pageSize,
            },
          });
        })
        .catch(genericErrorHandler)
        .finally(() => this.setState({ loading: false }));
    },

    onLoadReferenceEntities(entityName, displayField) {
      this.setState({ loading: true });
      backend.get(`/collection/${entityName}`, { params: { page: 0, sort: displayField, order: 'DESC' } })
        .then((response) => {
          sortBy(response.content, 'weight');
          const tempEntities = this.state.referenceEntities.slice();
          tempEntities.push({
            referenceType: entityName,
            displayField,
            entities: response.content.map((e) => {
              e._type = entityName;
              return e;
            }),
          });
          this.setState({
            referenceEntities: tempEntities,
          });
        })
        .catch(genericErrorHandler)
        .finally(() => this.setState({ loading: false }));
    },
    onLoadAllReferenceEntities(type) {
      backend.get(`/collection/type/${type}`).then((result) => {
        const referenceTypes = getReferenceTypes(result.spec);
        for (let i = 0; i < referenceTypes.length; i++) {
          this.onLoadReferenceEntities(
            referenceTypes[i].referenceType,
            referenceTypes[i].displayField,
          );
        }
      }).catch(genericErrorHandler);
    },
    onLoadEntityById(entity, id) {
      this.setState({ loading: true, loadedEntity: null });
      backend.get(`/collection/${entity}/${id}`)
        .then((response) => {
          this.setState({
            loadedEntity: response,
            loadededEntityDate: +new Date(),
          });
        })
        .catch(genericErrorHandler)
        .finally(() => this.setState({ loading: false }));
    },
    onUnloadEntity() {
      this.setState({ loading: false, loadedEntity: null });
    },
    handleNestedObject(obj, parentKey, handlerFunction) {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'undefined' || obj[key] === null) {
          // do nothing
        } else if (obj[key].constructor === Array) {
          for (let index = 0; index < obj[key].length; index++) {
            const newKey = `${parentKey || ''}.${key}.${index}`;
            this.handleNestedObject(obj[key][index], newKey, handlerFunction);
          }
        } else if (typeof obj[key] === 'object') {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          this.handleNestedObject(obj[key], newKey, handlerFunction);
        } else {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          handlerFunction(obj, newKey);
        }
      });
    },
    getTypeByKey(definition, key) {
      return key.split('.')
        .reduce((def, ky) => {
          if (def && def[ky] && def[ky].spec) {
            return def[ky].spec;
          }
          if (def && def[ky] && def[ky]._type) {
            return def[ky]._type;
          }
          if (def && def[ky] && def[ky]) {
            return def[ky];
          }
          return null;
        }, definition.spec);
    },
    getValueByKey(obj, key) {
      const keys = key.split('.');
      let val = obj;
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i].split(':');
        for (let j = 0; j < k.length; j++) {
          if (val[k[j]]) val = val[k[j]];
          else return null;
        }
      }
      return val || null;
    },
    setValueByKey(obj, key, value) {
      const keys = key.split('.');
      let val = obj;
      for (let i = 0; i < keys.length - 1; i++) {
        const k = keys[i].split(':');
        for (let j = 0; j < k.length; j++) {
          if (val[k[j]]) val = val[k[j]];
          else return null;
        }
      }
      val[keys[keys.length - 1]] = value;
    },
    prepareImages(type, entity) {
      /**
       * function to return a list with the keys of all images
       * @param entity
       * @param key
       * @param imagesList
       */
      const getImageList = (_, key, imagesList) => {
        const typeDef = this.getTypeByKey(this.state.collectionType, key);
        if (typeDef && typeDef === 'Image') {
          imagesList.push(key);
        }
      };

      const images = [];
      CollectionTypesStore.onLoadCollectionType(type, () => {
        this.handleNestedObject(entity, null, (ent, key) => {
          getImageList(ent, key, images);
        });
      });
      let imagesUploaded = 0;
      // use the list to upload all images then get & substitute their urls.
      if (images.length === 0) {
        return Promise.resolve(entity);
      }
      return new Promise((resolve, reject) => {
        const checkEnd = () => {
          imagesUploaded += 1;
          if (imagesUploaded === images.length) {
            resolve(entity);
          }
        };
        images.map((image) => {
          const value = this.getValueByKey(entity, image);
          if (value.indexOf('https') !== -1) {
            checkEnd();
          } else {
            fetch(value)
              .then((resp) => {
                resp.blob()
                  .then((blob) => {
                    backend.postFile([blob])
                      .then((res) => {
                        this.setValueByKey(entity, image, res.filePaths[0]);
                        checkEnd();
                      })
                      .catch((error) => {
                        reject(error.message);
                      });
                  })
                  .catch((error) => {
                    reject(error.message);
                  });
              })
              .catch((error) => {
                reject(error.message);
              });
          }
        });
      });
    },
    entitySaved(entityName) {
      const message = `Successfully saved ${entityName}`;
      notification.success({ message });
      navigateTo(`${PATHS.COLLECTION_EDIT}/${entityName}`);
    },
    onCreateEntity(type, data) {
      this.setState({ loading: true, error: false });
      this.prepareImages(type, data)
        .then((dataWithImageUrls) => backend.post(`/collection/${type}/`, { data: dataWithImageUrls })
          .then((response) => {
            this.setState({ error: false });
            return type;
          }))
        .then(this.entitySaved)
        .catch(genericErrorHandler)
        .finally(() => this.setState({ loading: false }));
    },
    onUpdateEntity(type, id, data) {
      this.setState({ loading: true, error: false });
      this.prepareImages(type, data)
        .then((dataWithImageUrls) => backend.put(`/collection/${type}/${id}`, { data: dataWithImageUrls })
          .then((response) => {
            this.setState({ error: false });
            return type;
          }))
        .then(this.entitySaved)
        .catch(genericErrorHandler)
        .finally(() => this.setState({ loading: false }));
    },

    onChown(type, id, owner) {
      return backend.post(`/collection/${type}/${id}/chown`, { data: owner })
        .then((response) => type)
        .catch(genericErrorHandler);
    },

    onDelete(entityName, id) {
      const { lastPagination, entities } = this.state;

      backend.delete(`/collection/${entityName}/${id}`)
        .then(() => {
          this.onLoadPaginatedGenericEntities({
            ...lastPagination,
            entityName,
            page: getPageAfterRemoval(lastPagination.page, entities.length),
          });
        })
        .catch((error) => {
          notification.error({ message: `Failed to delete ${entityName}. Error: ${error.status} - ${error.statusText}` });
        });
    },
  },
);
