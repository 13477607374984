import React from 'react';
import moment from 'moment';

const DEFAULT_moment_FORMAT = 'lll';
const DateCell = ({ date, format = DEFAULT_moment_FORMAT, className }) => {
  const momentDate = moment(date);
  const strValue = momentDate.isValid()
    ? `${momentDate.format(format)}`
    : 'invalid';

  return (<span className={className}>{strValue}</span>);
};

export default DateCell;
