import React from 'react';
import { Pagination } from 'antd';

export const DEFAULT_ROW_NR = 10;

const AppPagination = ({
  current, total, onChange, pageSize = DEFAULT_ROW_NR,
}) => (

  <Pagination
    pageSize={pageSize}
    total={total}
    current={current + 1}
    defaultCurrent={2}
    onChange={onChange}
  />
);

export default AppPagination;
