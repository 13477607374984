import React from 'react';
import moment from 'moment';
import { createConnector } from 'cartiv';
import {
  Button, Card, Divider, Modal, notification,
} from 'antd';
import FileSaver from 'file-saver';
import styled from 'styled-components';
import { get, merge } from 'lodash';
import api from '../../services/api';
import { I18nStore } from '../../components/i18n/i18nStore';
import { GenericEntityStore } from '../../stores/GenericEntityStore';
import { DraggerStore } from '../../components/Dragger/DraggerStore';
import { CollectionTypesStore } from '../collection/CollectionTypesStore';
import { GroupInput } from './FormControls';
import { capitalize } from '../../lib/capitalize';
import { navigateTo } from '../../services/history';
import { GroupStore } from '../account/GroupStore';
import GenericEditor from '../../stores/LanguageStore';
import backendApi from '../../services/api/BackendApi';
import Table from '../../components/table/Table';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import RowButton from '../../components/table/action/RowButton';
import DateAndModifierCell from '../../components/table/cell/DateAndModifierCell';
import TagsCell from '../../components/table/cell/TagsCell';
import { PATHS } from '../../constants';

const connect = createConnector(React);

export const generateGroupInputDef = function (fields, spec) {
  const groupInputDef = {};
  fields.forEach((field) => {
    // eslint-disable-next-line no-underscore-dangle
    const fieldType = typeof spec[field] === 'object' ? spec[field]._type : spec[field];

    switch (fieldType) {
      case 'Date':
        groupInputDef[`from${capitalize(field)}`] = 'Date';
        groupInputDef[`to${capitalize(field)}`] = 'Date';
        break;

      case 'Select':
        groupInputDef[field] = {
          _type: 'Select',
          options: [{ value: 'all', label: 'all' }].concat(spec[field].options),
        };
        break;

      case 'Boolean':
        groupInputDef[field] = {
          _type: 'Select',
          options: ['all', 'true', 'false'],
        };
        break;

      case 'Number':
        groupInputDef[`from${capitalize(field)}`] = 'Number';
        groupInputDef[`to${capitalize(field)}`] = 'Number';
        break;

      default:
        groupInputDef[field] = 'String';
    }
  });
  return groupInputDef;
};

export const generateQueryBasedOnDef = function (fields, spec, filters, languages = [], path = '') {
  const query = { $and: [] };
  fields.forEach((field) => {
    const queryPath = path !== '' ? `${path}.${field}` : field;
    const fieldType = typeof spec[field] === 'object' ? spec[field]._type : spec[field];
    const i18nEnabled = spec[field].i18n;

    switch (fieldType) {
      case 'Select':
        if (!!filters[field] && filters[field] !== 'all') {
          query.$and = query.$and.concat({ [queryPath]: filters[field] });
        }
        break;

      case 'Boolean':
        if (!!filters[field] && filters[field] !== 'all') {
          query.$and = query.$and.concat({
            [queryPath]: filters[field] === 'true',
          });
        }
        break;

      case 'Date':
      case 'Number':
        const fromQuery = {};
        if (filters[`from${capitalize(field)}`]) {
          fromQuery[queryPath] = { $gte: filters[`from${capitalize(field)}`] };
        }
        const toQuery = {};
        if (filters[`to${capitalize(field)}`]) {
          toQuery[queryPath] = { $lte: filters[`to${capitalize(field)}`] };
        }
        query.$and = query.$and || [];
        query.$and = query.$and.concat([fromQuery, toQuery]);
        break;

      case 'Tags':
        if (filters[field]) {
          query.$and = query.$and.concat({
            [queryPath]: {
              $all: filters[field].replace(/[ ,]+/g, ',').split(','),
            },
          });
        }
        break;

      default:
        if (filters[field]) {
          if (i18nEnabled) {
            const multiLangCondition = {
              $or: languages.map((lang) => ({
                [`${queryPath}._i18n.${lang}`]: {
                  $regex: filters[field],
                  $options: 'i',
                },
              })),
            };
            query.$and = query.$and.concat(multiLangCondition);
          } else {
            query[queryPath] = { $regex: filters[field], $options: 'i' };
            query.$and = query.$and.concat({
              [queryPath]: { $regex: filters[field], $options: 'i' },
            });
          }
        }
    }
  });

  if (query.$and.length > 0) {
    return query;
  }

  return {};
};

@connect(I18nStore)
@connect(DraggerStore)
@connect(GenericEntityStore)
@connect(CollectionTypesStore)
@connect(GroupStore)
@connect(GenericEditor)
class GenericEntityTable extends React.Component {
  constructor(props) {
    super();

    this.state = {
      filter: {},
    };

    this.query = this.query.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
  }


  componentDidMount() {
    this.initialize(this.props.match.params._type);
    api.GenericEditor.onLoadLanguages();
  }

  componentDidUpdate(prevProps, prevState) {
    const { _type } = this.props.match.params;
    const { _type: prevType } = prevProps.match.params;

    if (!this.state.loading && prevType !== _type) {
      this.initialize(_type);
    }
  }

  initialize(collectionType) {
    api.CollectionTypes.onLoadCollectionType(collectionType);
    api.Group.onLoadAllGroups();
    this.query({});
  }

  getQuery() {
    const definition = this.state.collectionType || {};
    const spec = definition.spec || {};
    const titleFields = definition.titleFields || [];

    const { filter, languages } = this.state;

    const query = generateQueryBasedOnDef(titleFields, spec, filter, languages);

    if (filter.group) {
      query['owner.groupIds'] = { $regex: filter.group, $options: 'i' };
    }
    return query;
  }

  clearQuery() {
    this.setState({ filter: {}, lastPagination: { page: 0 } }, () => {
      this.query({});
    });
  }

  downloadExportFile(format) {
    const { match } = this.props;
    const query = this.getQuery();
    // eslint-disable-next-line no-underscore-dangle
    const entityName = match.params._type;
    const uniqueFileName = this.getUniqueFileName;

    return () => {
      backendApi.downloadFile(`/collection/export/${entityName}/${format}`, { q: query })
        .then((resp) => {
          const blob = new Blob([resp], { type: 'application/json' });
          FileSaver.saveAs(blob, uniqueFileName(entityName, format));
        })
        .catch((error) => {
          notification.error({ message: error });
        });
    };
  }

  getUniqueFileName(entityName, extension) {
    return `${entityName}_${moment().format('YYYYMMDD-HHmmss')}.${extension}`;
  }

  renderFilters(titleFields, spec) {
    const formDef = generateGroupInputDef(titleFields, spec);

    formDef.group = {
      _type: 'String',
    };
    return (
      <div className="filters">
        <h4>Filters</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.query({});
          }}
        >
          <GroupInput
            name=""
            entityDefinition={{ spec: formDef }}
            value={this.state.filter}
            onChange={(filter) => this.setState({ filter })}
          />
          <div className="button-wrapper">
            <Button type="link" icon="close" onClick={this.clearQuery}>Clear</Button>
            <Button type="primary" htmlType="submit" icon="filter">Filter</Button>
          </div>
        </form>
        <hr />
      </div>
    );
  }

  goToEntity = ({ _id: id }) => {
    navigateTo(`${PATHS.COLLECTION_EDIT}/${this.props.match.params._type}/${id}`);
  };

  confirmDelete = (record) => {
    Modal.confirm({
      title: 'Confirmation required',
      content: 'Are you sure you want to delete entry?',
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => {
        api.GenericEntity.onDelete(this.props.match.params._type, record._id);
      },
    });
  };

  generateColumnCfg = ({ title, dataIndex, sorter }, spec) => {
    const columnCfg = {
      key: dataIndex,
      title,
      dataIndex,
      sorter,
    };

    if (dataIndex === 'tags' /* TODO expand based on spec per dataIndex */) {
      columnCfg.render = (text, record, i) => (
        <TagsCell tags={record.tags} />
      );
    }

    return columnCfg;
  };

  query({
    page = 0, pageSize = DEFAULT_ROW_NR, sortField = 'lastModifiedDate', sortOrder = 'DESC',
  }) {
    const { _type: entityName } = this.props.match.params;
    const query = this.getQuery();


    api.GenericEntity.onLoadPaginatedGenericEntities({
      fieldName: sortField,
      direction: sortOrder,
      pageSize,
      page,
      entityName,
      query,
    });
  }

  generateColumns(spec) {
    const { groups, collectionType } = this.state;

    if (!groups?.length || !collectionType?.id) {
      return [];
    }

    const titleFields = get(collectionType, 'titleFields', ['name']); // fallback for missing titleFields

    const columns = [{
      title: 'Groups',
      className: 'group-ids',
      dataIndex: 'owner.groupIds',
      key: 'domain',
      render: (text, record) => (<TagsCell tags={record.owner?.groupIds} />),
    }, {
      title: 'Last modified',
      className: 'last-modified',
      dataIndex: 'lastModifiedDate',
      key: 'lastModifiedDate',
      render: (text, { lastModifiedDate, lastModifiedBy }, idx) => (
        <DateAndModifierCell
          modifiedBy={lastModifiedBy}
          date={lastModifiedDate}
        />
      ),
    }, {
      title: 'Actions',
      key: 'actions',
      render: function (text, record) {
        return (
          <span>
            <RowButton
              icon="edit"
              onClick={() => this.goToEntity(record)}
            />
            <Divider type="vertical" />

            <RowButton
              icon="delete"
              onClick={() => {
                this.confirmDelete(record);
              }}
            />
          </span>
        );
      }.bind(this),
    }];

    Array.from(titleFields)
      .reverse()
      .forEach((field) => {
        const newColumnCfg = this.generateColumnCfg({
          key: field,
          title: field,
          dataIndex: field,
          sorter: true,
        }, spec);

        columns.unshift(newColumnCfg);
      });
    return columns;
  }

  render() {
    const { match, className } = this.props;
    const {
      entities: entitiesData, totalElements, lastPagination, collectionType, loading, language,
    } = this.state;

    const entityName = match.params._type;
    const spec = collectionType?.spec || {};
    const titleFields = collectionType?.titleFields || [];

    const pagination = merge({
      total: totalElements,
      onChange: this.query,
    }, {
      pageSize: lastPagination ? lastPagination.pageSize : undefined,
      current: lastPagination ? lastPagination.page : undefined,
    });

    return (
      <Card
        className={className}
        title={`${entityName.charAt(0).toUpperCase() + entityName.slice(1)} Management`}
        extra={(
          <div>
            <Button
              className="pull-right"
              onClick={this.downloadExportFile('json')}
              icon="download"
              type="link"
            >
              JSON
            </Button>
            <Button
              type="link"
              className="pull-right"
              onClick={this.downloadExportFile('csv')}
              icon="download"
              type="link"
            >
              CSV
            </Button>
            <Button
              type="primary"
              className="pull-right"
              size="large"
              onClick={() => navigateTo(`${PATHS.COLLECTION_EDIT}/${entityName}/new`)}
              icon="plus"
            >
              Add
            </Button>
          </div>
        )}
      >
        {this.renderFilters(titleFields, spec)}
        <Table
          data={entitiesData}
          columns={this.generateColumns(spec)}
          onChange={this.query}
          paginator={pagination}
          loading={loading}
          onRow={(record)=>({
            onClick: (e) => this.goToEntity(record),
          })}
          language={language}
        />
      </Card>
    );
  }
}


export default styled(GenericEntityTable)`
  .ant-table {
    table {
      width: 100%;
      // table-layout: fixed;
    }
    .ant-table-column-title {
      text-transform: capitalize;
    }
    .ant-table-tbody > tr {
      cursor: pointer;
      & > td {
        text-overflow: ellipsis;
        max-height: 3em;
        max-width: 30vw;
        white-space: nowrap;
        overflow: hidden;
      } 
      .last-modified {
        font-size: .85em;
        max-width: 170px;
      }
    }
  }
  .ant-card-body > * {
    margin-bottom: 30px;
  }

  .filters {
    form {
      display: flex;
      align-items: flex-end;
    }

    .GroupInput {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      > * {
        margin: 8px 16px;
        width: 13vw;
      }
    }

    .button-wrapper {
      padding-top: ${({ theme }) => theme.space.sm};
      padding-right: ${({ theme }) => theme.space.sm};
      display: flex;
      align-self: flex-start;

      button {
         margin-left: 16px;
      }
    }


    hr {
      border-top: 1px solid ${({ theme }) => theme.colors.hr};
    }
  }

`;
