import React from 'react';
import { createConnector } from 'cartiv';
import { Redirect } from 'react-router';
import { Button, Card, Divider } from 'antd';
import api from '../../services/api';
import { AuthStore } from '../../stores/AuthStore';
import { navigateTo } from '../../services/history';
import { DomainSettingsPageStore } from '../DomainSettingsPage/DomainSettingsPageStore';
import { DomainsManagementStore } from './DomainsManagementStore';
import { LayoutStore } from '../../components/Layout/LayoutStore';
import { PATHS } from '../../constants';

const connect = createConnector(React);

function DomainRow({ domain, onClick }) {
  function onClickEdit() {
    onClick(domain);
  }

  return (
    <div className={`domain domain-${domain}`} key={domain}>
      <div className="domain-row">
        <div className="domain-row-name">{domain}</div>
        <div className="buttons">
          <Button type="primary" onClick={onClickEdit}>
          Edit
          </Button>
          <Button type="link" onClick={() => { navigateTo(`users/new/?domainId=${domain}`); }}>
          Add User
          </Button>
        </div>
      </div>
      <Divider className="row-divider" />
    </div>
  );
}

@connect(DomainsManagementStore)
@connect(DomainSettingsPageStore)
@connect(AuthStore)
@connect(LayoutStore)
class DomainsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      jwtObject: {},
    };

    api.DomainsManagement.onLoadDomains();
  }

  componentDidMount() {
    api.LayoutPage.onSetShowSideBar(false);
  }

  onClickEdit(id) {
    api.DomainSettingsPage.onSetManagedDomainId(id);
    navigateTo(`/domain/?domainId=${id}`);
  }

  renderDomains() {
    return (
      <Card title="Domains" className="domain-list">
        <Button type="secondary" className="add-domain" onClick={() => { navigateTo(PATHS.DOMAINS_NEW); }}>
          Create Domain
        </Button>
        <div className="list-items">
          {
            this.state.domains.map((domain) => (
              <DomainRow
                key={domain.id}
                domain={domain.id}
                onClick={this.onClickEdit}
              />
            ))
          }
        </div>
        <Button className="domains-back-btn" type="link" icon="left" onClick={() => { navigateTo(PATHS.LANDING); }}>Back</Button>
      </Card>
    );
  }

  render() {
    if (!this.state.jwtObject) {
      return <Redirect to={PATHS.LOGIN} />;
    }
    return (
      <div className="DomainsManagement content-wrapper">
        <div className="container">
          <div className="ant-row justify-content-center">
            <div className="ant-col-10">
              {this.renderDomains()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { DomainsManagement };
