import { createAPI } from 'cartiv';

const instance = createAPI();


/**
 * Trigger #onApplicationDomainChange on implementing APIs
 * Action onApplicationDomainChange is a method used to trigger changes
 * when domain dependent data becomes obsolete
 */
instance.broadcastDomainChange = () => {
  Object.values(instance).forEach((apiInstance) => {
    const { onApplicationDomainChange } = apiInstance;

    if (onApplicationDomainChange) {
      onApplicationDomainChange();
    }
  });
};


export default instance;
