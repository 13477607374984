import { StringInput } from './StringInput';

import GroupInput from './GroupInput';

export { BooleanInput } from './BooleanInput';
export { ImageInput } from './ImageInput';
export { StringInput } from './StringInput';
export { GroupInput };
export { DateInput } from './DateInput';
export { TextInput } from './TextInput';
export { RichTextInput } from './RichTextInput';
export { RawInput } from './RawInput';
export { ArrayInput } from './ArrayInput';
export { SelectInput } from './SelectInput';
export { MultiSelectInput } from './MultiSelectInput';
export { MultiPageInput } from './MultiPageInput';
export { TagsInput } from './TagsInput';
export { ColorInput } from './ColorInput';
export { ReferenceInput } from './ReferenceInput';

// export const SectionInput = GroupInput;
export const PasswordInput = StringInput;
export const NumberInput = StringInput;
export const HTMLInput = StringInput;
export const EmailInput = StringInput;
export const TelInput = StringInput;
export const URLInput = StringInput;
