import { createStore } from 'cartiv';
import api from '../../services/api';
import backend from '../../services/api/BackendApi';

import { genericErrorHandler } from '../../utils/Common';
import { AuthStore } from '../../stores/AuthStore';
import { UserStore } from '../../stores/UserStore';
import { navigateTo } from '../../services/history';
import { CollectionTypesStore } from '../../pages/collection/CollectionTypesStore';
import { PATHS } from '../../constants';

export const DomainSelectorStore = createStore({
  api,
  name: 'DomainSelector',
}, {
  getInitialState() {
    return {
      domains: [],
      domain: null,
      domainInfo: null,
    };
  },
  onLoadUserDomains() {
    this.setState(this.getInitialState());
    backend.get('/account/domains')
      .then((domains) => {
        this.setState({
          domains,
        });
      })
      .catch(genericErrorHandler);
  },
  onSelectDomain(domain, cb) {
    CollectionTypesStore.setLoading(true);

    backend.get(`/account/domains/${domain}/token`)
      .then((tokenWrapper) => {
        AuthStore.initFromToken(tokenWrapper.value);
        UserStore.onLoadAccount(AuthStore.onGetStateObject(tokenWrapper.value).jwtObject.userId);

        this.setState({ domain });

        if (domain === 'root') {
          navigateTo(PATHS.DOMAINS_MANAGE);
        }
        this.onGetDomainInformation();

        if (cb) {
          cb();
        }
        navigateTo(PATHS.LANDING);
      })
      .catch(genericErrorHandler);
  },
  onGetDomainInformation() {
    backend.get('/domains/current')
      .then((domainInfo) => {
        this.setState({ domainInfo });
      })
      .catch(genericErrorHandler);
  },
  onClearDomain() {
    this.setState({
      domains: [],
      domain: null,
      domainInfo: null,
    });
    navigateTo(PATHS.LOGIN);
  },
});
