import { createStore } from 'cartiv';
import api from '../../services/api';

export const ModalsStore = createStore({
  api: api,
  name: 'Modals',
}, {
  getInitialState() {
    return {
      modals: [],
    };
  },
  onShowModal(modal) {
    modal.id = modal.id || Math.random();
    const modals = this.state.modals.slice();
    modals.push(modal);
    this.setState({modals});
  },
  onCloseModal(id) {
    this.setState({modals: this.state.modals.filter(m => m.id !== id)});
  },
});
