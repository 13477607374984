import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { clone } from '../../lib/clone';
import { genericErrorHandler } from '../../utils/Common';
import {
  addTitleFields,
  getConvertedSpecsFromArrayIntoObject,
  getConvertedSpecsFromObjectIntoArray,
  getTitleFields,
} from '../../lib/converters';

export const AccountSettingsStore = createStore({
  api,
  name: 'AccountSettings',
}, {
  getInitialState() {
    return {
      customRoles: [],
      accountSettings: {},
      accountSettingsListUserDataSpec: {},
    };
  },
  onLoadAccountSettings() {
    backend.get('/account/settings').then((result) => {
      this.setState({
        accountSettings: result,
      });
    }).catch(genericErrorHandler);
  },
  onLoadAccountSettingsListUserDataSpec() {
    backend.get('/account/settings').then((result) => {
      const tempResult = result;
      if (!!tempResult.userData && !!tempResult.userData.spec) {
        tempResult.userDataSpec = getConvertedSpecsFromObjectIntoArray(result.userData.spec);
        if (tempResult.userData.titleFields) {
          tempResult.userDataSpec = addTitleFields(tempResult.userDataSpec, result.userData.titleFields);
        }
      }

      this.setState({
        accountSettingsListUserDataSpec: tempResult,
        customRoles: result.customAuthorities,
      });
    }).catch(genericErrorHandler);
  },
  onUpdateAccountSettings(accountData) {
    const tempAccountData = clone(accountData);
    tempAccountData.userData = {};
    tempAccountData.userData.spec = getConvertedSpecsFromArrayIntoObject(accountData.userDataSpec);
    tempAccountData.userData.titleFields = getTitleFields(accountData.userDataSpec);
    tempAccountData.customAuthorities = tempAccountData.customRoles; // map front end role to backend authority

    const options = {
      data: tempAccountData,
    };
    backend.put('/account/settings', options).then((result) => {
      notification.success({ message: 'Account settings saved' });
    }).catch(genericErrorHandler);
  },
});
