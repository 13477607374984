import React from 'react';
import { Link } from 'react-router-dom';
import style from './NotFoundPage.scss';
import { PATHS } from '../../constants';

export class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.style = style;
  }

  render() {
    return (
      <div className="NotFoundPage page-body-wrapper">
        <div className="content-wrapper d-flex align-items-center text-center error-page bg-primary">
          <div className="ant-row flex-grow">
            <div className="col-lg-7 mx-auto text-white">
              <div className="ant-row align-items-center d-flex flex-row">
                <div className="col-lg-6 text-lg-right pr-lg-4">
                  <h1 className="display-1 mb-0">404</h1>
                </div>
                <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>SORRY!</h2>
                  <h3 className="font-weight-light">The page you’re looking for was not found.</h3>
                </div>
              </div>
              <div className="ant-row mt-5">
                <div className="col-12 text-center mt-xl-2">
                  <Link to={PATHS.LANDING} className="text-white font-weight-medium">Back to home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
