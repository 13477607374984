import React from 'react';
import { createConnector } from 'cartiv';
import styled from 'styled-components';
import { config } from '../../config/index';
import { I18nStore } from '../i18n/i18nStore';
import { Link } from 'react-router-dom';
import { Row } from 'antd';

const connect = createConnector(React);

@connect(I18nStore)
class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        const { className } = this.props;

        const year = new Date().getFullYear();

        let environmentName = config.backendUrl.split('.')[1]?.toUpperCase() || 'UNKNOWN';
        let FEVersion = window._version;

        return (
            <Row type="flex" justify="end" className={className}>
                <div>
                    <Link to="//justrocket.de" target="_blank">JUSTROCKET</Link> © {year}.
                    <div className="env-version">
                        {`${environmentName} ${FEVersion && `UI: ${FEVersion}` }`}
                    </div>
                </div>
            </Row>
        );
    }
}


export default styled(Footer)`
  .env-version {
    font-size: ${ ({ theme }) => theme.font.size.sm };
  }
`;
