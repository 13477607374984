import React from 'react';
import PasswordActionErrorCard from './PasswordActionErrorCard';


export default (props) => (
  <PasswordActionErrorCard
    {...props}
    title="Failed to reset your password"
    description="Please try again later or, if the problem persists, contact support."
  />
);
