import React from 'react';
import { RichTextEditor } from '../../../components/RichTextEditor/RichTextEditor';
import './SelectInput.scss';
import { Select } from 'antd';

export class RichTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'en',
    };
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeLanguage(value) {
    this.setState({
      lang: value,
    });
  }


  onChangeValue(e) {
    const value = e;
    let multiValue = this.props.value;
    if (this.props.entityDefinition.i18n) {
      if (!multiValue || !multiValue._i18n) {
        multiValue = {
          _i18n: {},
        }
      }
      multiValue['_i18n'][this.state.lang] = value;
      this.props.onChange(multiValue);
    } else {
      this.props.onChange(value);
    }
  }

  render() {
    const value = typeof this.props.value !== 'undefined' && this.props.value !== null ?
      this.props.value : this.props.defaultValue;

    return (
      <div className={`${this.props.name} form-group ${this.props.fieldClasses.groupClass}`}>
        <div className={`${this.props.entityDefinition.i18n ? 'ant-col-3' : this.props.fieldClasses.labelClass}`}>
          <label
            className={`form-control-label`}
            htmlFor={`${this.props.name}-form-control`}>
            {this.props.entityDefinition.label}
          </label>
        </div>
        {this.props.entityDefinition.details ?
          <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null
        }
        <div
          className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
          <RichTextEditor
            id={`${this.props.name}-form-control`}
            name={this.props.name}
            value={this.props.entityDefinition.i18n && value._i18n ? value['_i18n'][this.state.lang] : value}
            className={`${this.props.name} form-control ${this.props.fieldClasses.inputClass}`}
            placeholder={this.props.entityDefinition.placeholder}
            brandColors={this.props.brandColors}
            onChange={this.onChangeValue}
          />
          {this.props.entityDefinition.i18n &&
          <Select
            className={'select-language'}
            onChange={this.onChangeLanguage}
            value={this.state.lang}
          >
            {this.props.languages.map(lang =>
              <Select.Option value={lang} key={lang}>{lang}</Select.Option>)}
          </Select>}
        </div>
      </div>
    );
  }
}

RichTextInput.defaultProps = {
  value: '',
  defaultValue: '',
  languages: ['en'],
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: {},
  onChange: (v) => console.log(v),
};

