import React from 'react';
import { createConnector } from 'cartiv';

import { style } from './Registration.scss';
import api from '../../services/api';
import { RegistrationStore } from './RegistrationStore';
import GroupInput from '../../pages/genericEditor/FormControls/GroupInput';
import { I18nStore } from '../i18n/i18nStore';
import { parse } from '../../lib/query-string';
import { EmbeddableStore } from '../../stores/EmbeddableStore';

const connect = createConnector(React);

@connect(RegistrationStore)
@connect(I18nStore)
@connect(EmbeddableStore)
class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: {Registration: {}},
      email: '',
      password: '',
      firstName: '',
      lastName: '',
    };
    this.dummy = style;
  }

  componentDidMount() {
    api.Embeddable.onEnable();
  }

  componentWillUnmount() {
    api.Embeddable.onDisable();
  }

  register() {
    let user = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      domainId: parse(this.props.location.search).domainId,
      profileIds: this.state.options.signupProfile,
    };
    api.Registration.onRegister(user);
  }

  renderErrorElement(registrationError) {
    return (
      <div className="row error-wrapper">
        <p className="error">{registrationError.title}</p>
      </div>
    );
  }

  render() {
    const errorElement = this.state.registrationError ?
      this.renderErrorElement(this.state.registrationError) : null;
    return (
      <div className="Registration">
        <h1>{this.state.strings.Registration.title}</h1>
        <p className="text-muted">Create an account</p>
        <form onSubmit={e => {
          e.preventDefault();
          this.register();
        }}>
          <GroupInput
            value={{
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              email: this.state.email,
              password: this.state.password,
            }}
            entityDefinition={{
              spec: {
                firstName: 'String',
                lastName: 'String',
                email: this.state.strings.Registration.email,
                password: this.state.strings.Registration.password,
              },
            }}
            name="user"
            onChange={this.setState.bind(this)}
          />
          {errorElement}
          <div className="ant-row">
            <div className="col-6">
              <button type="submit" className="submit btn btn-primary px-4"
              >{this.state.strings.Registration.action}</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { Registration };

