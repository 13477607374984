import React from 'react';
import styled from 'styled-components';

const TwoRowsCell = ({ upperText, lowerText, className }) => (
  <div className={className}>
    <div className="upper-inner-row">{upperText}</div>
    <div className="lower-inner-row">{lowerText}</div>
  </div>
);

export default styled(TwoRowsCell)`
  & > .lower-inner-row {
      font-size: 10px;
      font-weight: 700
  }
`;
