import { createStore } from 'cartiv';
import api from '../../services/api';

export const DraggerStore = createStore({
  api,
  name: 'Dragger',
}, {
  getInitialState() {
    return {
      dragging: false,
      dragStartIndex: -1,
      dropIndex: -1,
      pageX: -1,
      pageY: -1,
      draggedItem: null,
    };
  },
  onStartDrag(options = {}) {
    options.event.preventDefault();
    options.item = options.item || 'Drag me!';
    options.markerSelector = options.markerSelector || '.GenericEntityTable .entity-item';
    options.dropSides = options.dropSides || 'vertical';
    const allElements = document.querySelectorAll(options.markerSelector);
    const allRects = Array.prototype.map.call(allElements, el => el.getBoundingClientRect());
    const dragStartIndex = options.startIndex || -1;
    document.body.classList.add('Dragging');
    this.setState({
      dragging: true,
      dragStartIndex,
      pageX: options.event.pageX,
      pageY: options.event.pageY,
      dropIndex: dragStartIndex,
      draggedItem: options.item,
    });
    const moveListener = function (e) {
      let dropIndex = -1;
      allRects.forEach((rectangle, idx) => {
        if (rectangle.left < e.pageX && rectangle.right > e.pageX &&
          rectangle.top < e.pageY && rectangle.bottom > e.pageY
        ) {
          dropIndex = idx + 1;
          if (options.dropSides === 'vertical') {
            if (e.pageY < rectangle.top + rectangle.height / 2) {
              dropIndex -= 1;
            }
          }
        }
      });
      this.setState({
        dropIndex,
        pageX: e.pageX,
        pageY: e.pageY,
      });
    }.bind(this);
    const upListener = function () {
      document.removeEventListener('mousemove', moveListener);
      document.removeEventListener('mouseup', upListener);
      document.body.classList.remove('Dragging');
      if (this.state.dropIndex > -1) {
        const dropIndex = this.state.dropIndex;
        setTimeout(() => {
          if (dropIndex === dragStartIndex) {
            return;
          }
          options.droppedAtIndex(dropIndex);
        });
      }
      this.setState(this.getInitialState());
    }.bind(this);
    document.addEventListener('mousemove', moveListener);
    document.addEventListener('mouseup', upListener);
  },
});
