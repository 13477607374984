import React from 'react';
import { Button, Icon } from 'antd';
import styled from 'styled-components';
import { parse } from 'query-string';
import api from '../../services/api';
import { passwordCardStyling } from './PasswordResetRequestCard';
import CardWithLogoInTitle from '../PasswordSet/CardWithLogoInTitle';


const PasswordActionErrorCard = ({
  className, title, description, location,
}) => {
  const onBackToLogin = () => {
    const { domainId } = parse(location.search);

    api.Login.onBackToLogin(domainId);
  };

  return (
    <CardWithLogoInTitle title={title} className={className}>
      <Icon type="close-circle" className="error-icon" />
      <span>{description}</span>
      <Button type="link" onClick={onBackToLogin} className="back-to-login-button">
        <Icon type="left" />
        Back to login
      </Button>
    </CardWithLogoInTitle>
  );
};

export default styled(PasswordActionErrorCard)`
  ${passwordCardStyling}

  .ant-card-body {
    .error-icon {
      svg {
        height: 80px;
        width: 80px;
        margin-bottom: ${({ theme }) => theme.space.md};
      }
    }

    .back-to-login-button {
      margin-top: ${({ theme }) => theme.space.xs};
    }
  }
`;
