import React from 'react';
import {
  Button, Form, Icon,
} from 'antd';
import styled from 'styled-components';
import { createConnector } from 'cartiv';
import { parse } from 'query-string';
import api from '../../services/api';
import { passwordCardStyling } from './PasswordResetRequestCard';
import { LoginStore } from '../Login/LoginStore';
import PasswordInputForm from './PasswordInputForm';
import { navigateTo } from '../../app/AppRoutes';
import { PATHS } from '../../constants';
import { AuthStore } from '../../stores/AuthStore';
import { ApplicationPublicDataStore } from '../../stores/ApplicationPublicDataStore';
import CardWithLogoInTitle from '../PasswordSet/CardWithLogoInTitle';


const connect = createConnector(React);


@connect(AuthStore)
@connect(LoginStore)
@connect(ApplicationPublicDataStore)
class PasswordChangeInputCard extends React.Component {
  constructor(props) {
    super(props);

    const { email, domainId, profileUrl } = parse(window.location.search);

    api.ApplicationPublicDataStore.onLoadData(domainId);

    if (email && domainId) {
      this.state = { email, domainId, profileUrl };
    } else {
      const currentUserEmail = AuthStore.getEmail();
      const currentUserDomainId = AuthStore.getDomainId();

      if (currentUserEmail && currentUserDomainId) {
        this.state = { email: currentUserEmail, domainId: currentUserDomainId, profileUrl };
      } else {
        navigateTo(PATHS.PASSWORD_CHANGE_ERROR);
      }
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.showWrongPasswordError = this.showWrongPasswordError.bind(this);
    this.onBackToProfile = this.onBackToProfile.bind(this);
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { email, domainId } = this.state;
    const { form } = this.props;
    const { validateFields } = form;
    validateFields(['password', 'current-password'])
      .then(({ 'current-password': currentPassword, password: newPassword }) => {
        validateFields(['repeat-password'])
          .then(() => {
            api.Login.onChangePassword(email, domainId, currentPassword, newPassword, this.showWrongPasswordError);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  onBackToProfile = () => {
    const { profileUrl } = this.state;

    window.location.href = profileUrl;
  }

  passwordForm = () => {
    const { form } = this.props;
    const { loading } = this.state;

    return (
      <PasswordInputForm type="change" onSubmit={this.onSubmit} form={form} loading={loading} />
    );
  };

  showWrongPasswordError(errorMessage) {
    const { form } = this.props;
    const { setFields, getFieldValue } = form;

    setFields({
      'current-password': {
        value: getFieldValue('current-password'),
        errors: [new Error(errorMessage)],
      },
    });
  }

  render() {
    const { className } = this.props;
    const { profileUrl } = this.state;

    return (
      <CardWithLogoInTitle title="Change Password" className={className}>
        {this.passwordForm()}
        {profileUrl && (
          <Button type="link" onClick={this.onBackToProfile}>
            <Icon type="left" />
            Back to profile
          </Button>
        )}
      </CardWithLogoInTitle>
    );
  }
}

const PasswordChangeInputForm = Form.create()(PasswordChangeInputCard);
export default styled(PasswordChangeInputForm)`
  ${passwordCardStyling}
`;
