import React from 'react';
import { Input, Select } from 'antd';
import './StringInput.scss';

const TYPE_TO_INPUT_TYPE_MAP = {
  Password: 'password',
  Email: 'email',
  Tel: 'tel',
  Number: 'number',
};

export class StringInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      lang: 'en',
    };
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChange(e) {
    const { value } = e.target;
    const valid = this.validate(value);
    this.setState({ value });
    this.props.onChange({ value, valid });
  }

  onChangeValue(e) {
    const { value } = e.target;
    let multiValue = this.props.value;
    if (this.props.entityDefinition.i18n) {
      // Handle case when the value is not yet set, or not i18n format (loses old value..)
      if (!multiValue || !multiValue._i18n) {
        multiValue = {
          _i18n: {},
        };
      }
      multiValue._i18n[this.state.lang] = value;
      this.props.onChange(multiValue);
      return;
    }
    this.props.onChange(value);
  }

  onChangeLanguage(value) {
    this.setState({
      lang: value,
    });
  }

  validate(value) {
    let valid = null;
    if (this.props.validator) {
      valid = !!value && this.props.validator(value);
      this.setState({ valid });
    }
    return valid;
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.forceValidation && newProps.forceValidation) {
      this.validate(this.props.value);
    }
  }

  render() {
    const value = typeof this.props.value === 'undefined' || this.props.value === null
      ? this.props.defaultValue
      : this.props.entityDefinition.i18n
        ? !!this.props.value._i18n && !!this.props.value._i18n[this.state.lang]
          ? this.props.value._i18n[this.state.lang]
          : this.props.defaultValue
        : this.props.value;

    const groupClass = `form-group ${this.props.fieldClasses.groupClass}`;
    const hasValueClass = value ? 'has-value' : 'empty';
    const focusClass = this.state.focused ? 'focused' : 'blurred';

    return (
      <div
        className={`${this.props.name} StringInput ${groupClass} ${hasValueClass} ${focusClass}`}
      >
        <div
          className={`${this.props.fieldClasses.labelClass} label-wrapper`}
        >
          <label
            className="form-control-label"
            htmlFor={`${this.props.name}-form-control`}
          >
            {this.props.entityDefinition.label}
            {this.props.required ? <span className="required">*</span> : null}
          </label>
        </div>
        <div
          className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass} ${this.props.entityDefinition.i18n ? 'i18n' : ''}`}
        >
          <Input
            type={TYPE_TO_INPUT_TYPE_MAP[this.props.entityDefinition._type] || 'text'}
            name={this.props.name}
            id={`${this.props.name}-form-control`}
            className={`${this.props.name} form-control ${this.props.fieldClasses.inputClass}`}
            placeholder={this.props.entityDefinition.placeholder}
            // onFocus={e => this.setState({focused: true})}
            // onBlur={e => this.setState({focused: false})}
            value={value}
            onChange={this.onChangeValue}
          />
          {this.state.valid ? null
            : (
              <small
                className="validationMessage"
              >
                {this.props.validationMessage || `Please supply a valid ${this.props.entityDefinition.label}`}
              </small>
            )}
          {this.props.entityDefinition.details
            ? <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null}
          {this.props.entityDefinition.i18n
          && (
            <Select
              className="select-language"
              onChange={this.onChangeLanguage}
              value={this.state.lang}
            >
              {this.props.languages.map((lang) => <Select.Option value={lang} key={lang}>{lang}</Select.Option>)}
            </Select>
          )}
        </div>
      </div>
    );
  }
}

StringInput.defaultProps = {
  value: '',
  defaultValue: '',
  languages: ['en'],
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  placeholder: '',
  onChange: (v) => console.log(v),
};
