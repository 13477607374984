import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { AnalyticsStore } from './lib/AnalyticsStore';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

AnalyticsStore.init();
