import React from 'react';
import { createConnector } from 'cartiv';
import {
  Button, Card, Divider, Modal,
} from 'antd';
import { merge } from 'lodash';
import api from '../../services/api';
import { AuthStore } from '../../stores/AuthStore';
import { GroupStore } from './GroupStore';
import { navigateTo } from '../../services/history';
import Table from '../../components/table/Table';
import RowButton from '../../components/table/action/RowButton';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import TwoRowsCell from '../../components/table/cell/TwoRowsCell';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(GroupStore)
@connect(AuthStore)
class GroupList extends React.Component {
  groupColumns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (text, record, idx) => (
      <TwoRowsCell upperText={record.name} lowerText={record.groupId} />
    ),
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    sorter: true,
  }, {
    title: 'Public',
    dataIndex: 'allowSignup',
    key: 'allowSignup',
    render: (text, record, idx) => <span>{record.allowSignup ? 'Yes' : 'No' }</span>,
  }, {
    title: 'Actions',
    key: 'actions',
    render: function (text, record) {
      return (
        <span>
          <RowButton
            icon="edit"
            onClick={() => this.goToGroup(record)}
          />
          <Divider type="vertical" />
          <RowButton
            icon="delete"
            onClick={() => { this.confirmDelete(record); }}
          />
        </span>
      );
    }.bind(this),
  }];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.query({});
  }

  confirmDelete = ({ groupId, name }) => {
    Modal.confirm({
      title: 'Confirmation required',
      content: `Are you sure you want to delete '${name}'`,
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => api.Group.onDeleteGroup(groupId),
    });
  };

  goToGroup = ({ groupId }) => {
    navigateTo(`${PATHS.ACCOUNTS_GROUPS_EDIT}/${groupId}`);
  };

  query({
    page = 0, pageSize = DEFAULT_ROW_NR, sortField = 'lastModifiedDate', sortOrder = 'DESC',
  }) {
    api.Group.onLoadPaginated({
      fieldName: sortField,
      direction: sortOrder,
      size: pageSize,
      page,
    });
  }

  render() {
    const {
      groups: groupData, totalElements, lastPagination, groupsLoading,
    } = this.state;

    const pagination = merge({
      total: totalElements,
      onChange: this.query,
    }, {
      pageSize: lastPagination ? lastPagination.pageSize : undefined,
      current: lastPagination ? lastPagination.page : undefined,
    });

    return (
      <Card
        title="Groups"
        extra={(
          <Button type="primary" size="large" icon="plus" onClick={() => navigateTo(PATHS.ACCOUNTS_GROUPS_NEW)}>
              New group
          </Button>
          )}
      >
        <Table
          data={groupData}
          columns={this.groupColumns}
          onChange={this.query}
          paginator={pagination}
          loading={groupsLoading}
        />
      </Card>
    );
  }
}

export { GroupList };
