import React from 'react';
import PasswordActionSuccessfulCard from './PasswordActionSuccessfulCard';


export default () => (
  <PasswordActionSuccessfulCard
    title="Password reset successfully"
    description="Logging you in..."
  />
);
