import React from 'react';
import { createConnector } from 'cartiv';
import { Button, Card, Collapse } from 'antd';
import style from './AccountSettingsPage.scss';
import api from '../../services/api';
import { clone } from '../../lib/clone';
import { AuthStore } from '../../stores/AuthStore';
import { AccountSettingsStore } from './AccountSettingsStore';
import { GroupInput, StringInput } from '../genericEditor/FormControls';
import { EntityDefinitionEditor } from '../entityDefinitionEditor/EntityDefinitionEditor';
import GenericBackAndSubmit from '../../components/GenericFooter/GenericBackAndSubmit';

const connect = createConnector(React);

@connect(AccountSettingsStore)
@connect(AuthStore)
class AccountSettingsEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.style = style;

    this.submitForm = this.submitForm.bind(this);
    this.onChangeUserData = this.onChangeUserData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangePasswordResetUrl = this.onChangePasswordResetUrl.bind(this);
    this.onChangeSuccessfulResetUrl = this.onChangeSuccessfulResetUrl.bind(this);
  }

  componentDidMount() {
    api.AccountSettings.onLoadAccountSettingsListUserDataSpec();
  }

  submitForm() {
    const tempValue = {
      ...this.state.accountSettingsListUserDataSpec,
      customRoles: this.state.customRoles || [],
    };
    if (this.entityRef) {
      tempValue.userDataSpec = this.entityRef.getSpec();
    }
    if (!tempValue.customPasswordResetUrl) {
      tempValue.passwordResetUrl = '';
    }
    if (!tempValue.customSuccessfulResetUrl) {
      tempValue.successfulResetUrl = '';
    }
    tempValue.customRoles = this.state.customRoles;
    api.AccountSettings.onUpdateAccountSettings(tempValue);
  }

  onChangeUserData(e) {
    const tempState = clone(this.state);
    tempState.accountSettings.userDataSpec = e;
    this.setState(tempState);
  }

  onChange(e) {
    this.setState({ value: e });
  }

  onChangePasswordResetUrl(e) {
    const tempValue = clone(this.state.accountSettingsListUserDataSpec);
    tempValue.passwordResetUrl = e;
    this.setState({ accountSettingsListUserDataSpec: tempValue });
  }

  onChangeSuccessfulResetUrl(e) {
    const tempValue = clone(this.state.accountSettingsListUserDataSpec);
    tempValue.successfulResetUrl = e;
    this.setState({ accountSettingsListUserDataSpec: tempValue });
  }

  onCustomRolesChanged = ({ customRoles }) => {
    this.setState({ customRoles });
  };

  render() {
    const {
      value, customRoles, accountSettingsListUserDataSpec, userDataSpec,
    } = this.state;

    const oAuth2Available = ((!!accountSettingsListUserDataSpec && accountSettingsListUserDataSpec.signupAllowed)
      || (!!value && value.signupAllowed));

    return (
      <Card title="Account settings">
        <form className="card-body" onSubmit={(e) => e.preventDefault()}>

          <Collapse bordered={false} defaultActiveKey={['general']}>
            <Collapse.Panel header="General Settings" key="general">
              <GroupInput
                name="domain"
                entityDefinition={{
                  spec: {
                    adminApproveRequired: 'Boolean',
                    signupAllowed: 'Boolean',
                    autoActivationAllowed: 'Boolean',
                  },
                }}
                value={accountSettingsListUserDataSpec || value}
                onChange={this.onChange}
                fieldClasses={{
                  groupClass: 'ant-row',
                  labelClass: 'ant-col-6',
                  inputClass: '',
                  inputWrapperClass: 'ant-col-18',
                }}
              />
            </Collapse.Panel>
            {oAuth2Available
            && (
              <Collapse.Panel header="OAuth2 Integrations" key="oauth2">
                <GroupInput
                  name="social"
                  entityDefinition={{
                    spec: {
                      authProviders: {
                        _type: 'Array',
                        elementType: 'Object',
                        spec: {
                          appId: 'String',
                          appSecret: 'String',
                          authUrl: 'String',
                          providerId: 'String',
                          tokenMethod: {
                            _type: 'Select',
                            options: ['POST', 'GET'],
                          },
                          tokenUrl: 'String',
                          signupProfileId: {
                            _type: 'String',
                            details: 'The Profile has to exist; Use $.someField notation to reference data in the oauth2 provider\'s user object. If left empty these values need to be provided by the client.',
                          },
                          signupGroupId: {
                            _type: 'String',
                            details: 'The Group has to exist; Use $.someField notation to reference data in the oauth2 provider\'s user object. If left empty these values need to be provided by the client.',
                          },
                          logoUrl: 'Image',
                          redirectUrl: 'String',
                          responseType: {
                            _type: 'Select',
                            options: ['code', 'token id_token'],
                          },
                          scope: 'String',
                        },
                      },
                    },
                  }}
                  value={accountSettingsListUserDataSpec || value}
                  onChange={this.onChange}
                  fieldClasses={{
                    groupClass: 'ant-row',
                    labelClass: 'ant-col-6',
                    inputClass: '',
                    inputWrapperClass: 'ant-col-18',
                  }}
                />
              </Collapse.Panel>
            )}
            <Collapse.Panel header="Password Reset Settings" key="password-reset">
              <GroupInput
                name="password-reset"
                entityDefinition={{
                  spec: {
                    customPasswordResetUrl: {
                      _type: 'Boolean',
                      disabled: (!!accountSettingsListUserDataSpec
                        && accountSettingsListUserDataSpec.customSuccessfulResetUrl)
                        || (!!value && value.customSuccessfulResetUrl),
                    },
                    customSuccessfulResetUrl: {
                      _type: 'Boolean',
                      disabled: (!!accountSettingsListUserDataSpec
                        && accountSettingsListUserDataSpec.customPasswordResetUrl)
                        || (!!value && value.customPasswordResetUrl),
                    },
                  },
                }}
                value={accountSettingsListUserDataSpec || value}
                onChange={this.onChange}
                fieldClasses={{
                  groupClass: 'ant-row',
                  labelClass: 'ant-col-6',
                  inputClass: '',
                  inputWrapperClass: 'ant-col-18',
                }}
              />
              {((!!accountSettingsListUserDataSpec && accountSettingsListUserDataSpec.customPasswordResetUrl)
                || (!!value && value.customPasswordResetUrl))
              && (
                <StringInput
                  name="passwordResetUrl"
                  entityDefinition={{
                    label: 'Password Reset Url',
                    placeholder: userDataSpec ? userDataSpec.passwordResetUrl : 'Default: https://jap.app.justrocket.cloud/password-reset/change-password/${TOKEN}',
                  }}
                  value={accountSettingsListUserDataSpec.passwordResetUrl}
                  onChange={this.onChangePasswordResetUrl}
                />
              )}
              {((!!accountSettingsListUserDataSpec && accountSettingsListUserDataSpec.customSuccessfulResetUrl) || (!!value && value.customSuccessfulResetUrl))
              && (
                <StringInput
                  name="passwordResetUrl"
                  entityDefinition={{
                    label: 'Password Reset Url',
                    placeholder: userDataSpec ? userDataSpec.successfulResetUrl : 'Default: https://${DOMAIN}/password-reset-success?loginToken=${loginToken}',
                  }}
                  value={accountSettingsListUserDataSpec.successfulResetUrl}
                  onChange={this.onChangeSuccessfulResetUrl}
                />
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Additional user data" key="user-data">
              <EntityDefinitionEditor
                ref={(entityDef) => this.entityRef = entityDef}
                spec={accountSettingsListUserDataSpec ? accountSettingsListUserDataSpec.userDataSpec : null}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Custom roles" key="custom-Roles">
              <GroupInput
                value={{ customRoles }}
                entityDefinition={{
                  spec: {
                    customRoles: {
                      _type: 'Array',
                      label: 'Custom Roles',
                      spec: {
                        name: 'String',
                        description: 'String',
                      },
                    },
                  },
                }}
                onChange={this.onCustomRolesChanged}
              />
            </Collapse.Panel>
          </Collapse>
          <GenericBackAndSubmit onSave={this.submitForm} />
        </form>
      </Card>
    );
  }
}

export { AccountSettingsEditor };
