import React from 'react';
import { Button } from 'antd';

const RowButton = ({
  label, icon, shape, size, type, onClick,
}) => (
  <Button
    icon={icon}
    shape={shape}
    size={size || 'small'}
    type={type || 'link'}
    onClick={onClick}
  >
    {label}
  </Button>
);


export default RowButton;
