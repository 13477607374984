import { createStore } from 'cartiv';
import api from '../../services/api';

export const LayoutStore = createStore({
  api,
  name: 'LayoutPage',
},
{
  getInitialState() {
    return {
      showSideBar: true,
    };
  },
  onSetShowSideBar(show) {
    this.setState({ showSideBar: show });
  },
});
