import React from 'react';
import { createConnector } from 'cartiv';
import { Button, Card, Icon } from 'antd';
import styled from 'styled-components';
import api from '../../services/api';
import { I18nStore } from '../../components/i18n/i18nStore';
import { LayoutStore } from '../../components/Layout/LayoutStore';
import { navigateTo } from '../../services/history';
import { PATHS } from '../../constants';

const connect = createConnector(React);


@connect(I18nStore)
@connect(LayoutStore)
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    api.LayoutPage.onSetShowSideBar(true);
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <div className="container">
          <div className="ant-row">
            <div className="ant-col-7">
              <Card title={(
                <span>
                  <Icon type="appstore" />

                  Collections
                </span>
              )}
              >
                <ol>
                  <li>Create Data Types</li>
                  <li>Define Access Level</li>
                  <li>Fill with data</li>
                </ol>
                <div className="actions">
                  <Button onClick={() => navigateTo(PATHS.COLLECTION_TYPES)}>
                    Create Type
                  </Button>
                </div>
              </Card>
            </div>
            <div className="ant-col-7">
              <Card title={(
                <span>
                  <Icon type="setting" />

                  Account Settings
                </span>
              )}
              >
                <ol>
                  <li>Define user fields</li>
                  <li>Add OAuth2 Integrations</li>
                  <li>Specify App-specific roles</li>
                </ol>
                <div className="actions">
                  <Button onClick={() => navigateTo(PATHS.ACCOUNTS_SETTINGS)}>
                    Edit settings
                  </Button>
                </div>
              </Card>
            </div>
            <div className="ant-col-7">
              <Card title={(
                <span>
                  <Icon type="mail" />

                  Email templates
                </span>
              )}
              >
                <ol>
                  <li>Design email temaplates</li>
                  <li>Specify placeholders</li>
                  <li>Change SMTP server settings</li>
                </ol>
                <div className="actions">
                  <Button onClick={() => navigateTo(PATHS.EMAILS_TEMPLATES)}>
                    View templates
                  </Button>
                </div>
              </Card>
            </div>
          </div>
          <div className="ant-row">
            <div className="ant-col-7">
              <Card title={(
                <span>
                  <Icon type="solution" />
                  Groups & Profiles
                </span>
              )}
              >
                <ol>
                  <li>Use groups to segregate data</li>
                  <li>Use profiles to group service roles that control user access to data</li>
                </ol>
                <div className="actions">
                  <Button onClick={() => navigateTo(PATHS.ACCOUNTS_PROFILES)}>
                    View Profiles
                  </Button>
                </div>
              </Card>
            </div>
            <div className="ant-col-7">
              <Card title={(
                <span>
                  <Icon type="team" />
                  Users
                </span>
              )}
              >
                <ol>
                  <li>View users</li>
                  <li>Manually add / invite</li>
                  <li>Control access level of users</li>
                </ol>
                <div className="actions">
                  <Button onClick={() => navigateTo(PATHS.ACCOUNTS_USERS)}>
                    List users
                  </Button>
                </div>
              </Card>
            </div>
            <div className="ant-col-7">
              <Card title={(
                <span>
                  <Icon type="tag" />
                  Custom App Token
                </span>
              )}
              >
                <ol>
                  <li>Create custom profile</li>
                  <li>Set expiry rule</li>
                  <li>Generate token</li>
                </ol>
                <div className="actions">
                  <Button onClick={() => navigateTo(PATHS.DOMAIN_TOKENS)}>
                    To generator
                  </Button>
                </div>
              </Card>
            </div>
            <div className="ant-col-7 ant-col-offset-7">
              <Card title={(
                <span>
                  <Icon type="star" />

                  Upcoming features
                </span>
              )}
              >
                <ol>
                  <li>
                    <strong>SB:</strong>

                    Site Builder
                  </li>
                  <li>
                    <strong>&lambda;</strong>

                    Lambdas JS-runner
                  </li>
                  <li>
                    <strong>BSM:</strong>

                    Business State machine
                  </li>
                </ol>
                <p>Want to know more?</p>
                <div className="actions">
                  <Button onClick={() => window.open('mailto:manuel@justrocket.de?subject=JAP Information enquiry')}>
                    Get in contact with us!
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default styled(LandingPage)`
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-col-7 {
    padding: 0 ${({ theme }) => theme.space.xxs};
  }
  
  .ant-card {
    margin-bottom: ${({ theme }) => theme.space.md};
    
    .ant-card-head-title  {
      i {
      margin-right: ${({ theme }) => theme.space.xxs};
      }
    }
    }
    li {
      font-weight: 300;
    }
    .actions {
      justify-content: flex-end;
    }
  }
`;
