import { createStore } from 'cartiv';
import { merge } from 'lodash';
import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';
import backend from '../../services/api/BackendApi';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import { FORBIDDEN } from '../../constants';

export const AuditStore = createStore({
  api,
  name: 'Audit',
}, {
  getInitialState() {
    return {
      success: false,
      loading: false,
      auditData: [],
      totalPages: 0,
      lastPagination: {
        page: 0,
      },
      totalElements: -1,
    };
  },

  onLoadPaginated({
    fieldName, direction, page, size = DEFAULT_ROW_NR,
  }, query) {
    this.setState({ success: false, loading: true });

    const reqParams = merge(this.state.lastPagination, {
      sort: fieldName, order: direction, page, size,
    });

    backend.get('/audit', {
      params: {
        ...reqParams,
        q: query,
      },
      errorHandler: (err) => {
        throw err;
      },
    })
      .then((response) => {
        response && this.setState({
          success: true,
          loading: false,
          auditData: response.content,
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          lastPagination: reqParams,
        });
      }).catch(genericErrorHandler);
  },
});
