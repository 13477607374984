import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as InputComponents from '.';
import { capitalize } from '../../../lib/capitalize';
import { clone } from '../../../lib/clone';


class GroupInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderInput = this.renderInput.bind(this);
  }

  onFieldChange(field, value) {
    const data = this.props.value || {};
    const fieldDef = this.entityDefinition.spec[field];
    if (!fieldDef._virtual) {
      data[field] = value;
    } else {
      Object.keys(fieldDef.spec).forEach((realField) => {
        data[realField] = value[realField];
      });
    }
    this.props.onChange(data);
  }

  renderInput(fieldKey) {
    let fieldDef = this.entityDefinition.spec[fieldKey];
    if (typeof fieldDef === 'string') {
      fieldDef = {
        _type: fieldDef,
        name: fieldKey,
      };
    }
    const { disabled } = fieldDef;
    fieldDef.label = fieldDef.label ? fieldDef.label : capitalize(fieldKey.replace(/([A-Z])/g, ' $1'));
    fieldDef.label = `${this.props.level > 0 ? `${this.props.name}/` : ''}${fieldDef.label}`;

    const inputType = (typeof fieldDef === 'object')
      ? `${fieldDef._type}Input` : `${fieldDef}Input`;
    const InputTag = InputComponents[inputType];

    if (!InputTag) {
      return (
        <div
          key={fieldKey}
          className="alert alert-danger"
        >
          Cannot generate input for
          {' '}
          {fieldKey}
          [
          {inputType}
          ]
        </div>
      );
    }

    const { value } = this.props;
    if (value && fieldDef._virtual && !value[fieldKey]) {
      value[fieldKey] = {};
      Object.keys(fieldDef.spec).forEach((realField) => {
        value[fieldKey][realField] = value[realField];
        delete value[realField];
      });
    }
    const fieldName = `${this.props.name}${!fieldDef._virtual ? `-${fieldKey}` : ''}`;
    const fieldValue = value ? value[fieldKey] : undefined;

    return (
      <InputTag
        name={fieldName}
        key={fieldKey}
        level={this.props.level + 1}
        value={fieldValue}
        entityDefinition={fieldDef}
        fieldClasses={this.props.fieldClasses}
        languages={this.props.languages}
        brandColors={this.props.brandColors}
        referenceEntities={this.props.referenceEntities}
        onChange={(value) => this.onFieldChange(fieldKey, value)}
        disabled={disabled}
      />
    );
  }

  ensureHintedSpec() {
    const updatedDef = clone(this.props.entityDefinition);
    Object.keys(updatedDef.viewHints || []).forEach((hintKey) => {
      const hint = updatedDef.viewHints[hintKey];
      const virtField = {
        _type: hint._type,
        _virtual: true,
        spec: Object.keys(updatedDef.spec)
          .filter((key) => hint.fields.indexOf(key) !== -1)
          .reduce((obj, key) => {
            obj[key] = updatedDef.spec[key];
            delete updatedDef.spec[key];
            return obj;
          }, {}),
      };
      updatedDef.spec[hintKey] = virtField;
    });
    this.entityDefinition = updatedDef;
  }

  render() {
    const { className, level } = this.props;
    this.ensureHintedSpec();
    const inputs = Object.keys(this.entityDefinition.spec)
      .map(this.renderInput);
    const Hx = `h${level + 1}`;

    return (
      <div className={`GroupInput ${className}`}>
        {
          this.entityDefinition.name
            ? <Hx className="group-title">{this.entityDefinition.name}</Hx>
            : null
        }
        {inputs}
      </div>
    );
  }
}

GroupInput.defaultProps = {
  entityDefinition: {},
  level: 0,
  // Don't define the value because then it will be shared among different groups leading
  // to very weird errors
  // value: {}, -- WRONG
  value: undefined,
  name: 'define-a-name',
  className: '',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  referenceEntities: [],
  onChange: (v) => console.warn(`changed ${v}, and nobody is listening`),
};

GroupInput.propTypes = {
  value: PropTypes.any,
  level: PropTypes.number,
  label: PropTypes.string,
  fieldClasses: PropTypes.shape({
    groupClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    inputWrapperClass: PropTypes.string,
  }),
  entityDefinition: PropTypes.shape({}),
  onChange: PropTypes.func,
};


export default styled(GroupInput)`
  margin-bottom: 16px;
  
  .form-group {
    margin: 0px 10px 16px 10px;
  }
`;
