import React from 'react';
import { Button, Modal, Select } from 'antd';
import { BooleanInput, StringInput } from '../genericEditor/FormControls';
import './EntityDefinitionEditor.scss';
import { clone } from '../../lib/clone';
import { getArrayOfRootLeaves } from '../../lib/converters';

const TYPES = [
  { value: 'Array', label: 'Array' },
  { value: 'Boolean', label: 'Boolean' },
  { value: 'Color', label: 'Color' },
  { value: 'Date', label: 'Date' },
  { value: 'Group', label: 'Group' },
  { value: 'Image', label: 'Image' },
  { value: 'MultiSelect', label: 'MultiSelect' },
  { value: 'Number', label: 'Number' },
  { value: 'Password', label: 'Password' },
  { value: 'Raw', label: 'Raw' },
  { value: 'RichText', label: 'RichText' },
  { value: 'Select', label: 'Select' },
  { value: 'String', label: 'String' },
  { value: 'Tags', label: 'Tags' },
  { value: 'Text', label: 'Text' },
  { value: 'Reference', label: 'Reference' },
];

const POSSIBLE_TITLE_FIELDS = ['Boolean', 'Date', 'Number', 'Raw', 'RichText', 'Select', 'String', 'Tags', 'Text'];

export class EntityDefinitionEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      spec: [],
      titleFieldCount: 0,
      loaded: false,
    };

    this.subDefs = [];

    this.onChange = this.onChange.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.loaded && nextProps.spec.length) {
      return {
        spec: nextProps.spec,
        loaded: true,
      };
    }
    return null;
  }

  componentDidMount() {
    const { spec } = this.props;

    this.setState({ spec });
  }

  getSpec() {
    const spec = clone(this.state.spec);
    for (let i = 0; i < spec.length; i++) {
      if (this.subDefs[i]) {
        spec[i].spec = this.subDefs[i].getSpec();
      }
    }

    return spec;
  }

  onChange(key, value, index) {
    const spec = clone(this.state.spec);
    spec[index][key] = value;

    if (key === '_type' && value === 'Reference') {
      if (this.props.referenceTypes) {
        spec[index].referenceType = this.props.referenceTypes[0].type;
        if (this.props.referenceTypeListSpec[this.props.referenceTypes[0].type]) {
          spec[index].displayField = getArrayOfRootLeaves(this.props.referenceTypeListSpec[this.props.referenceTypes[0].type].spec)[0];
        } else {
          spec[index].displayField = '';
        }
      } else {
        spec[index].referenceType = '';
        spec[index].displayField = '';
      }
    }

    let { titleFieldCount } = this.state;
    if (key === 'titleField') {
      titleFieldCount = value ? titleFieldCount + 1 : titleFieldCount - 1;
    } else if (key === '_type' && spec[index].titleField) {
      titleFieldCount -= 1;
      spec[index].titleField = false;
    } else if (key === 'i18n') {
      spec[index].i18n = !value;
    } else if (key === 'referenceType') {
      this.props.loadReferenceTypeFields(value, (field) => {
        spec[index].displayField = field;
      });
    }
    this.setState({ spec, titleFieldCount });
  }

  addField() {
    const spec = clone(this.state.spec);
    spec.push({
      name: '',
      _type: 'String',
      i18n: false,
    });

    this.setState({ spec });
  }

  removeField(i) {
    const spec = clone(this.state.spec);
    spec.splice(i, 1);
    this.setState({ spec });
  }

  onChangeOption(key, value, index, fieldIdx) {
    const spec = clone(this.state.spec);
    spec[fieldIdx].options[index][key] = value;
    this.setState({ spec });
  }

  removeOption(index, fieldIdx) {
    const spec = clone(this.state.spec);
    spec[fieldIdx].options.splice(index, 1);
    this.setState({ spec });
  }

  addOption(fieldIdx) {
    const spec = clone(this.state.spec);
    if (!spec[fieldIdx].options) {
      spec[fieldIdx].options = [];
    }
    spec[fieldIdx].options.push({
      value: '',
      label: '',
    });
    this.setState({ spec });
  }

  showDeleteModal(field, i) {
    Modal.confirm({
      title: 'Confirmation required',
      content: `Are you sure you want to delete '${field.name}'`,
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => this.removeField(i),
    });
  }

  renderOptions(options, fieldIdx) {
    return (
      <div className="options">
        {!!options && options.map((option, i) => (
          <div key={i} className="option">
            <div className="option-row">
              <StringInput
                entityDefinition={{ label: 'Option: ' }}
                fieldClasses={{ groupClass: 'option-name' }}
                value={option.value}
                onChange={(value) => this.onChangeOption('value', value, i, fieldIdx)}
              />
              <StringInput
                entityDefinition={{ label: 'Label: ' }}
                fieldClasses={{ groupClass: 'option-label' }}
                value={option.label}
                onChange={(value) => this.onChangeOption('label', value, i, fieldIdx)}
              />
              <div className="option-remove field-action-col">
                <Button
                  type="danger"
                  htmlType="button"
                  title="Delete element"
                  className="remove"
                  onClick={() => this.removeOption(i, fieldIdx)}
                  icon="delete"
                />
              </div>
            </div>
          </div>
        ))}
        <Button
          type="default"
          icon="plus"
          className="add-field"
          onClick={() => this.addOption(fieldIdx)}
        >
          Add option
        </Button>
      </div>
    );
  }

  renderSelectOptions = () => {
    const { referenceTypes } = this.props;
    const filteredTypes = [];

    // As a best condition to disable Reference input is to have no referenceTypes available.
    // (Account page - Additional user data)
    if (referenceTypes.length === 0) {
      filteredTypes.push('Reference');
    }

    return (
      TYPES
        .filter(({ value }) => filteredTypes.indexOf(value) === -1)
        .map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Select.Option>
        ))
    );
  };

  render() {
    this.subDefs = [];
    return (
      <div className={`EntityDefinitionEditor depth-${this.props.depth}`}>
        {this.state.spec.map((field, i) => (
          <div key={i} className="field">
            <div className="field-row">
              <StringInput
                fieldClasses={{ groupClass: 'field-name' }}
                entityDefinition={{ label: 'Name: ' }}
                value={field.name}
                onChange={(value) => this.onChange('name', value, i)}
              />

              <div className="field-type select">
                {'Type: '}
                <Select value={field._type} onChange={(value) => this.onChange('_type', value, i)}>
                  {this.renderSelectOptions()}
                </Select>
              </div>
              {(field._type === 'String' || field._type === 'RichText' || field._type === 'Text')
              && (
                <BooleanInput
                  name={`i18n-${i}`}
                  entityDefinition={{
                    label: 'i18n',
                  }}
                  value={field.i18n}
                  onChange={() => this.onChange('i18n', field.i18n, i)}
                />
              )}
              <div className="field-checkbox">
                {(this.props.depth === 0 && POSSIBLE_TITLE_FIELDS.includes(field._type))
                && (
                  <BooleanInput
                    name={`list-field-${i}`}
                    entityDefinition={{
                      label: 'Listfield',
                    }}
                    title="Show as a column in the list-view"
                    value={field.titleField}
                    disabled={this.state.titleFieldCount === 6 && !field.titleField}
                    onChange={(checked) => this.onChange('titleField', checked, i)}
                  />
                )}
              </div>

              <div className="field-remove field-action-col">
                <Button
                  type="danger"
                  htmlType="button"
                  icon="delete"
                  title="Delete element"
                  className="remove"
                  onClick={() => this.showDeleteModal(field, i)}
                />
              </div>

            </div>
            {(field._type === 'Group' || field._type === 'Array')
            && (
              <EntityDefinitionEditor
                ref={(subDef) => this.subDefs[i] = subDef}
                spec={field.spec}
                depth={this.props.depth + 1}
                loadReferenceTypeFields={this.props.loadReferenceTypeFields}
                referenceTypes={this.props.referenceTypes}
                referenceTypeListSpec={this.props.referenceTypeListSpec}
              />
            )}
            {(field._type === 'Select' || field._type === 'MultiSelect') && this.renderOptions(field.options, i)}
            {field._type === 'Reference'
            && (
              <div className="reference-type-select">
                {'Reference Type: '}
                <Select value={field.referenceType} onChange={(value) => this.onChange('referenceType', value, i)}>
                  {!!this.props.referenceTypes && this.props.referenceTypes.map((option) => (
                    <Select.Option
                      key={option.type}
                      value={option.type}
                    >
                      {option.type}
                    </Select.Option>
                  ))}
                </Select>
                {'Displayed Field: '}
                {this.props.referenceTypes && this.props.referenceTypes.length
                  ? (
                    <Select value={field.displayField} onChange={(value) => this.onChange('displayField', value, i)}>
                      {!!field.referenceType && !!this.props.referenceTypeListSpec[field.referenceType]
                        ? getArrayOfRootLeaves(this.props.referenceTypeListSpec[field.referenceType].spec).map((option) => <Select.Option key={option} value={option}>{option}</Select.Option>)
                        : (!!this.props.referenceTypeListSpec[this.props.referenceTypes[0].type]
                          && getArrayOfRootLeaves(this.props.referenceTypeListSpec[this.props.referenceTypes[0].type].spec).map((option) => <Select.Option key={option} value={option}>{option}</Select.Option>))}
                    </Select>
                  ) : 'Loading...'}
              </div>
            )}
          </div>
        ))}
        <Button
          type="default"
          className="add-field"
          onClick={this.addField}
          icon="plus"
        >
          Add field
        </Button>
      </div>
    );
  }
}

EntityDefinitionEditor.defaultProps = {
  spec: [],
  depth: 0,
  loadReferenceTypeFields: () => {
  },
  referenceTypes: [],
  referenceTypeListSpec: {},
};
