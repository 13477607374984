import React from 'react';
import { createConnector } from 'cartiv';
import {
  Button, Card, Input, Tabs, notification,
} from 'antd';
import { StringInput } from '../genericEditor/FormControls';
import './CollectionType.scss';
import { CollectionTypesStore } from './CollectionTypesStore';
import api from '../../services/api';
import { clone } from '../../lib/clone';
import { AccessControlEditor } from './AccessControlList';
import { getArrayOfLeavesFromSpecs } from '../../lib/converters';
import { EntityDefinitionEditor } from '../entityDefinitionEditor/EntityDefinitionEditor';
import { ReferenceTypesStore } from '../entityDefinitionEditor/ReferenceTypesStore';


const connect = createConnector(React);

@connect(CollectionTypesStore)
@connect(ReferenceTypesStore)
class CollectionType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangePermission = this.onChangePermission.bind(this);
    this.onChangeRoleName = this.onChangeRoleName.bind(this);
    this.onChangeRoleDescription = this.onChangeRoleDescription.bind(this);
    this.onDeleteRole = this.onDeleteRole.bind(this);
    this.addNewRole = this.addNewRole.bind(this);
  }

  componentDidMount() {
    const { type } = this.props.match.params;

    api.ReferenceTypes.onLoadReferenceTypes();

    if (type !== 'new') {
      api.CollectionTypes.onLoadCollectionTypeListSpec(this.props.match.params.type);
    }
  }

  componentWillUnmount() {
    api.CollectionTypes.onResetCollectionTypeListSpec();
  }

  onLoadReferenceTypesListSpec(type, callback) {
    api.ReferenceTypes.onLoadReferenceTypeListSpec(type, callback);
  }

  onChangePermission(index, permission) {
    const tempCollectionType = clone(this.state.collectionTypeListSpec);
    const roles = clone(tempCollectionType.acl);

    roles[index].permission = permission;
    tempCollectionType.acl = roles;
    this.setState({ collectionTypeListSpec: tempCollectionType });
  }

  onChangeRoleName(value, index) {
    const tempCollectionType = clone(this.state.collectionTypeListSpec);
    const roles = clone(tempCollectionType.acl);
    const nameComponents = roles[index].name.split('_');
    nameComponents[3] = value.replace(/- /g, '_').toUpperCase();
    roles[index].name = nameComponents.join('_');
    tempCollectionType.acl = roles;
    this.setState({ collectionTypeListSpec: tempCollectionType });
  }

  onChangeRoleDescription(value, index) {
    const tempCollectionType = clone(this.state.collectionTypeListSpec);
    const roles = clone(tempCollectionType.acl);
    roles[index].description = value;
    tempCollectionType.acl = roles;
    this.setState({ collectionTypeListSpec: tempCollectionType });
  }

  onDeleteRole(index) {
    const tempCollectionType = clone(this.state.collectionTypeListSpec);
    const roles = clone(tempCollectionType.acl);
    roles.splice(index, 1);
    tempCollectionType.acl = roles;
    this.setState({ collectionTypeListSpec: tempCollectionType });
  }

  addNewRole() {
    const tempCollectionType = clone(this.state.collectionTypeListSpec);
    const roles = clone(tempCollectionType.acl);
    const namePrefix = `ROLE_COLLECTION_${tempCollectionType.type.toUpperCase()}_`;
    roles.push({
      name: namePrefix,
      description: '',
      permission: {},
    });
    tempCollectionType.acl = roles;
    this.setState({ collectionTypeListSpec: tempCollectionType });
  }

  onChange(key, value) {
    const tempCollectionType = clone(this.state.collectionTypeListSpec);
    tempCollectionType[key] = value;
    this.setState({ collectionTypeListSpec: tempCollectionType });
  }

  onSave() {
    const noEmptyNameProperty = (spec) => spec.every(({ name }) => (name && name.length > 0));

    const collectionType = clone(this.state.collectionTypeListSpec);
    const { type: collectionName } = collectionType;

    if (!(collectionName && collectionName.length > 0)) {
      notification.error({
        message: 'Invalid input data',
        description: 'The Type Name of the new collection cannot be empty!',
      });

      return;
    }

    if (this.entityRef) {
      collectionType.spec = this.entityRef.getSpec();
    }

    if (noEmptyNameProperty(collectionType.spec)) {
      const { type } = this.props.match.params;
      if (type === 'new') {
        api.CollectionTypes.onCreateCollectionType(collectionType);
      } else {
        const { id } = this.state.collectionTypes
          .filter((collectionType) => collectionType.type === type)[0];
        api.CollectionTypes.onUpdateCollectionType(id, collectionType);
      }
    } else {
      notification.error({
        message: 'Invalid input data',
        description: 'The Name property of fields cannot be empty!',
      });
    }
  }

  render() {
    const collectionType = this.state.collectionTypeListSpec || { spec: [] };

    return (
      <div className="CollectionType content-wrapper">
        <Card title="Collection Type Editor">
          <h3>Type Name</h3>
          <StringInput
            value={collectionType.type}
            entityDefinition={{}}
            onChange={(value) => this.onChange('type', value)}
          />
          <h3 className="collection-description">
            Description
          </h3>
          <Input.TextArea
            value={collectionType.description}
            onChange={({ target }) => this.onChange('description', target.value)}
            className="description-text-area form-control"
          />
          <div className="spec-acl-container">
            <Tabs
              defaultActiveKey="spec"
              onChange={() => {
                const collectionType = clone(this.state.collectionTypeListSpec);
                collectionType.spec = this.entityRef.getSpec();
                this.setState({ collectionTypeListSpec: collectionType });
              }}
            >
              <Tabs.TabPane key="spec" tab="Spec">
                <div className="spec">
                  <h3 className="type-title">
                    Type specs
                  </h3>
                  <div className="desc">
                    Define the fields associated with the collection:
                  </div>
                  <EntityDefinitionEditor
                    ref={(entityDef) => this.entityRef = entityDef}
                    spec={collectionType.spec}
                    loadReferenceTypeFields={this.onLoadReferenceTypesListSpec}
                    referenceTypes={this.state.referenceTypes}
                    referenceTypeListSpec={this.state.referenceTypeListSpec}
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane key="acl" tab="Acl">
                <AccessControlEditor
                  collection={collectionType.type}
                  roles={collectionType.acl ? collectionType.acl : []}
                  onChangeName={this.onChangeRoleName}
                  onDeleteRole={this.onDeleteRole}
                  onChangeDescription={this.onChangeRoleDescription}
                  onChangePermission={this.onChangePermission}
                  onAddNewRole={this.addNewRole}
                  fields={getArrayOfLeavesFromSpecs(collectionType.spec)}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div className="card-actions">
            <Button
              type="link"
              htmlType="button"
              icon="left"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
            <Button type="primary" htmlType="submit" icon="save" onClick={this.onSave}>
              Save
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export { CollectionType };
