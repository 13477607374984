import React from 'react';
import { Route, Router, RouteComponentProps } from 'react-router';
import styled, { ThemeProvider } from 'styled-components';

import { Theme } from '../theme/Theme';

import 'antd/dist/antd.css';
import './App.css';

import { history } from '../services/history';
import AppRoutes from './AppRoutes';

const App = () => (
  <Router history={history}>
    <Route
      path="/"
      render={(props:RouteComponentProps) => {
        document.body.className = `router-${props.location.pathname.replace(/\//g, '') || 'root'}`;
        return (
          <ThemeProvider theme={Theme}>
            <AppRoutes {...props} />
          </ThemeProvider>
        );
      }}
    />
  </Router>
);

export default styled(App)`
  html, body {
    width: 100%;
    height: 100%;
    
    font-family: "Proxima Nova", Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    
    label {
      font-size: ${Theme.font.size};
    }
  }
`;
