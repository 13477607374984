import api from '../../services/api';
import { handleError } from '../api/Utils';
import { UNAUTHORIZED } from '../../constants';


const REASONS_REDIRECT_LOGIN = {
  Signature: 'Signature',
  MalformedJwt: 'MalformedJwt',
  ExpiredJwt: 'ExpiredJwt',
  UnsupportedJwt: 'UnsupportedJwt',
  IllegalArgument: 'IllegalArgument',
};


/**
 * Interceptor method for managing server notified token issues
 * Managed problems:
 *  - MalformedJwt
 *  - ExpiredJwt
 *  - UnsupportedJwt
 *  - IllegalArgument
 *
 */
export const verifyUnauthorized = async (response, config) => {
  if (response.status === UNAUTHORIZED) {
    try {
      const data = await response.clone().json();

      const shouldRedirectToLogin = !!REASONS_REDIRECT_LOGIN[data.title];
      if (shouldRedirectToLogin) {
        api.Auth.onLogout();
      }
    } catch (err) {
      handleError(err);
    }
  }

  return response;
};
