export default {
  Login: {
    title: 'Login',
    email: 'Email',
    password: 'Password',
    action: 'Log in',
    forgotPassword: 'Forgot password',
  },
  RequestPasswordReset: {
    title: 'Request password reset',
    action: 'Send',
    next: 'Next',
    step2Title: 'Select domain and group',
    noDomains: 'There are no domains available for this email. Please make sure email is valid.',
  },
  PasswordResetForm: {
    title: 'Reset your password',
    action: 'Save',
  },
  Registration: {
    title: 'Registration',
    email: 'Email',
    password: 'Password',
    action: 'Register',
  },
};
