import React, { useState } from 'react';
import { Row, Table } from 'antd';
import { get, has, isObject } from 'lodash';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import Pagination from '../pagination/Pagination';


const normalizeValue = (value, languageCode = 'en') => {
  let normalizedValue = value;

  if (isObject(value)) {
    if (isObject(value._i18n)) {
      normalizedValue = value._i18n[languageCode];
    }
  }
  return normalizedValue;
};


const augmentRenderFn = (column, languageCode) => {
  let render;
  const { render: originlRenderFn } = column;
  const newColumn = { ...column };

  // normalize data and call custom renderer
  if (newColumn.hasOwnProperty('render')) {
    render = (text, record, idx) => {
      const normalizedValue = normalizeValue(get(record, column.dataIndex), languageCode);

      const normalizedRecord = {
        ...record,
        [column.dataIndex]: normalizedValue,
      };
      return originlRenderFn(text, normalizedRecord, idx);
    };
  } else { // just normalize data for rendering
    render = (text, record, idx) => {
      const normalizedValue = normalizeValue(get(record, column.dataIndex), languageCode);

      return (
        <>
          {normalizedValue}
        </>
      );
    };
  }
  return { ...newColumn, render };
};


const AppTable = ({
  columns, data, onChange, loading, paginator, className, onRow, languageCode,
}) => {
  const [sorterState, setSorterState] = useState({
    sortField: 'lastModifiedDate',
    sortOrder: 'DESC',
  });

  const convertDirection = ({ columnKey }) => {
    if (columnKey === sorterState.sortField) {
      return sorterState.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    }
    return 'ASC';
  };

  const onPageChange = (current, pageSize) => {
    handleChange({
      current,
      pageSize,
    }, {}, {});
  };

  const handleChange = (pagination, filters, sorter) => {
    const newSorterState = {
      sortField: sorter.field || sorterState.sortField,
      sortOrder: has(sorter, 'columnKey') ? convertDirection(sorter) : sorterState.sortOrder,
    };

    setSorterState(newSorterState);

    const paginationOps = {
      page: pagination.current - 1 || 0,
      pageSize: pagination.pageSize,
      ...newSorterState,
      ...filters,
    };

    onChange(paginationOps);
  };

  const augmentedColumns = columns.map((column) => augmentRenderFn(column, languageCode));

  return (
    <div className={className}>
      <Table
        dataSource={data}
        columns={augmentedColumns}
        rowKey={(record) => record.id || uuid()}
        onChange={handleChange}
        pagination={false}
        loading={loading}
        onRow={onRow}
      />
      {paginator && (
        <Row type="flex" justify="end">
          <Pagination {...paginator} onChange={onPageChange} />
        </Row>
      )}
    </div>
  );
};

export default styled(AppTable)`
  .ant-table-wrapper {
    margin-bottom: ${({ theme }) => theme.space.xs};
  }

  .ant-spin {
    width: 100%;
    padding-top: 50px;
  }

   button:hover {
      border-bottom: 1px solid ${({ theme }) => theme.colors.hoverBorder};
   }
`;
