import React from 'react';
import PasswordActionErrorCard from '../PasswordReset/PasswordActionErrorCard';


export default (props) => (
  <PasswordActionErrorCard
    {...props}
    title="Failed to activate your account"
    description="Please try again later or, if the problem persists, contact the person who
  invited you."
  />
);
