import React from 'react';
import { Icon, Menu } from 'antd';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';


class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubMenu: false,
    };
  }

  componentDidMount() {
    // const path = this.props.link ? this.props.link : `/${this.props.subMenu[0].link.split('/')[1]}`;
    // if (window.location.pathname.startsWith(path)) {
    //   this.setState({ showSubMenu: true });
    // }
  }

  toggleSubMenu = () => {
    this.setState({ showSubMenu: !this.state.showSubMenu });
  };


  render() {
    const {
      title,
      icon,
      subMenu = [],
      key,
      link,
      className,
      ...rest
    } = this.props;
    if (subMenu && subMenu.length) {
      return (
        <Menu.SubMenu
          className={className}
          key={key}
          {...rest}
          title={(
            <span>
              <Icon type={icon} />
              <span>{title}</span>
            </span>
          )}
        >
          {
            subMenu.map(({ title: subTitle, icon: subIcon, link: subLink }) => (
              <Menu.Item key={subTitle}>
                <Link to={subLink}>
                  <Icon type={subIcon} />
                  <span>{subTitle}</span>
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>

      );
    } else {
      return (
        <Menu.Item
          key={key}
          {...rest}
          className={className}
        >
          <Link to={link}>
            <Icon type={icon} />
            <span>{title}</span>
          </Link>
        </Menu.Item>
      );
    }
  }
}

export default styled(MenuItem)`
  li,
  .ant-menu-submenu-title {
    &:hover {
      background: ${({ theme }) => theme.colors.hover};
    }
  }
  
  .anticon {
     font-size: 16px !important;
  }
`;
