import React from 'react';
import {
  Button, Card, Empty, Modal,
} from 'antd';
import { createConnector } from 'cartiv';
import { CollectionTypesStore } from './CollectionTypesStore';
import { AuthStore } from '../../stores/AuthStore';
import api from '../../services/api';
import './CollectionTypeList.scss';
import { navigateTo } from '../../services/history';
import { PATHS } from '../../constants';


const connect = createConnector(React);

@connect(CollectionTypesStore)
@connect(AuthStore)
class CollectionTypeList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    api.CollectionTypes.onLoadCollectionTypes();
  }

  confirmDelete = (deleteCollectionType) => {
    Modal.confirm({
      title: 'Confirmation required',
      content: `Are you sure you want to delete '${deleteCollectionType.type} (${deleteCollectionType.description})'`,
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => api.CollectionTypes.onDeleteCollectionType(deleteCollectionType.id),
    });
  };

  buildDeleteHandler = (collectionType) => (event) => {
    event.stopPropagation();
    this.confirmDelete(collectionType);
  };

  render() {
    const { collectionTypes = [] } = this.state;
    return (
      <div className="CollectionTypeList content-wrapper">
        <Card
          title="Collection types"
          extra={(
            <Button
              onClick={() => navigateTo(`${PATHS.COLLECTION_TYPES}/new`)}
              className="pull-right"
              type="primary"
              size="large"
              icon="plus"
            >
              Add
            </Button>
          )}
        >
          <table className="table table-hover">
            <tbody>
              {!!collectionTypes.length
            && collectionTypes.map((collectionType, index) => (
              <tr className="item" key={index} onClick={() => navigateTo(`${PATHS.COLLECTION_TYPES}/${collectionType.type}`)}>
                <td className="data">
                  <div className="title">{collectionType.type}</div>
                  <div className="description">{collectionType.description}</div>
                </td>
                <td className="actions">
                  <Button type="link" icon="edit">Edit</Button>
                  <Button
                    type="link"
                    onClick={this.buildDeleteHandler(collectionType)}
                    icon="delete"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          {!collectionTypes.length
          && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No collections defined" />}
        </Card>
      </div>
    );
  }
}

export { CollectionTypeList };
