export const cookies = {
  removeItem(cookieName) {
    this.setItem(cookieName, null, -1);
  },
  setItem(cookieName, value, exdays = 30, path = '/') {
    if (typeof document === 'undefined') return null;
    const cookieValue = encodeURIComponent(value);
    const pathString = `path=${path}`;
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + exdays);
    const expiration = ((exdays === null) ? '' : `; expires=${expirationDate.toUTCString()}`);
    const cookieDefinition = `${cookieName}=${cookieValue};${pathString}${expiration}`;
    document.cookie = cookieDefinition;
  },
  getItem(cookieName) {
    if (typeof document === 'undefined') return null;
    let x;
    let y;
    const ARRcookies = document.cookie.split(';');
    for (let i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x === cookieName) {
        return decodeURIComponent(y);
      }
    }
  },
};
