import React from 'react';
import PasswordResetRequestSentCard from '../../components/PasswordReset/PasswordResetRequestSentCard';
import PasswordSetCardsContainer from './PasswordSetCardsContainer';


export default (props) => (
  <PasswordSetCardsContainer>
    <PasswordResetRequestSentCard {...props} />
  </PasswordSetCardsContainer>
);
