import { createStore } from 'cartiv';
import { notification } from 'antd';
import { merge } from 'lodash';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';
import { navigateTo } from '../../services/history';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import { getPageAfterRemoval } from '../../utils/pagination/Utils';
import { PATHS } from '../../constants';

export const GroupStore = createStore({
  api,
  name: 'Group',
}, {
  getInitialState() {
    return {
      group: {
        name: '',
        description: '',
        allowSignup: false,
      },
      groups: [],
      totalElements: -1,
      lastPagination: {
        page: 0,
      },
      groupsLoading: false,
    };
  },

  onLoadPaginated({
    fieldName, direction, page, size = DEFAULT_ROW_NR,
  }) {
    const reqParams = merge(this.state.lastPagination, {
      fieldName, page, size, order: direction,
    });

    this.setState({ groupsLoading: true });

    backend.get('/account/groups', {
      params: reqParams,
    })
      .then((response) => {
        this.setState({
          groups: response.content,
          totalElements: response.totalElements,
          lastPagination: reqParams,
        });
      }).catch(genericErrorHandler)
      .finally(() => this.setState({ groupsLoading: false }));
  },

  onLoadAllGroups(cb) {
    const requestParams = { params: { size: 1_000_000 }};
    
    backend.get('/account/groups', requestParams).then((result) => {
      this.setState({
        groups: result.content,
      }, () => {
        cb && cb(result.content);
      });
    }).catch(genericErrorHandler);
  },

  onLoadGroup(groupId) {
    backend.get(`/account/groups/${groupId}`).then((result) => {
      this.setState({
        group: result,
      });
    }).catch(genericErrorHandler);
  },
  onResetGroup() {
    this.setState({
      group: {
        name: '',
        description: '',
        allowSignup: false,
      },
    });
  },
  onCreateGroup(groupData) {
    backend
      .post('/account/groups', { data: groupData }).then((result) => {
        const tempGroup = this.state.groups.slice();

        notification.success({ message: 'Group created' });
        tempGroup.push(result);
        this.setState({ groups: tempGroup });

        navigateTo(PATHS.ACCOUNTS_GROUPS);
      })
      .catch(genericErrorHandler);
  },
  onUpdateGroup(groupData) {
    const tempGroup = this.state.groups.slice();
    const index = tempGroup.findIndex((group) => group.groupId === groupData.groupId);
    tempGroup[index] = groupData;
    backend
      .patch(`/account/groups/${groupData.groupId}`, { data: groupData }).then((result) => {
        notification.success({ message: 'Group saved' });
        this.setState({ groups: tempGroup });
        navigateTo(PATHS.ACCOUNTS_GROUPS);
      })
      .catch(genericErrorHandler);
  },
  onDeleteGroup(groupId) {
    const { groups, lastPagination } = this.state;

    backend
      .delete(`/account/groups/${groupId}`).then((result) => {
        this.onLoadPaginated({
          ...lastPagination,
          page: getPageAfterRemoval(lastPagination.page, groups.length),
        });

        notification.success({ message: 'Group deleted' });
      })
      .catch(genericErrorHandler);
  },
});
