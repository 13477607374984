import React from 'react';
import GroupInput from './GroupInput';
import './MultiPageInput.scss';

export class MultiPageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: Object.keys(this.props.entityDefinition.spec)[0],
    };
  }

  setActivePage(e, page) {
    e.preventDefault();
    this.setState({
      activePage: page,
    });
  }

  onPageChange(pageId, value) {
    const entity = this.props.value || {};
    entity[pageId] = value;
    this.props.onChange(entity);
  }

  render() {
    return (
      <div className={`${this.props.name} MultiPageInput `}>
        <ul className="page-titles row nav nav-tabs tab-solid  tab-solid-primary">
          {Object.keys(this.props.entityDefinition.spec).map(pageId => {
              const activeClass = this.state.activePage === pageId ? 'active' : '';
              const page = this.props.entityDefinition.spec[pageId];
              return (<li className={`page-title page-title-${pageId} nav-item`}
                          key={pageId}>
                <a href="#"
                   className={`nav-link ${activeClass}`}
                   onClick={e => this.setActivePage(e, pageId)}>
                  {page.name || pageId}
                </a>
              </li>);
            },
          )}
        </ul>
        <div className="page-contents">
          {Object.keys(this.props.entityDefinition.spec).map(pageId => {
              const activeClass = this.state.activePage === pageId ? 'active' : '';
              const page = this.props.entityDefinition.spec[pageId];
              return (
                <div
                  className={`page-content page-content-${pageId} ${activeClass}`}
                  key={pageId}>
                  <GroupInput
                    name={`${this.props.name}-${pageId}`}
                    entityDefinition={page}
                    fieldClasses={this.props.fieldClasses}
                    value={this.props.value[pageId]}
                    languages={this.props.languages}
                    onChange={(v) => this.onPageChange(pageId, v)}
                  />
                </div>);
            },
          )}
        </div>
      </div>
    );
  }
}

MultiPageInput.defaultProps = {
  name: 'name',
  value: {},
  defaultValue: {},
  onChange: v => console.log('default change listener', v),
  entityDefinition: {
    spec: [{
      title: 'sample',
      spec: {},
    }],
  },
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
};
