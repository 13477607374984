import { createStore } from 'cartiv';
import { notification } from 'antd';
import api from '../../services/api';
import fileBackend from '../../services/api/FileApi';
import { genericErrorHandler } from '../../utils/Common';

export const FileSettingsStore = createStore({
  api,
  name: 'FileSettings',
}, {
  getInitialState() {
    return {
      settings: {},
      loading: false,
    }
  },
  toFEStructure(settings) {
    let data = {
      maxSize: settings.maxSize,
      cacheRetention: settings.cacheRetention,
    };

    data.allowedTypes = settings.allowedTypes.map(value => {
      const parts = value.split('/');
      return {
        type: parts[0],
        subtype: parts[1],
      }
    });

    return data;
  },
  toBEStructure(settings) {
    let data = {
      maxSize: settings.maxSize,
      cacheRetention: settings.cacheRetention,
    };

    data.allowedTypes = settings.allowedTypes.map(type => type.type !== '*' ? `${type.type}/${type.subtype}` : '*');

    return data;
  },
  onLoadFileSettings() {
    this.setState({loading: true});
    fileBackend.get('/api/files/settings').then((result) => {
      this.setState({
        settings: this.toFEStructure(result),
        loading: false,
      });
    }).catch((e) => {
      this.setState({loading: false, settings: {}});
      genericErrorHandler(e);
    });
  },
  onUpdateFileSettings(data) {
    const settings = this.toBEStructure(data);
    fileBackend.patch('/api/files/settings', settings).then(result => {
      notification.success({message: 'File settings saved'});
    }).catch(genericErrorHandler);
  },
});
