export const getConvertedSpecsFromArrayIntoObject = function (data) {
  let transformedSpec = {};
  for (let i = 0; i < data.length; i++) {
    if (data[i]._type === 'Group' || data[i]._type === 'Array') {
      let tmpSpec = getConvertedSpecsFromArrayIntoObject(data[i].spec);
      transformedSpec[data[i].name] = {
        _type: data[i]._type,
        spec: tmpSpec,
      };
    } else if (data[i]._type === 'Select' || data[i]._type === 'MultiSelect') {
      transformedSpec[data[i].name] = {
        _type: data[i]._type,
        options: data[i].options,
      };
    } else if (data[i]._type === 'String' || data[i]._type === 'RichText' || data[i]._type === 'Text') {
      transformedSpec[data[i].name] = {
        _type: data[i]._type,
        i18n: data[i].i18n,
      }
    } else if (data[i]._type === 'Reference') {
      transformedSpec[data[i].name] = {
        _type: data[i]._type,
        referenceType: data[i].referenceType,
        displayField: data[i].displayField,
      };
    } else {
      transformedSpec[data[i].name] = data[i]._type;
    }
  }
  return transformedSpec;
};
export const getConvertedSpecsFromObjectIntoArray = function (data) {
  let transformedSpec = [];
  Object.keys(data).forEach(key => {
    let tmp = {
      name: key,
    };

    if (data[key]._type === 'Select' || data[key]._type === 'MultiSelect' || data[key]._type === 'Group' || data[key]._type === 'Array' || data[key]._type === 'Reference') {
      tmp._type = data[key]._type;

      if (data[key]._type === 'Select' || data[key]._type === 'MultiSelect') {
        tmp.options = data[key].options;

      } else if (data[key]._type === 'Reference') {
        tmp.referenceType = data[key].referenceType;
        tmp.displayField = data[key].displayField;

      } else {
        tmp.spec = getConvertedSpecsFromObjectIntoArray(data[key].spec);
      }
    } else if (data[key]._type === 'String' || data[key]._type === 'RichText' || data[key]._type === 'Text') {
      tmp._type = data[key]._type;
      tmp.i18n = data[key].i18n;
    } else {
      tmp._type = data[key];
    }
    transformedSpec.push(tmp);
  });
  return transformedSpec;
};

export const getArrayOfLeavesFromSpecs = function (data) {
  let leaves = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i]._type === 'Group' || data[i]._type === 'Array') {
      let tempData = getArrayOfLeavesFromSpecs(data[i].spec);
      if (tempData.length > 0 && typeof tempData[0] === 'string') {
        tempData.forEach(tmp => leaves.push(tmp));
      }
    } else {
      leaves.push(data[i].name);
    }
  }
  return leaves;
};
export const getArrayOfRootLeaves = function (data) {
  let leaves = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i]._type !== 'Group' && data[i]._type !== 'Array' && data[i]._type !== 'Select' && data[i]._type !== 'MultiSelect' && data[i]._type !== 'Reference') {
      leaves.push(data[i].name);
    }
  }
  return leaves;
};
export const getArrayOfLeafObjectsFromSpecs = function (data) {
  let leaves = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i]._type === 'Group' || data[i]._type === 'Array') {
      let tempData = getArrayOfLeafObjectsFromSpecs(data[i].spec);
      if (tempData.length > 0) {
        tempData.forEach(tmp => leaves.push(tmp));
      }
    } else {
      leaves.push(data[i]);
    }
  }
  return leaves;
};

export const getReferenceTypes = function (data) {
  let types = [];
  let tempData = data;
  if (typeof tempData === 'object') {
    tempData = getConvertedSpecsFromObjectIntoArray(tempData);
  }
  let leaves = getArrayOfLeafObjectsFromSpecs(tempData);
  for (let i = 0; i < leaves.length; i++) {
    if (leaves[i]._type === 'Reference') {
      types.push({referenceType: leaves[i].referenceType, displayField: leaves[i].displayField});
    }
  }
  return types;
};

export const getReferenceFieldCount = function (data) {
  let count = 0;
  let leaves = getArrayOfLeafObjectsFromSpecs(data);
  for (let i = 0; i < leaves.length; i++) {
    if (leaves[i]._type === 'Reference') {
      count = count + 1;
    }
  }
  return count;
};

export const getTitleFields = function (data) {
  let titleFields = [];
  data.forEach((field) => {
    if (!!field.titleField) {
      titleFields.push(field.name);
    }
  });

  return titleFields;
};

export const addTitleFields = function (data, titleFields) {
  if (!!titleFields) {
    data.forEach((field) => {
      field.titleField = titleFields.includes(field.name)
    });
  }

  return data;
};
