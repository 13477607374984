import React from 'react';
import { createConnector } from 'cartiv';

import { Button, Card, notification } from 'antd';
import { DEFAULT_TEMPLATES, EmailTemplatesStore } from '../../stores/EmailTemplateStore';
import { GroupInput } from '../genericEditor/FormControls';
import { GroupStore } from '../account/GroupStore';
import './EmailTemplatePage.scss';

const connect = createConnector(React);

@connect(EmailTemplatesStore)
@connect(GroupStore)
class EmailTemplatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _initial: true,
      formData: null,
      loadedTemplateId: null,
      templates: [],
      loading: false,
    };
    this.getTemplate = this.getTemplate.bind(this);
  }

  componentDidMount() {
    // if (this.props.match.params.id !== 'new') {
    //   EmailTemplatesStore.onLoadPaginatedTemplates();
    // } else {
    //   EmailTemplatesStore.onUnloadTemplate();
    // }
    // EmailTemplatesStore.onLoadPaginatedTemplates(); // TODO  see if commented code can be removed
    GroupStore.onLoadAllGroups();
  }

  getTemplate(id) {
    const templates = this.state.templates.filter((template) => template.id === id);
    return templates[0];
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const data = this.getTemplate(this.props.match.params.id);
    if (this.state.formData === null && this.state.loading === false && !!data) {
      this.setState({
        formData: data,
        loadedTemplateId: data.templateId,
      });
    }
  }

  onChange(value) {
    this.setState({
      formData: value,
    });
  }

  save(e) {
    e.preventDefault();
    const formData = this.state.formData || {};
    if (this.props.match.params.id === 'new') {
      if (DEFAULT_TEMPLATES.indexOf(formData.templateId) === -1) {
        EmailTemplatesStore.onAddTemplate(formData);
      } else {
        notification.warn({ message: `Default template id ${formData.templateId} can't be used!` });
      }
    } else if (DEFAULT_TEMPLATES.indexOf(formData.templateId) === -1) {
      EmailTemplatesStore.onUpdateTemplate(this.props.match.params.id, formData);
    } else if (DEFAULT_TEMPLATES.indexOf(this.state.loadedTemplateId) !== -1) {
      EmailTemplatesStore.onUpdateTemplate(this.props.match.params.id, formData);
    } else {
      notification.warn({ message: 'Default template id can\'t be used!' });
    }
  }

  render() {
    const groupOptions = [];
    if (this.state.groups) {
      this.state.groups.forEach((group) => groupOptions.push({
        value: group.groupId,
        label: group.name,
      }));
    }
    const FormSpec = {
      spec: {
        name: 'String',
        templateId: 'String',
        subject: 'String',
        to: 'String',
        body: 'Text',
      },
    };

    return (
      <div className="EmailTemplatePage content-wrapper">
        <Card
          title="Edit Template"
          extra={(
            <Button type="link" icon="eye" onClick={() => this.setState({ hidePreview: !this.state.hidePreview })}>
              {this.state.hidePreview ? 'Show preview' : 'Hide preview'}
            </Button>
        )}
        >
          <form onSubmit={this.save.bind(this)}>

            {this.state.loading
              ? (
                <div>
                Loading...
                </div>
              )
              : (
                <GroupInput
                  name="template"
                  entityDefinition={FormSpec}
                  fieldClasses={{
                    groupClass: 'ant-row',
                    labelClass: 'ant-col-6',
                    inputClass: '',
                    inputWrapperClass: 'ant-col-18',
                  }}
                  value={this.state.formData}
                  onChange={this.onChange.bind(this)}
                />
              )}
            <div className="card-actions">
              <Button
                type="link"
                htmlType="button"
                icon="left"
                onClick={() => (window).history.back()}
              >
Back
              </Button>
              <Button type="primary" htmlType="submit" icon="save">
                Save
              </Button>
            </div>
          </form>

        </Card>
        {!this.state.hidePreview && (
        <Card title="Preview">
          {
            !!this.state.formData && !!this.state.formData.body
            && <div dangerouslySetInnerHTML={{ __html: this.state.formData.body }} />
          }
        </Card>
        )}
      </div>
    );
  }
}

export { EmailTemplatePage };
