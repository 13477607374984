import { createStore } from 'cartiv';
import { notification } from 'antd';
import { debounce } from 'lodash';
import api from '../../services/api';
import backend from '../../services/api/BackendApi';
import { AuthStore } from '../../stores/AuthStore';
import { navigateTo } from '../../services/history';
import { UserStore } from '../../stores/UserStore';
import { JAP_ADMIN_UI, PATHS } from '../../constants';
import { ApplicationPublicDataStore } from '../../stores/ApplicationPublicDataStore';

export const LoginStore = createStore({
  api,
  name: 'Login',
}, {
  getInitialState() {
    return {
      loginError: null,
      lastFetched: -1,
      loading: false,
    };
  },
  onLogin(email, password, domainId) {
    const data = {
      email, password, rememberMe: true,
    };
    if (domainId) {
      data.domainId = domainId;
    }
    backend.post('/account/authenticate', { data })
      .then((resp) => {
        this.setState({
          loginError: null,
          lastFetched: new Date().getTime(),
        });

        window.parent.postMessage({
          message: 'Successfully logged in',
          token: resp.token,
          email,
        }, '*');
      }).catch((errorResponse) => {
        if (errorResponse.json) {
          errorResponse.json()
            .then((js) => {
              this.setState({ loginError: js });

              const data = {
                message: 'Error',
                response: js,
              };
              window.parent.postMessage(data, '*');
            });
        } else {
          this.setState({ loginError: errorResponse });

          const data = {
            message: 'Error',
            title: errorResponse.data && errorResponse.data.title,
            response: errorResponse,
          };
          window.parent.postMessage(data, '*');
        }
      });
  },
  onLoginSuccessful(token) {
    AuthStore.initFromToken(token);

    const hasJapAdminRole = AuthStore.getRoles().includes(JAP_ADMIN_UI);

    if (hasJapAdminRole) {
      UserStore.onLoadAccount(AuthStore.onGetStateObject(token).jwtObject.userId);
      navigateTo(PATHS.LANDING);
    } else {
      notification.error({
        message: 'Permission denied',
        description: 'You have no permission to use this app.\n In order to change that contact your administrator.',
        duration: 0,
      });

      api.Auth.onLogout();

      navigateTo(PATHS.LOGIN);
    }
  },
  onSendPasswordResetRequest(email, domainId, noNavigate = false) {
    this.setState({ loading: true });
    backend.post('/account/reset-password-request', { data: { email, domainId }, headers: { 'jrc-domain': domainId } })
      .then((resp) => {
        !noNavigate && navigateTo('request/sent', [email, domainId]);
      }).catch((errorResponse) => {
        notification.error({ title: 'Could not send password reset email' });
      })
      .finally(() => this.setState({ loading: false }));
  },
  onResetPasswordWithToken(password, resetToken) {
    this.setState({ loading: true });
    backend.post('/account/reset-password', {
      data: { password, resetToken },
      headers: {
        Authorization: null /* FIXME needs to be set, so active JAP logins on the same domain don't interfere  */,
        'jrc-domain': ApplicationPublicDataStore.getApplicationId(),
      },
    })
      .then(({ token }) => {
        if (token) {
          navigateTo(PATHS.PASSWORD_RESET_SUCCESS);
          debounce(() => {
            const callbackUrl = ApplicationPublicDataStore.getCallbackUrlFor('password-reset', 'success', { token });
            window.location = callbackUrl;
          }, 2000)();
        }
      }).catch((errorResponse) => {
        navigateTo(PATHS.PASSWORD_RESET_ERROR);
      }).finally(() => this.setState({ loading: false }));
  },
  onChangePassword(email, domainId, currentPassword, newPassword, showWrongOldPasswordError) {
    this.setState({ loading: true });

    const authData = {
      email, domainId, password: currentPassword, rememberMe: true,
    };
    backend.post('/account/authenticate', { data: authData })
      .then(({ token }) => {
        const passwordData = { oldPassword: currentPassword, newPassword };
        const headers = { Authorization: `Bearer ${token}` };

        backend.post('/account/change-password', { data: passwordData, headers })
          .then(({ status, value }) => {
            if (status === 200 && value) {
              navigateTo(PATHS.PASSWORD_CHANGE_SUCCESS);
              debounce(() => { window.location.href = value; }, 2000)();
            }
          }).catch((errorResponse) => {
            const { data } = errorResponse;

            if (data && data.status === 401) {
              showWrongOldPasswordError(data.title);
            } else {
              navigateTo(PATHS.PASSWORD_CHANGE_ERROR);
            }
          });
      })
      .catch(() => {
        showWrongOldPasswordError('Wrong current password');
      })
      .finally(() => this.setState({ loading: false }));
  },
  onLoadGroupByEmailAndDomainThenSendRequest(email, domain) {
    this.setState({ loading: true });
    backend.get(`/account/group/${email}/${domain}`)
      .then((resp) => {
        this.onSendPasswordResetRequest(email, domain, resp.value);
      })
      .catch(() => {
        const data = {
          status: 404,
          message: 'User with email not found',
        };
        window.parent.postMessage(data, '*');
      })
      .finally(() => this.setState({ loading: false }));
  },
  onBackToLogin(domainId) {
    const uriParams = domainId ? `?domainId=${domainId}` : '';

    if (window.location.pathname.includes('embed')) {
      navigateTo(`/embed/login${uriParams}`);
    } else {
      navigateTo(`/login${uriParams}`);
    }
  },
});
