import { notification } from 'antd';
import { omit, isObject } from 'lodash';
import { handleError } from './api/Utils';


const reportToUser = (thrownError, extractedData = {}) => {
  const data = omit(extractedData, 'status'); // remove status from displaying

  const stringifyError = (input) => {
    return Object.entries(input).map(([key, value]) => {
      if (isObject(value)) {
        return stringifyError(value);
      }
      return `${value}`;
    }).join(', ');
  };

  notification.error({
    message: 'An issue occured ',
    description: stringifyError(data),
    getContainer: () => window.top.document.body, // root window will have itself as top
  });
};

/**
 * @param err
 * @param cb
 */
export const genericErrorHandler = function (err, cb) {
  if (err instanceof Response || err.response instanceof Response) {
    const actualErr = err instanceof Response ? err : err.response;

    actualErr
      .json()
      .then((data) => {
        reportToUser(err, data);
      })
      .catch((e2) => {
        reportToUser(null, err || e2);
      });
  } else {
    reportToUser(null, err);
  }

  if (cb) {
    cb();
  }
};

/**
 * Throws the error to be catch by promise and handled accordingly
 * @param err
 * @param cb
 */
export const genericErrorHandlerWithThrow = function (err, cb) {
  handleError(err);
  throw err;
};
