import React from 'react';
import {
  Button, Card, Checkbox, Input,
} from 'antd';
import { createConnector } from 'cartiv';
import styled from 'styled-components';
import api from '../../services/api';
import { AuthStore } from '../../stores/AuthStore';
import { BooleanInput, StringInput } from '../genericEditor/FormControls';
import { clone } from '../../lib/clone';
import { GroupStore } from './GroupStore';
import GenericBackAndSubmit from '../../components/GenericFooter/GenericBackAndSubmit';


const connect = createConnector(React);

@connect(GroupStore)
@connect(AuthStore)
class GroupEditor extends React.Component {
  constructor(props) {
    super(props);
    this.id = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
    this.state = {};

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangePublic = this.onChangePublic.bind(this);
  }

  componentDidMount() {
    if (this.id !== 'new') {
      api.Group.onLoadGroup(this.id);
    } else {
      api.Group.onResetGroup();
    }
  }

  onClickSave() {
    if (this.state.group.groupId !== undefined) {
      api.Group.onUpdateGroup(this.state.group);
    } else {
      api.Group.onCreateGroup(this.state.group);
    }
  }

  onChangeName(e) {
    const tempGroup = clone(this.state.group);
    tempGroup.name = e;
    this.setState({ group: tempGroup });
  }

  onChangeDescription(e) {
    const tempGroup = clone(this.state.group);
    tempGroup.description = e.target.value;
    this.setState({ group: tempGroup });
  }

  onChangePublic() {
    const tempGroup = clone(this.state.group);
    tempGroup.allowSignup = !tempGroup.allowSignup;
    this.setState({ group: tempGroup });
  }

  render() {
    const { className } = this.props;

    return (
      <div className={`GroupEditor content-wrapper ${className}`}>
        <Card title=" Group editor">
          <div className="editor">
            {this.state.group && this.state.group.groupId && (
            <div className="groupId">
              <label>GroupId</label>
              <div className="value">
                {this.state.group.groupId}
              </div>
            </div>
            )}
            <div className="name">
              <label>Name</label>
              <StringInput
                value={this.state.group ? this.state.group.name : ''}
                entityDefinition={{}}
                onChange={this.onChangeName}
              />
            </div>
            <div className="description">
              <label>Description</label>
              <Input.TextArea
                value={this.state.group ? this.state.group.description : ''}
                className="form-control"
                onChange={this.onChangeDescription}
              />
            </div>
            <div className="public">
              <BooleanInput
                entityDefinition={{ label: 'Public', details: 'A public profile is one that allows signup' }}
                value={this.state.group ? this.state.group.allowSignup : false}
                onChange={this.onChangePublic}
                fieldClasses={{
                  groupClass: 'ant-row',
                  labelClass: 'ant-col-6',
                  inputClass: '',
                  inputWrapperClass: 'ant-col-7',
                }}
                name="public-checkbox"
              />
            </div>
          </div>
          <GenericBackAndSubmit onSave={this.onClickSave} />
        </Card>
      </div>
    );
  }
}

export default styled(GroupEditor)`
  .editor-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 
  .editor > div:not(:first-child) {
      margin-top: ${({ theme }) => theme.space.xs};
  }
`;
