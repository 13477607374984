import React from 'react';
import styled from 'styled-components';
import DateCell from './DateCell';

const DateAndModifierCell = ({
  modifiedBy, date, format, className,
}) => (
  <div className={className}>
    <DateCell date={date} format={format} />
    <span>{`by ${(modifiedBy || '').split('@')[0]}`}</span>
  </div>
);

export default styled(DateAndModifierCell)`
  > span {
    display: block;
    padding-left: ${({ theme }) => theme.space.xxs}
  }
`;
