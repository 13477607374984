import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';

export const DomainTokensPageStore = createStore({
  api,
  name: 'DomainTokensPage',
}, {
  getInitialState() {
    return {
      token: '',
    };
  },
  onGenerateToken(data) {
    backend.post('/domains/generate-token', { data }).then(({ token }) => {
      const copyStringToClipboard = (str) => {
        // Create new element
        const el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
      };

      copyStringToClipboard(`localStorage.token="${token}"`);

      this.setState({ token });

      notification.info({ message: 'Token copied to clipboard' });
    }).catch((e) => {
      genericErrorHandler(e);
    });
  },
});
