import React from 'react';
import { Button, Form, Input } from 'antd';
import { customPasswordValidator } from '../../utils/strings/Utils';

const validateRepeatPassword = (form) => (rule, value, callback) => {
  const { getFieldValue } = form;
  const password = getFieldValue('password');

  if (!value || password !== value) {
    callback('The passwords do not match!');
  } else {
    callback();
  }
};

const PasswordInputForm = ({
  type = 'reset', onSubmit, form, loading,
}) => {
  const { getFieldDecorator } = form;

  const currentPasswordInput = type === 'change' ? (
    <Form.Item name="password">
      {getFieldDecorator('current-password', {
        rules: [
          {
            required: true,
            message: 'Please enter your current password',
          },
        ],
        validateTrigger: 'onSubmit',
      })(<Input.Password placeholder="Current Password" />)}
    </Form.Item>
  ) : null;

  return (
    <Form name="basic" onSubmit={onSubmit}>
      {currentPasswordInput}

      <Form.Item name="password">
        {getFieldDecorator('password', {
          rules: [
            {
              validator: customPasswordValidator,
            },
          ],
          validateTrigger: 'onSubmit',
        })(<Input.Password placeholder="New Password" />)}
      </Form.Item>

      <Form.Item name="repeat-password">
        {getFieldDecorator('repeat-password', {
          rules: [
            {
              validator: validateRepeatPassword(form),
            },
          ],
          validateTrigger: 'onSubmit',
        })(<Input.Password placeholder="Repeat New Password" />)}
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
            Set new password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create()(PasswordInputForm);
