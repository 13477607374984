import React from 'react';
import { createConnector } from 'cartiv';

import { Redirect } from 'react-router';
import { Card, notification } from 'antd';
import api from '../../services/api';
import { I18nStore } from '../i18n/i18nStore';
import { style } from './Login.scss';
import { AuthStore } from '../../stores/AuthStore';
import { LoginStore } from './LoginStore';
import { config } from '../../config';
import { navigateTo } from '../../services/history';
import { quotes } from './quotes';
import { parse } from '../../lib/query-string';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(LoginStore)
@connect(AuthStore)
@connect(I18nStore)
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: { Login: {} },
      email: '',
      password: '',
      quote: quotes[~~(quotes.length * Math.random())],
      divHeight: 300,
    };
    this.dummy = style;
  }

  componentDidMount() {
    const setState = this.setState.bind(this);
    window.addEventListener('message', (event) => this.receiveMessage(event, this.state, setState), false);
    const script = document.createElement('script');
    script.src = 'js/jap.js';
    script.async = true; // initial
    script.defer = true; // fallback older browsers

    const { domainId } = parse(this.props.location.search);

    document.body.appendChild(script);
    script.onload = () => this.scriptLoaded(domainId);
  }

  scriptLoaded(domainId) {
    const { adminUrl } = config;

    window.jap.embed('login', { domainId, element: 'login', adminUrl });
  }

  receiveMessage(event, state, setState) {
    if (event.origin === config.adminUrl) {
      const { data } = event;

      if (data.token) {
        api.Login.onLoginSuccessful(data.token);
      } else if (data.message === 'Error' && data.title) {
        notification.warn({ message: data.title });
      } else if (data === 'Forgot password') {
        navigateTo(PATHS.PASSWORD_RESET_REQUEST);
      } else if (data.name === 'nrAuthProviders') {
        const { divHeight } = state;
        const { nrAuthProviders } = data;
        const heightToAdd = nrAuthProviders > 0 ? 45 + nrAuthProviders * 55 : 0;
        setState({ divHeight: divHeight + heightToAdd });
      }
    }
  }

  renderlogin = () => (
    <div className="Login">
      <div className="ant-row team-row">
        <div className="ant-col-7 ant-col-offset-2">
          <Card title="Sign in to your account">
            <div
              id="login"
              className="login-form"
              className="login-card"
              style={{ height: `${this.state.divHeight}px` }}
            />
          </Card>
        </div>
        <div className="ant-col-offset-2 ant-col-7 right-side">
          <div className="quote">
            <div className="text">
              {`"${this.state.quote.text}"`}
            </div>
            <div className="author">
              {
                this.state.quote.author
                  .split(/[^a-zA-ZöäüÄÜÖéáűőúöüóíÉÁŰŐÚÖÜÍÓțșăâîȚȘĂÂÎ]/)
                  .map((n, i) => (i > 0 ? `${n[0]}.` : n))
                  .join(' ')
              }
            </div>
            <div className="image" style={{ backgroundImage: `url(images/team/${this.state.quote.id}.png-800.png)` }} />
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    // If user is logged in
    if (this.state.jwtObject && this.state.jwtObject.auth) {
      return <Redirect to="/domain-selector" />;
    }
    return this.renderlogin();
  }
}

export { Login };
