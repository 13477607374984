import { createStore } from 'cartiv';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';

export const ComponentsPageStore = createStore({
  api,
  name: 'ComponentsPage',
}, {
  getInitialState() {
    return {
      loading: false,
      components: [],
    };
  },
  onLoadComponents(parentId = 'root') {
    this.setState({
      loading: true,
    });
    backend.get('/collection/Component', { query: { q: { parentId }, size: 100 } })
      .then((result) => {
        this.setState({
          loading: false,
          components: result.content,
        });
      })
      .catch(genericErrorHandler);
  },
});
