export const sortBy = function (array, property, ascending = true) { // TODO remove and use lodash?
  let sortedArray = array || [];
  sortedArray.sort((a, b) => {
    if (
      a[property] > b[property] ||
      (typeof a[property] !== 'undefined' && typeof b[property] === 'undefined')
    ) {
      return ascending ? 1 : -1;
    } else if (a[property] < b[property] ||
      (typeof b[property] !== 'undefined' && typeof a[property] === 'undefined')
    ) {
      return ascending ? -1 : 1;
    } else {
      return 0;
    }
  });

  return sortedArray;
};

export const orderBy = sortBy;
