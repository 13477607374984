import React from 'react';
import { Link } from 'react-router-dom';
import { createConnector } from 'cartiv';
import styled from 'styled-components';
import { I18nStore } from '../i18n/i18nStore';
import { MyProfileStore } from '../../stores/ProfileStore';
import AccountManagementDropdown from './AccountManagementDropdown';
import { DomainSelectorStore } from '../DomainSelector/DomainSelectorStore';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(I18nStore)
@connect(MyProfileStore)
@connect(DomainSelectorStore)
class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  renderLogo(domainInfo) {
    const nonDefaultLogo = domainInfo
      ? (domainInfo.applicationLogoUrl || domainInfo.applicationLogoSmallUrl)
      : undefined;

    return (
      <div className="logo-container">
        <Link to={PATHS.LANDING}>
          {
            !nonDefaultLogo
              ? <div className="logo" />
              : <div className="logo" style={{ backgroundImage: `url(${nonDefaultLogo})` }} />
          }
        </Link>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { domainInfo = {} } = this.state;

    return (
      <div className={className}>
        {this.renderLogo(domainInfo)}
        <AccountManagementDropdown />
      </div>
    );
  }
}

export default styled(Header)`
  height: ${({ theme }) => theme.layout.header.height}px;
  justify-content: space-between;
  display: flex;

  .user-menu {
    cursor: pointer;

    .horizontal-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .ant-avatar {
      margin-right: 10px;
    }
  }

  .header-dropdown-menu-item {
    font-weight: 100;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
  }

  .active-domain {
    background-color: darken(#fff, 5%);
  }
  
  .user-email {
    color:  ${({ theme }) => theme.colors.white};
  }
  
 .logo-container {
    padding: 0 20px;
    width: 270px;
    height: calc(${({ theme }) => theme.layout.header.height}px - ${({ theme }) => theme.space.xxs} - ${({ theme }) => theme.space.xxs});
    background: ${({ theme }) => theme.colors.contrastBg};
    margin: ${({ theme }) => theme.space.xxs} 0;
    border-radius: 24px;
    
    .logo {
      height: 100%;
      background-image: url(${({ theme }) => theme.layout.header.logo});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
`;
