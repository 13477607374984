import React from 'react';
import { createConnector } from 'cartiv';
import {
  Button, Card, Divider, Form, Modal,
} from 'antd';
import styled from 'styled-components';
import { merge } from 'lodash';
import api from '../../services/api';
import { I18nStore } from '../../components/i18n/i18nStore';
import { UserStore } from '../../stores/UserStore';
import { AuthStore } from '../../stores/AuthStore';
import { GroupInput } from '../genericEditor/FormControls';
import { navigateTo } from '../../services/history';
import { GroupStore } from '../account/GroupStore';
import { AccountSettingsStore } from '../account/AccountSettingsStore';
import { generateGroupInputDef, generateQueryBasedOnDef } from '../genericEditor/GenericEntityTable';
import { ModalsStore } from '../../components/modals/ModalsStore';
import Table from '../../components/table/Table';
import RowButton from '../../components/table/action/RowButton';
import { DEFAULT_ROW_NR } from '../../components/pagination/Pagination';
import TagsCell from '../../components/table/cell/TagsCell';
import { escapeRegex } from '../../lib/utils';
import { PATHS } from '../../constants';

const connect = createConnector(React);

@connect(I18nStore)
@connect(UserStore)
@connect(AuthStore)
@connect(GroupStore)
@connect(AccountSettingsStore)
@connect(ModalsStore)
class UsersPage extends React.Component {
  userColumns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  }, {
    title: 'Profile',
    dataIndex: 'profileIds',
    key: 'profileIds',
    sorter: true,
    render: (text, record) => (<TagsCell tags={record.profileIds} />),
  }, {
    title: 'Group',
    dataIndex: 'groupIds',
    key: 'groupIds',
    render: (text, record) => (<TagsCell tags={record.groupIds} />),
    sorter: true,
  }, {
    title: 'Actions',
    key: 'actions',
    render: function (text, record) {
      return (
        <span>
          <RowButton
            icon="edit"
            onClick={() => this.goToUser(record)}
          />
          <Divider type="vertical" />

          <RowButton
            icon="delete"
            onClick={() => { this.confirmDelete(record); }}
          />
        </span>
      );
    }.bind(this),
  }];


  constructor(props) {
    super(props);

    this.state = {
      users: [],
      filter: {
        name: '',
        email: '',
        role: '',
        profile: '',
      },
    };
    this.onChange = this.onChange.bind(this);
    this.query = this.query.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
    this.getQuery = this.getQuery.bind(this);
    this.transformData = this.transformData.bind(this);
    this.goToUser = this.goToUser.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.domainId) {
        // TODO
    } else {
      api.Group.onLoadAllGroups();
    }

    this.query({});
  }

  onChange(value) {
    this.setState({ filter: value });
  }

  getQuery() {
    const { accountSettings } = this.state;
    const spec = !!accountSettings && !!accountSettings.userData
      ? accountSettings.userData.spec : {};
    const titleFields = !!accountSettings && !!accountSettings.userData
      ? (accountSettings.userData.titleFields || []) : [];

    const { filter } = this.state;
    const query = generateQueryBasedOnDef(titleFields, spec, filter);

    query.email = { $regex: escapeRegex(filter.email), $options: 'i' };

    if (this.props.match.params.domainId) {
      query.domainId = this.props.match.params.domainId;
    }

    const orList = [
      { firstName: { $regex: filter.name, $options: 'i' } },
      { lastName: { $regex: filter.name, $options: 'i' } },
    ];

    if (filter.name) {
      query.$or = orList;
    }

    if (filter.profile) {
      query.profileId = { $regex: filter.profile, $options: 'i' };
    }

    if (filter.group) {
      query.groupIds = { $regex: filter.group, $options: 'i' };
    }

    return query;
  }

  confirmDelete = (record) => {
    Modal.confirm({
      title: 'Confirmation required',
      content: `Are you sure you want to delete '${record.name}'`,
      okText: 'Delete',
      cancelText: 'No',
      onOk: () => api.User.onDeleteUser(record.id),
    });
  };

  clearQuery() {
    this.setState({
      filter: {
        name: '',
        email: '',
        role: '',
        profile: '',
      },
      lastPagination: {
        page: 0,
      },
    }, () => this.query({}));
  }

  /**
   * TODO needs to be applied on data set load NOT on every render!!!!
   * @param user
   * @returns {{groupName: string, name: string}}
   */
  transformData(user) {
    const groupName =
        this.state.groups
        .filter((gr) => user.groupIds && user.groupIds.indexOf(gr.groupId) !== -1)
        .join(', ')
    ;
    const name = `${user.firstName} ${user.lastName}`;
    return { ...user, groupName, name };
  }

  /**
   * Params update when the Table component changes
   * @param page
   * @param pageSize
   * @param sortField
   * @param sortOrder
   */
  query({
    page = 0, pageSize = DEFAULT_ROW_NR, sortField = 'lastModifiedDate', sortOrder = 'DESC',
  }) {
    const query = this.getQuery();

    api.User.onLoadPaginated({
      fieldName: sortField,
      direction: sortOrder,
      size: pageSize,
      page,
    }, query);
  }

  goToUser({ id, domainId }) {
    navigateTo(`${PATHS.USERS}/${id}/?domainId=${domainId}`);
  }

  renderFilters(titleFields, spec) {
    let inputDef = generateGroupInputDef(titleFields, spec);
    inputDef = {
      name: 'String',
      email: 'String',
      profile: 'String',
      group: 'String',
      ...inputDef,
    };
    return (
      <div className="filters">
        <h4>Filters</h4>
        <Form onSubmit={(e) => {
          e.preventDefault();
          this.query({});
        }}
        >
          <GroupInput
            name=""
            entityDefinition={{ spec: inputDef }}
            value={this.state.filter}
            onChange={this.onChange}
          />
          <div className="button-wrapper">
            <Button type="link" icon="close" onClick={this.clearQuery}>Clear</Button>
            <Button type="primary" htmlType="submit" icon="filter">Filter</Button>
          </div>
        </Form>
        <hr />
      </div>
    );
  }

  render() {
    const {
      jwtObject, accountSettings, users, totalElements, lastPagination, loading,
    } = this.state;
    const { className, match } = this.props;

    const userData = users.map((user) => this.transformData(user));
    const domainId = jwtObject && jwtObject.domainId !== 'root'
      ? jwtObject.domainId
      : match.params.domainId;

    const spec = !!accountSettings && !!accountSettings.userData
      ? accountSettings.userData.spec : {};
    const titleFields = !!accountSettings && !!accountSettings.userData
      ? (accountSettings.userData.titleFields || []) : [];

    const pagination = merge({
      total: totalElements,
      onChange: this.query,
    }, {
      pageSize: lastPagination ? lastPagination.pageSize : undefined,
      current: lastPagination ? lastPagination.page : undefined,
    });

    return (
      <Card
        className={className}
        title="Users"
        extra={(
          <Button
            type="primary"
            onClick={() => navigateTo(`${PATHS.USERS}/new/?domainId=${domainId}`)}
            className="pull-right"
            icon="plus"
          >
            Add User
          </Button>
        )}
      >
        {this.renderFilters(titleFields, spec)}

        <Table
          data={userData}
          columns={this.userColumns}
          onChange={this.query}
          paginator={pagination}
          loading={loading}
          onRow={(user) => ({
            onClick: (e) => {
              if (e.ctrlKey || e.metaKey) {
                const data = {
                  profileIds: user.profileIds,
                  groupIds: user.groupIds,
                  userId: user.id,
                  subject: user.email,
                  validity: '2030-10-10',
                };
                api.DomainTokensPage.onGenerateToken(data);
              }
            },
          })}
        />
      </Card>
    );
  }
}

export default styled(UsersPage)`
  .ant-card-body > * {
    margin-bottom: 30px;
  }
  
  .filters {
    form {
      display: flex;
      align-items: flex-end;
    }
    .GroupInput {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
     
      > * {
        margin: 8px 16px;
        width: 13vw;
      }
    }
    
    .button-wrapper {
      padding-top: ${({ theme }) => theme.space.sm};
      padding-right: ${({ theme }) => theme.space.sm};
      display: flex;
      align-self: flex-start;      
      
      button {
         margin-left: 16px;
      }
    }
     
    hr {
      border-top: 1px solid ${({ theme }) => theme.colors.hr};
    }
  }
`;
