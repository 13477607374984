import React from 'react';
import { createConnector } from 'cartiv';
import styled from 'styled-components';
import { I18nStore } from '../../components/i18n/i18nStore';

const connect = createConnector(React);

@connect(I18nStore)
class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="DashboardPage content-wrapper">
        <div className="ant-row">
          <div className="ant-col-3 ant-col3 ant-col-3 col-sm-6 grid-margin stretch-card">
            <div className="card card-statistics">
              <div className="card-body">
                <div className="clearfix">
                  <div className="float-left">
                    <i className="mdi mdi-cube text-danger icon-lg" />
                  </div>
                  <div className="float-right">
                    <p className="card-text text-right">Total Revenue</p>
                    <div className="fluid-container">
                      <h3 className="card-title font-weight-bold text-right mb-0">$65,650</h3>
                    </div>
                  </div>
                </div>
                <p className="text-muted mt-3">
                  <i className="mdi mdi-alert-octagon mr-1" aria-hidden="true" />
                  {' '}
                  65% lower growth
                </p>
              </div>
            </div>
          </div>
          <div className="ant-col-3 ant-col3 ant-col-3 col-sm-6 grid-margin stretch-card">
            <div className="card card-statistics">
              <div className="card-body">
                <div className="clearfix">
                  <div className="float-left">
                    <i className="mdi mdi-receipt text-warning icon-lg" />
                  </div>
                  <div className="float-right">
                    <p className="card-text text-right">Orders</p>
                    <div className="fluid-container">
                      <h3 className="card-title font-weight-bold text-right mb-0">3455</h3>
                    </div>
                  </div>
                </div>
                <p className="text-muted mt-3">
                  <i className="mdi mdi-bookmark-outline mr-1" aria-hidden="true" />
                  {' '}
                  Product-wise sales
                </p>
              </div>
            </div>
          </div>
          <div className="ant-col-3 ant-col3 ant-col-3 col-sm-6 grid-margin stretch-card">
            <div className="card card-statistics">
              <div className="card-body">
                <div className="clearfix">
                  <div className="float-left">
                    <i className="mdi mdi-poll-box text-teal icon-lg" />
                  </div>
                  <div className="float-right">
                    <p className="card-text text-right">Sales</p>
                    <div className="fluid-container">
                      <h3 className="card-title font-weight-bold text-right mb-0">5693</h3>
                    </div>
                  </div>
                </div>
                <p className="text-muted mt-3">
                  <i className="mdi mdi-calendar mr-1" aria-hidden="true" />
                  {' '}
                  Weekly Sales
                </p>
              </div>
            </div>
          </div>
          <div className="ant-col-3 ant-col3 ant-col-3 col-sm-6 grid-margin stretch-card">
            <div className="card card-statistics">
              <div className="card-body">
                <div className="clearfix">
                  <div className="float-left">
                    <i className="mdi mdi-account-location text-info icon-lg" />
                  </div>
                  <div className="float-right">
                    <p className="card-text text-right">Employees</p>
                    <div className="fluid-container">
                      <h3 className="card-title font-weight-bold text-right mb-0">246</h3>
                    </div>
                  </div>
                </div>
                <p className="text-muted mt-3">
                  <i className="mdi mdi-reload mr-1" aria-hidden="true" />
                  {' '}
                  Product-wise sales
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div
                  className="chartjs-size-monitor"
                  style={{
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    overflow: 'hidden',
                    pointerEvents: 'none',
                    visibility: 'hidden',
                    zIndex: '-1',
                  }}
                >
                  <div
                    className="chartjs-size-monitor-expand"
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      overflow: 'hidden',
                      pointerEvents: 'none',
                      visibility: 'hidden',
                      zIndex: -1,
                    }}
                  >
                    <div style={{
                      position: 'absolute',
                      width: '1000000px',
                      height: '1000000px',
                      left: 0,
                      top: 0,
                    }}
                    />
                  </div>
                  <div
                    className="chartjs-size-monitor-shrink"
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      overflow: 'hidden',
                      pointerEvents: 'none',
                      visibility: 'hidden',
                      zIndex: -1,
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute', width: '200%', height: '200%', left: 0, top: 0,
                      }}
                    />
                  </div>
                </div>
                <h5 className="card-title mb-4">Targets</h5>
                <canvas
                  id="dashoard-area-chart"
                  height="263"
                  width="789"
                  className="chartjs-render-monitor"
                  style={{
                    display: 'block', width: 789, height: 263,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ant-row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-4">Orders</h5>
                <div className="table-responsive">
                  <table className="table center-aligned-table">
                    <thead>
                      <tr>
                        <th className="border-bottom-0">Order No</th>
                        <th className="border-bottom-0">Product Name</th>
                        <th className="border-bottom-0">Purchased On</th>
                        <th className="border-bottom-0">Shipping Status</th>
                        <th className="border-bottom-0">Payment Method</th>
                        <th className="border-bottom-0">Payment Status</th>
                        <th className="border-bottom-0" />
                        <th className="border-bottom-0" />
                        <th className="border-bottom-0" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>034</td>
                        <td>Iphone 7</td>
                        <td>12 May 2017</td>
                        <td>Dispatched</td>
                        <td>Credit card</td>
                        <td><label className="badge badge-teal">Approved</label></td>
                        <td><a href="#" className="btn btn-outline-success btn-sm">View Order</a></td>
                        <td><a href="#" className="btn btn-outline-danger btn-sm">Cancel</a></td>
                      </tr>
                      <tr>
                        <td>035</td>
                        <td>Galaxy S8</td>
                        <td>15 May 2017</td>
                        <td>Dispatched</td>
                        <td>Internet banking</td>
                        <td><label className="badge badge-warning">Pending</label></td>
                        <td><a href="#" className="btn btn-outline-success btn-sm">View Order</a></td>
                        <td><a href="#" className="btn btn-outline-danger btn-sm">Cancel</a></td>
                      </tr>
                      <tr>
                        <td>036</td>
                        <td>Amazon Echo</td>
                        <td>17 May 2017</td>
                        <td>Dispatched</td>
                        <td>Credit card</td>
                        <td><label className="badge badge-teal">Approved</label></td>
                        <td><a href="#" className="btn btn-outline-success btn-sm">View Order</a></td>
                        <td><a href="#" className="btn btn-outline-danger btn-sm">Cancel</a></td>
                      </tr>
                      <tr>
                        <td>037</td>
                        <td>Google Pixel</td>
                        <td>17 May 2017</td>
                        <td>Dispatched</td>
                        <td>Cash on delivery</td>
                        <td><label className="badge badge-danger">Rejected</label></td>
                        <td><a href="#" className="btn btn-outline-success btn-sm">View Order</a></td>
                        <td><a href="#" className="btn btn-outline-danger btn-sm">Cancel</a></td>
                      </tr>
                      <tr>
                        <td>038</td>
                        <td>Mac Mini</td>
                        <td>19 May 2017</td>
                        <td>Dispatched</td>
                        <td>Debit card</td>
                        <td><label className="badge badge-teal">Approved</label></td>
                        <td><a href="#" className="btn btn-outline-success btn-sm">View Order</a></td>
                        <td><a href="#" className="btn btn-outline-danger btn-sm">Cancel</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-row">
          <div className="ant-col-12 ant-col12 ant-col-12 col-sm-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-4">Global Sales by Top Locations</h5>
                <div className="ant-row">
                  <div className="ant-col-5 ant-col5 ant-col-12 col-sm-12 col-xs-12">
                    <table className="table table-hover">
                      <tbody>
                        <tr>
                          <td>
                            <div className="flag">
                              <img src="../images/flags/US.png" />
                            </div>
                          </td>
                          <td>USA</td>
                          <td className="text-right">
                          2.920
                          </td>
                          <td className="text-right">
                          53.23%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img src="../images/flags/DE.png" />
                            </div>
                          </td>
                          <td>Germany</td>
                          <td className="text-right">
                          1.300
                          </td>
                          <td className="text-right">
                          20.43%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img src="../images/flags/AU.png" />
                            </div>
                          </td>
                          <td>Australia</td>
                          <td className="text-right">
                          760
                          </td>
                          <td className="text-right">
                          10.35%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img src="../images/flags/GB.png" />
                            </div>
                          </td>
                          <td>United Kingdom</td>
                          <td className="text-right">
                          690
                          </td>
                          <td className="text-right">
                          7.87%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img src="../images/flags/RO.png" />
                            </div>
                          </td>
                          <td>Romania</td>
                          <td className="text-right">
                          600
                          </td>
                          <td className="text-right">
                          5.94%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img src="../images/flags/BR.png" />
                            </div>
                          </td>
                          <td>Brasil</td>
                          <td className="text-right">
                          550
                          </td>
                          <td className="text-right">
                          4.34%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="ant-col-7 ant-col7 ant-col-12 col-sm-12 col-xs-12">
                    <div
                      className="rounded"
                      id="map"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-4"> Manage Tickets </h5>
                <div className="fluid-container">
                  <div className="ant-row ticket-card mt-3 pb-2 border-bottom">
                    <div className="col-1">
                      <img className="img-sm rounded-circle" src="images/faces/face1.jpg" alt="profile image" />
                    </div>
                    <div className="ticket-details col-9">
                      <div className="d-flex">
                        <p className="text-primary font-weight-bold mr-2 mb-0 no-wrap">James :</p>
                        <p className="font-weight-medium mr-1 mb-0">[#23047]</p>
                        <p className="font-weight-bold mb-0 ellipsis">Lorem ipsum dolor sit amet, consectetur.</p>
                      </div>
                      <p className="text-small text-gray">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Illum
                        sequi a, nostrum.
                      </p>
                      <div className="ant-row text-muted d-flex">
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Last responded :</p>
                          <p className="Last-responded mr-2 mb-0">3 hours ago</p>
                        </div>
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Due in :</p>
                          <p className="Last-responded mr-2 mb-0">2 Days</p>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-actions col-2">
                      <div className="btn-group dropdown">
                        <button
                          type="button"
                          className="btn btn-teal dropdown-toggle btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Manage
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-reply fa-fw" />
                            Quick reply
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-history fa-fw" />
                            Another
                            action
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-check text-success fa-fw" />
                            Resolve
                            Issue
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-times text-danger fa-fw" />
                            Close
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row ticket-card mt-3 pb-2 border-bottom">
                    <div className="col-1">
                      <img className="img-sm rounded-circle" src="images/faces/face2.jpg" alt="profile image" />
                    </div>
                    <div className="ticket-details col-9">
                      <div className="d-flex">
                        <p className="text-primary font-weight-bold mr-2 mb-0 no-wrap">Stella :</p>
                        <p className="font-weight-medium mr-1 mb-0">[#23135]</p>
                        <p className="font-weight-bold mb-0 ellipsis">
                          Lorem ipsum dolor sit amet, consectetur
                          adipisicing
                          elit. Sapiente.
                        </p>
                      </div>
                      <p className="text-small text-gray">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Excepturi voluptates fuga quae?
                      </p>
                      <div className="ant-row text-muted d-flex">
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Last responded :</p>
                          <p className="Last-responded mr-2 mb-0">3 hours ago</p>
                        </div>
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Due in :</p>
                          <p className="Last-responded mr-2 mb-0">2 Days</p>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-actions col-2">
                      <div className="btn-group dropdown">
                        <button
                          type="button"
                          className="btn btn-teal dropdown-toggle btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Manage
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-reply fa-fw" />
                            Quick reply
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-history fa-fw" />
                            Another
                            action
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-check text-success fa-fw" />
                            Resolve
                            Issue
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-times text-danger fa-fw" />
                            Close
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row ticket-card mt-3 pb-2 border-bottom">
                    <div className="col-1">
                      <img className="img-sm rounded-circle" src="images/faces/face3.jpg" alt="profile image" />
                    </div>
                    <div className="ticket-details col-9">
                      <div className="d-flex">
                        <p className="text-primary font-weight-bold mr-2 mb-0 no-wrap">John Doe :</p>
                        <p className="font-weight-medium mr-1 mb-0">[#23246]</p>
                        <p className="font-weight-bold mb-0 ellipsis">Lorem ipsum dolor sit amet.</p>
                      </div>
                      <p className="text-small text-gray">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </p>
                      <div className="ant-row text-muted d-flex">
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Last responded :</p>
                          <p className="Last-responded mr-2 mb-0">3 hours ago</p>
                        </div>
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Due in :</p>
                          <p className="Last-responded mr-2 mb-0">2 Days</p>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-actions col-2">
                      <div className="btn-group dropdown">
                        <button
                          type="button"
                          className="btn btn-teal dropdown-toggle btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Manage
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-reply fa-fw" />
                            Quick reply
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-history fa-fw" />
                            Another
                            action
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-check text-success fa-fw" />
                            Resolve
                            Issue
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-times text-danger fa-fw" />
                            Close
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row ticket-card mt-3 pb-2 border-bottom">
                    <div className="col-1">
                      <img className="img-sm rounded-circle" src="images/faces/face4.jpg" alt="profile image" />
                    </div>
                    <div className="ticket-details col-9">
                      <div className="d-flex">
                        <p className="text-primary font-weight-bold mr-2 mb-0 no-wrap">Marques Brownlee :</p>
                        <p className="font-weight-medium mr-1 mb-0">[#23047]</p>
                        <p className="font-weight-bold mb-0 ellipsis">Lorem ipsum dolor sit amet, consectetur.</p>
                      </div>
                      <p className="text-small text-gray">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Illum
                        sequi a, nostrum.
                      </p>
                      <div className="ant-row text-muted d-flex">
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Last responded :</p>
                          <p className="Last-responded mr-2 mb-0">3 hours ago</p>
                        </div>
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Due in :</p>
                          <p className="Last-responded mr-2 mb-0">2 Days</p>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-actions col-2">
                      <div className="btn-group dropdown">
                        <button
                          type="button"
                          className="btn btn-teal dropdown-toggle btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Manage
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-reply fa-fw" />
                            Quick reply
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-history fa-fw" />
                            Another
                            action
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-check text-success fa-fw" />
                            Resolve
                            Issue
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-times text-danger fa-fw" />
                            Close
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row ticket-card mt-3 pb-2">
                    <div className="col-1">
                      <img className="img-sm rounded-circle" src="images/faces/face5.jpg" alt="profile image" />
                    </div>
                    <div className="ticket-details col-9">
                      <div className="d-flex">
                        <p className="text-primary font-weight-bold mr-2 mb-0 no-wrap">Marina John :</p>
                        <p className="font-weight-medium mr-1 mb-0">[#23034]</p>
                        <p className="font-weight-bold mb-0 ellipsis">
                          Lorem ipsum dolor sit amet, consectetur
                          adipisicing
                          elit. Quasi amet totam, dignissimos fugiat voluptates, ab magni, necessitatibus excepturi
                          inventore, mollitia ipsa quaerat aliquam.
                        </p>
                      </div>
                      <p className="text-small text-gray">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Recusandae animi omnis, a?
                      </p>
                      <div className="ant-row text-muted d-flex">
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Last responded :</p>
                          <p className="Last-responded mr-2 mb-0">3 hours ago</p>
                        </div>
                        <div className="col-4 d-flex">
                          <p className="mb-0 mr-2">Due in :</p>
                          <p className="Last-responded mr-2 mb-0">2 Days</p>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-actions col-2">
                      <div className="btn-group dropdown">
                        <button
                          type="button"
                          className="btn btn-teal dropdown-toggle btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Manage
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-reply fa-fw" />
                            Quick reply
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-history fa-fw" />
                            Another
                            action
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-check text-success fa-fw" />
                            Resolve
                            Issue
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fa fa-times text-danger fa-fw" />
                            Close
                            Issue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default styled(DashboardPage)`
  .container { 
    display: flex;
    align-content: center;
  }
`;
