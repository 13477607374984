import './ThirdPartyAuth.scss';
import React, { Component, MouseEvent } from 'react';
import { Button } from 'antd';
import { PATHS } from '../../constants';
import { AuthProviderEpo } from '../../lib/jap-client/api';

interface ThirdPartyAuthProps {
  authProviderInfo: AuthProviderEpo,
  domainId: string,
}

interface ThirdPartyAuthState extends AuthProviderEpo {
  domainId: string,
}

class ThirdPartyAuth extends Component<ThirdPartyAuthProps, ThirdPartyAuthState> {
  constructor(props: ThirdPartyAuthProps) {
    super(props);

    const { authProviderInfo, domainId } = props;
    const {
      logoUrl,
      providerId,
      authUrl,
      tokenUrl,
      tokenMethod,
      appId,
      responseType,
      signupProfileId,
      signupGroupId,
      scope,
    } = authProviderInfo;
    this.state = {
      domainId,
      logoUrl,
      providerId,
      authUrl,
      tokenUrl,
      tokenMethod,
      appId,
      responseType,
      signupProfileId,
      signupGroupId,
      scope,
    };
    this.storeAuthData = this.storeAuthData.bind(this);
  }

  storeAuthData(e: MouseEvent) {
    const {
      domainId, providerId, signupProfileId, signupGroupId,
    } = this.state;

    localStorage.setItem('authDetails', JSON.stringify({
      domainId,
      providerId,
      profileIds: signupProfileId,
      groupIds: [signupGroupId],
    }));
  }

  render() {
    const {
      authUrl,
      appId,
      responseType,
      providerId,
      logoUrl,
      scope,
    } = this.state;

    const { location } = window;
    const { protocol, host } = location;
    const redirectUri = `${protocol}//${host}${PATHS.ACCOUNT_AUTHENTICATE}`;

    const clientIdParam = appId ? `client_id=${appId}` : '';
    const redirectUriParam = `redirect_uri=${redirectUri}`;
    const responseTypeParam = responseType ? `response_type=${responseType}` : 'response_type=token';
    const scopeParam = scope ? `scope=${scope}` : '';
    const urlParams = [
      clientIdParam,
      redirectUriParam,
      responseTypeParam,
      scopeParam,
    ]
      .filter(Boolean)
      .join('&');

    return (
      <Button
        className="ThirdPartyAuth"
        type="link"
        href={`${authUrl}?${urlParams}`}
        onClick={this.storeAuthData}
        target="_top"
      >
        <div>
          <img src={logoUrl} width="20px" />
          <span>
            {'Sign in with '}
            {providerId}
          </span>
        </div>
      </Button>
    );
  }
}

export { ThirdPartyAuth };
