import { createStore } from 'cartiv';
import { notification } from 'antd';
import backend from '../../services/api/BackendApi';
import api from '../../services/api';
import { genericErrorHandler } from '../../utils/Common';
import { navigateBack, navigateTo } from '../../services/history';
import { PATHS } from '../../constants';

export const SERVICES = {
  ACCOUNT_SERVICE: 'ACCOUNT_SERVICE',
  EMAIL_SERVICE: 'EMAIL_SERVICE',
  FILE_SERVICE: 'FILE_SERVICE',
  COLLECTION_SERVICE: 'COLLECTION_SERVICE',
  AUDIT_SERVICE: 'AUDIT_SERVICE',
};

export const DomainSettingsPageStore = createStore({
  api,
  name: 'DomainSettingsPage',
}, {
  getInitialState() {
    return {
      domain: {},
      previousUsedServices: {},
      value: {},
      managedDomainId: 'current',
    };
  },
  onLoadDomainSettingsPage() {
    backend.get(`/domains/${this.state.managedDomainId}`).then((domain) => {
      const servicesObject = this.serviceArrayToObject(domain.usedServices);
      this.setState({
        domain: {
          ...domain,
          services: servicesObject,
        },
        previousUsedServices: servicesObject,
      });
    }).catch((e) => genericErrorHandler());
  },
  serviceArrayToObject(usedServices) {
    const services = {
      Email: '', Collection: '', File: true, Account: true, Audit: true,
    };

    if (usedServices) {
      services.Email = (usedServices.indexOf(SERVICES.EMAIL_SERVICE) !== -1);
      services.Collection = (usedServices.indexOf(SERVICES.COLLECTION_SERVICE) !== -1);
    }
    return services;
  },
  onSetManagedDomainId(domainId) {
    this.setState({ managedDomainId: domainId });
  },
  onUpdateDomainSettings(domainData, servicesToDelete) {
    backend.put(`/domains/${this.state.domain.id}`, { data: domainData }).then((result) => {
      notification.success({ message: 'Domain settings saved' });

      servicesToDelete.forEach((service) => this.onDeleteService(service));

      navigateBack();
    }).catch(genericErrorHandler);
  },
  onResetDomain() {
    this.setState({ domain: {} });
  },
  onCreateNewDomain(domainData) {
    backend.post('/domains', { data: domainData }).then((result) => {
      notification.success({ message: 'Domain successfully created' });
      navigateTo(`${PATHS.DOMAIN}/?domainId=${domainData.id}`);
      window.location.reload();
    }).catch(genericErrorHandler);
  },
  onDeleteService(service) {
    const serviceToDelete = this.buildServiceToDelete(service);

    backend.delete(`/domains/${this.state.domain.id}/${serviceToDelete}`)
      .then((result) => {
        notification.success({ message: 'Service successfully deleted!' });
      })
      .catch(genericErrorHandler);
  },
  buildServiceToDelete(service) {
    if (service === 'Email') {
      return SERVICES.EMAIL_SERVICE;
    }
    if (service === 'Collection') {
      return SERVICES.COLLECTION_SERVICE;
    }
  },

});
