import React from 'react';
import PasswordResetInputCard from '../../components/PasswordReset/PasswordResetInputCard';
import PasswordSetCardsContainer from './PasswordSetCardsContainer';


export default (props) => (
  <PasswordSetCardsContainer>
    <PasswordResetInputCard {...props} />
  </PasswordSetCardsContainer>
);
