import React, { Component } from 'react';
import {
  Redirect, Route, Switch, RouteComponentProps,
} from 'react-router';
import { History } from 'history';
import { createConnector } from 'cartiv';
import api from '../services/api';
import Layout from '../components/Layout/Layout';
import { Authentication } from '../components/Login/Authentication';
import { Login } from '../components/Login/Login';
import { DomainSelector } from '../components/DomainSelector/DomainSelector';
import { AuthStore } from '../stores/AuthStore';
import DashboardPage from '../pages/dashboard/DashboardPage';
import { DomainSettingsPage } from '../pages/DomainSettingsPage/DomainSettingsPage';
import { DomainTokensPage } from '../pages/DomainTokensPage/DomainTokensPage';
import GenericEntityTable from '../pages/genericEditor/GenericEntityTable';
import { GenericEditor } from '../pages/genericEditor/GenericEditor';
import UsersPage from '../pages/user/UsersListPage';
import ComponentsPage from '../pages/components/ComponentsPage';
import ComponentPage from '../pages/components/ComponentPage';
import UserEditor from '../pages/user/UserEditor';
import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import PasswordResetRequestPage from '../pages/password/PasswordResetRequestPage';
import PasswordResetRequestCard from '../components/PasswordReset/PasswordResetRequestCard';
import PasswordResetRequestSentCard from '../components/PasswordReset/PasswordResetRequestSentCard';
import { EmailTemplatesPage } from '../pages/emailTemplate/EmailTemplatesListPage';
import { EmailTemplatePage } from '../pages/emailTemplate/EmailTemplatePage';
import { EmailSettingsPage } from '../pages/emailSettingsPage/EmailSettingsPage';
import { DomainsManagement } from '../pages/DomainsManagement/DomainsManagement';
import { AccountSettingsEditor } from '../pages/account/AccountSettingsEditor';
import AccountDetailPage from '../pages/account/AccountDetailPage';
import { CollectionType } from '../pages/collection/CollectionType';
import LandingPage from '../pages/landing/LandingPage';
import { CollectionTypeList } from '../pages/collection/CollectionTypeList';
import AccountProfilesList from '../pages/account/AccountProfilesList';
import AccountProfileEditor from '../pages/account/AccountProfileEditor';
import AuditList from '../pages/audit/AuditList';
import { GroupList } from '../pages/account/GroupList';
import GroupEditor from '../pages/account/GroupEditor';
import { FileSettingsPage } from '../pages/file/FileSettingsPage';
import { Registration } from '../components/Registration/Registration';
import { LoginForm } from '../components/Login/LoginForm';
import PasswordSetterPage from '../pages/password/PasswordSetterPage';
import PasswordSetSuccessfulPage from '../pages/password/PasswordSetSuccessfulPage';
import PasswordSetErrorPage from '../pages/password/PasswordSetErrorPage';
import { PATHS } from '../constants';
import PasswordResetRequestSentPage from '../pages/password/PasswordResetRequestSentPage';
import PasswordResetInputPage from '../pages/password/PasswordResetInputPage';
import PasswordResetSuccessfulPage from '../pages/password/PasswordResetSuccessfulPage';
import PasswordResetErrorPage from '../pages/password/PasswordResetErrorPage';
import PasswordResetInputCard from '../components/PasswordReset/PasswordResetInputCard';
import PasswordChangeInputPage from '../pages/password/PasswordChangeInputPage';
import PasswordChangeSuccessfulPage from '../pages/password/PasswordChangeSuccessfulPage';

let history: History | null = null;
const PUBLIC_PATHS = [
  PATHS.ACCOUNT_AUTHENTICATE,
  PATHS.LOGIN,
  PATHS.EMBED_LOGIN,
  PATHS.EMBED_REGISTER,
  PATHS.EMBED_REQUEST_PASSWORD_RESET,
  PATHS.EMBED_CHANGE_PASSWORD,
  PATHS.EMBED_REQUEST_SENT,
  PATHS.PASSWORD_RESET_REQUEST,
  PATHS.PASSWORD_RESET_REQUEST_SENT,
  PATHS.PASSWORD_RESET_PASSWORD_CHANGED,
  PATHS.PASSWORD_RESET_SUCCESS,
  PATHS.PASSWORD_RESET_ERROR,
  PATHS.PASSWORD_SET,
  PATHS.PASSWORD_SET_SUCCESS,
  PATHS.PASSWORD_SET_ERROR,
  PATHS.PASSWORD_CHANGE,
  PATHS.PASSWORD_CHANGE_SUCCESS,
  PATHS.PASSWORD_CHANGE_ERROR,
];

/**
 * @deprecated
 * @param address
 */
export const navigateTo = function (address: string) {
  api.Analytics.onEvent('pageview', address);
  if (!history && typeof window !== 'undefined' && window.history) {
    console.log('failing to navigate');
  } else if (history) {
    console.log('navigation deprecated');
    history.push(address);
  }
};

const connect = createConnector(React);

interface AppRouteProps extends RouteComponentProps {
  history: History,
}

interface AppRouteState {
  token: string | null,
}

@connect(AuthStore)
class AppRoutes extends Component<AppRouteProps, AppRouteState> {
  constructor(props: any) {
    super(props);
    this.state = {
      token: AuthStore.getToken(),
    };
  }

  componentDidUpdate(prevProps: AppRouteProps) {
    if (this.props.location !== prevProps.location) {
      !this.isPublicRoute() && this.onPrivateRouteChange();
    }
  }

  onPrivateRouteChange() {
    api.Auth.onRefreshToken();
  }

  isPublicRoute = () => PUBLIC_PATHS.indexOf(this.props.location.pathname) !== -1 || /\/password-reset\/change-password\/*/.test(this.props.location.pathname);

  render(): React.ReactNode {
    history = this.props.history;
    let loggedIn = !!this.state.token;

    if (this.isPublicRoute()) {
      return (
        <Switch>
          <Route path={PATHS.LOGIN} component={Login} />
          <Route path={PATHS.ACCOUNT_AUTHENTICATE} component={Authentication} />
          <Route path={PATHS.PASSWORD_SET_SUCCESS} component={PasswordSetSuccessfulPage} />
          <Route path={PATHS.PASSWORD_SET_ERROR} component={PasswordSetErrorPage} />
          <Route path={PATHS.PASSWORD_SET} component={PasswordSetterPage} />
          <Route path={PATHS.PASSWORD_CHANGE_SUCCESS} component={PasswordChangeSuccessfulPage} />
          <Route path={PATHS.PASSWORD_CHANGE_ERROR} component={PasswordResetErrorPage} />
          <Route path={PATHS.PASSWORD_CHANGE} component={PasswordChangeInputPage} />
          <Route path={PATHS.PASSWORD_RESET_REQUEST_SENT} component={PasswordResetRequestSentPage} />
          <Route path={PATHS.PASSWORD_RESET_REQUEST} component={PasswordResetRequestPage} />
          <Route path={`${PATHS.PASSWORD_RESET_CHANGE_PASSWORD}/:resetToken`} component={PasswordResetInputPage} />
          <Route path={PATHS.PASSWORD_RESET_SUCCESS} component={PasswordResetSuccessfulPage} />
          <Route path={PATHS.PASSWORD_RESET_ERROR} component={PasswordResetErrorPage} />
          <Route path={PATHS.EMBED_REGISTER} component={Registration} />
          <Route path={PATHS.EMBED_LOGIN} component={LoginForm} />
          <Route path={PATHS.EMBED_CHANGE_PASSWORD} component={PasswordResetInputCard} />
          <Route path={PATHS.EMBED_REQUEST_PASSWORD_RESET} component={PasswordResetRequestCard} />
          <Route path={PATHS.EMBED_REQUEST_SENT} component={PasswordResetRequestSentCard} />
        </Switch>
      );
    }
    if (this.props.location && this.props.location.pathname === '/domain-selector') {
      return <DomainSelector />;
    }
    if (!loggedIn) {
      return <Redirect to={PATHS.LOGIN} />;
    }
    return (
      <Route
        path="/"
        render={() => (
          <Layout {...this.props}>
            <Switch>
              <Route path={PATHS.LANDING} exact component={LandingPage} />
              <Route path={PATHS.DASHBOARD} component={DashboardPage} />
              <Route path={PATHS.DOMAIN} exact component={DomainSettingsPage} />
              <Route path={PATHS.DOMAIN_TOKENS} exact component={DomainTokensPage} />
              <Route path={PATHS.COMPONENTS} exact component={ComponentsPage} />
              <Route path={`${PATHS.COMPONENTS}/:id`} exact component={ComponentPage} />
              <Route path={PATHS.ACCOUNT} component={AccountDetailPage} />
              <Route path={PATHS.ACCOUNTS_USERS} exact component={UsersPage} />
              <Route path={`${PATHS.ACCOUNTS_USERS}/:domainId`} exact component={UsersPage} />
              <Route path={PATHS.ACCOUNTS_SETTINGS} component={AccountSettingsEditor} />
              <Route path={PATHS.ACCOUNTS_PROFILES} exact component={AccountProfilesList} />
              <Route path={`${PATHS.ACCOUNTS_PROFILES_EDIT}/:profileId`} component={AccountProfileEditor} />
              <Route path={PATHS.ACCOUNTS_PROFILES_NEW} component={AccountProfileEditor} />
              <Route path={PATHS.ACCOUNTS_GROUPS} exact component={GroupList} />
              <Route path={`${PATHS.ACCOUNTS_GROUPS_EDIT}/:groupId`} component={GroupEditor} />
              <Route path={PATHS.ACCOUNTS_GROUPS_NEW} component={GroupEditor} />
              <Route path={PATHS.DOMAINS_USERS_NEW} component={UserEditor} />
              <Route path={`${PATHS.USERS}/:id`} component={UserEditor} />
              <Route path={PATHS.DOMAINS_MANAGE} component={DomainsManagement} />
              <Route path={PATHS.DOMAINS_NEW} component={DomainSettingsPage} />
              <Route path={PATHS.EMAILS_TEMPLATES} component={EmailTemplatesPage} />
              <Route path={`${PATHS.EMAILS_TEMPLATE}/:id`} component={EmailTemplatePage} />
              <Route path={PATHS.EMAILS_SETTINGS} component={EmailSettingsPage} />
              <Route path={`${PATHS.COLLECTION_TYPES}/:type`} component={CollectionType} />
              <Route path={PATHS.COLLECTION_TYPES} component={CollectionTypeList} />
              <Route path={`${PATHS.COLLECTION_EDIT}/:_type/:id`} component={GenericEditor} />
              <Route path={`${PATHS.COLLECTION_EDIT}/:_type`} component={GenericEntityTable} />
              <Route path={PATHS.AUDIT} component={AuditList} />
              <Route path={PATHS.FILE_SETTINGS} component={FileSettingsPage} />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Layout>
        )}
      />
    );
  }
}

export default AppRoutes;
